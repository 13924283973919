import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Navbar menu component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, isBurgerOpen: Boolean }} props
 * @returns
 */
function Menu ({ children, isBurgerOpen }) {
  return (
    <div
      className={bindingClassName(
        'navbar-menu',
        { 'is-active': isBurgerOpen },
      )}
    >
      {children}
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isBurgerOpen: PropTypes.bool,
};

Menu.defaultProps = {
  isBurgerOpen: false,
};

export default Menu;

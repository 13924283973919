import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme, useClickOutside } from '~/hooks';
import { bindingClassName } from '~/utils';

import TextInput from './Text';
import ListItem from '../../ListItem';

/**
 * ----------------------------------------------------------------------------
 * Auto complete component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  items: Array,
 *  numItems: Number,
 *  keyExtractor: Function,
 *  onChange: Function,
 *  render: Function,
 *  renderSeparator: Function,
 *  renderEmpty: Function,
 *  shadowless: Boolean,
 * }} props
 * @return {JSX.Element}
 */
function AutoComplete ({
  items,
  numItems,
  keyExtractor,
  onChange,
  render,
  renderSeparator,
  renderEmpty,
  shadowless,
  showTotal,
  ...rest
}, ref) {
  const [ visible, setVisible ] = useState(false);
  const elRef = useRef(null);

  const { theme } = useTheme();

  const handleOnChange = (text) => {
    setVisible(true);
    onChange(text);
  };

  const handleEnter = () => {
    // out focus from input
    // setVisible(false);
  };

  useClickOutside(elRef, () => {
    setVisible(false);
  });

  const hideDropdown = () => {
    setVisible(false);
  };

  // binds ref passed from parent to the this component's instance. Call-back returns object with closures.
  useImperativeHandle(ref, () => ({
    hideDropdown,
  }));

  return (
    <Wrapper
      ref={elRef}
      theme={theme}
      className={bindingClassName(
        'input-autocomplete',
        { 'is-shadowless': shadowless },
      )}
    >
      <TextInput
        onChange={handleOnChange}
        onEnter={handleEnter}
        {...rest}
      />
      <div
        className={bindingClassName(
          'input-autocomplete-dropdown',
          { 'is-open': visible },
          { 'is-close': !visible },
        )}
      >
        {showTotal && <div className="total-result">{`Found ${items.length} results.`}</div>}
        <ListItem
          items={items}
          keyExtractor={keyExtractor}
          numItems={numItems}
          render={render}
          renderSeparator={renderSeparator}
          renderEmpty={renderEmpty}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  &.input-autocomplete {
    .input-autocomplete-dropdown {
      background: ${({ theme }) => theme.palette.background};
    }
  }
`;

AutoComplete.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  numItems: PropTypes.number,
  keyExtractor: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  render: PropTypes.func,
  renderSeparator: PropTypes.func,
  renderEmpty: PropTypes.func,
  shadowless: PropTypes.bool,
  showTotal: PropTypes.bool,
};

AutoComplete.defaultProps = {
  items: [],
  numItems: undefined,
  onChange () {},
  render: undefined,
  renderSeparator: undefined,
  renderEmpty: undefined,
  shadowless: false,
  showTotal: false,
};

export default forwardRef(AutoComplete);

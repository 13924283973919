import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function ContactItem ({ icon, text, type, renderProps }) {
  const renderContactLink = () => {
    const contactTokens = text.split(',');
    switch (type) {
      case 'phone':
        return contactTokens.map(contactToken => <a href={`tel:${contactToken}`}>{contactToken}</a>);
      case 'email':
        return contactTokens.map(contactToken => <a href={`mailto:${contactToken}`}>{contactToken}</a>);
      default:
      case 'link':
        return contactTokens.map(contactToken => <a href={contactToken}>{contactToken}</a>);
    }
  };
  return (
    <div className="contact">
      <Icon className="contact-icon" name={icon} />
      <div className="contact-text">
        {(renderProps && renderProps()) || renderContactLink(text)}
      </div>
    </div>
  );
}

ContactItem.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf([ 'phone', 'email', 'link' ]),
  renderProps: PropTypes.func,
};

ContactItem.defaultProps = {
  icon: '',
  text: '',
  type: 'link',
  renderProps: undefined,
};

export default ContactItem;

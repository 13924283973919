import React, { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Hammer from 'react-hammerjs';
import { useSpring, useTransition, animated } from 'react-spring';

import { useTheme } from '~/hooks';
import { getFile } from '~/utils';

/**
 * Reducer
 *
 * @param {{ start: Number, move: Number, current: Number, index: Number, isPanning: Boolean }} state
 * @param {{ type: String, payload: {} }} action
 * @returns
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'pan-start':
      return { ...state, ...payload, isPanning: true };

    case 'pan':
      return { ...state, ...payload };

    case 'pan-end':
      return { ...state, ...payload, isPanning: false };

    default:
      throw new Error('Unexpected action');
  }
};

/**
 * Initial value of reducer state
 * @constant
 */
const initialState = {
  start: 0,
  move: 0,
  current: 0,
  index: 0,
  isPanning: false,
};

/**
 * ----------------------------------------------------------------------------
 * Cover flow component
 * ----------------------------------------------------------------------------
 *
 * @param {{ items: [], onChange: Function, renderContent: Function }} props
 * @param {Arrray} props.items
 * @param {Function} props.onChange
 * @param {Function} props.renderContent
 * @returns
 */
function CoverFlow ({ items, onChange, renderContent }) {
  const { theme } = useTheme();
  const sliderRef = useRef(null);
  const [ state, dispatch ] = useReducer(reducer, initialState);

  /**
   * Slider animated prop
   */
  const [ animatedProp, setAnimated ] = useSpring(() => ({
    from: { transform: 0 },
    to: { transform: state.move },
    immediate: true,
  }));

  /**
   * Content transition props
   * @constant
   */
  const itemsWithTransition = useTransition(state.index, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  /**
   * Handle on pan start
   * @function
   */
  const handleOnPanStart = ({ changedPointers }) => {
    dispatch({
      type: 'pan-start',
      payload: {
        start: changedPointers[0].clientX,
      },
    });
  };

  /**
   * Handle on pan
   * @function
   */
  const handleOnPan = ({ changedPointers }) => {
    const { start, current } = state;
    const delta = (start - changedPointers[0].clientX) + current;

    setAnimated({
      to: { transform: delta },
      immediate: true,
    });

    dispatch({
      type: 'pan',
      payload: { move: delta },
    });
  };

  /**
   * Handle on pan end
   * @function
   */
  const handleOnPanEnd = () => {
    const { move } = state;
    const { clientWidth: sliderClientWidth } = sliderRef.current.domElement;
    const currentIndex = Math.round(move / sliderClientWidth);

    let index = 0;
    if (currentIndex >= 0 && currentIndex <= items.length - 1) {
      index = currentIndex;
    } else if (currentIndex < 0) {
      index = 0;
    } else if (currentIndex > items.length - 1) {
      index = items.length - 1;
    }

    /** current translateX */
    const current = index * sliderClientWidth;

    setAnimated({
      to: { transform: current },
      immediate: false,
    });

    dispatch({
      type: 'pan-end',
      payload: { current, index, move: current },
    });

    /** fired onChange event */
    onChange(items[index], index);
  };

  return (
    <Wrapper
      theme={theme}
      className="cover-flow"
    >
      <Hammer
        direction="DIRECTION_HORIZONTAL"
        onPanStart={handleOnPanStart}
        onPan={handleOnPan}
        onPanEnd={handleOnPanEnd}
        ref={sliderRef}
      >
        <div
          className="slider"
        >
          <animated.div
            className="slide-container"
            style={{
              transform: animatedProp.transform.interpolate(value => (
                `translateX(${-value}px`
              )),
            }}
          >
            {items.map((item, index) => (
              <div className="slide-item" key={index.toString()}>
                <animated.img
                  src={getFile(item.src)}
                  alt={item.alt}
                  style={{
                    transform: animatedProp.transform.interpolate((value) => {
                      const { clientWidth } = sliderRef.current.domElement;
                      /**
                       * its position compare with current index (state.index)
                       * @example if I have an array of 3 length ([0, 1, 2]), the index 1 is the current index, so the position of 1 is 0.
                       * @example [0, 1(current index), 2] -> [-1, 0, 1] (position)
                       */
                      const position = (value / clientWidth) - index;
                      const rotateY = 40 * position;
                      const translateX = position * (clientWidth / 10);
                      // const scale = (Math.abs(position) + 1) - 0.8 < 1 ? 1 : (Math.abs(position) + 1) - 0.8;

                      return `rotateY(${rotateY}deg) translateX(${translateX}px)`;
                    }),
                  }}
                />
              </div>
            ))}
          </animated.div>
        </div>
      </Hammer>

      <div className="content-container">
        {
          itemsWithTransition.map(({ item, key, props }) => (
            <animated.div className="content-item" key={key} style={props}>
              {renderContent(items[item], item)}
            </animated.div>
          ))
        }
      </div>
    </Wrapper>
  );
}

CoverFlow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  renderContent: PropTypes.func,
};

CoverFlow.defaultProps = {
  onChange () {},
  renderContent () {},
};

const Wrapper = styled.div``;

export default CoverFlow;

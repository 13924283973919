import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Level left component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function Left ({ children, className, style }) {
  return (
    <div
      style={style}
      className={bindingClassName(
        'level-left',
        className,
      )}
    >
      {children}
    </div>
  );
}

Left.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

Left.defaultProps = {
  className: '',
  style: {},
};

export default Left;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Container component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, fluid: Boolean }} props
 * @returns
 */
const Container = forwardRef(({ children, fluid }, ref) => (
  <div
    ref={ref}
    className={bindingClassName({
      container: !fluid,
      'container-fluid': fluid,
    })}
  >
    {children}
  </div>
));

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  fluid: PropTypes.bool,
};

Container.defaultProps = {
  fluid: false,
};

export default Container;

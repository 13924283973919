import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { usePalette } from 'react-palette';

import Section from '../Section';
import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Floor level bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  src: String,
 *  title: String,
 *  subTitle: String,
 *  seeMoreText: String,
 *  onClickSeeMore: Function,
 * }} props
 * @returns
 */
function FloorLevelBar ({
  src,
  title,
  subTitle,
  seeMoreText,
  onClickSeeMore,
}) {
  const palette = usePalette(src);
  const { theme } = useTheme();

  return (
    <Wrapper
      theme={theme}
      palette={palette}
      className={bindingClassName(
        'floor-level-bar',
      )}
    >
      <div className="container">
        <div className="floor-level-bar-container">
          <div className="floor-icon-container">
            <img src={src} alt="" />
          </div>
          <div className="floor-title-container">
            <div className="floor-name">
              <p className="title is-text-uppercase is-size-4 is-weight-bold">{title}</p>
              <p className="sub-title is-size-6">{subTitle}</p>
            </div>
            <div>
              <a className="see-more" href="" onClick={onClickSeeMore}>{seeMoreText}</a> {/* eslint-disable-line */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

FloorLevelBar.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  seeMoreText: PropTypes.string,
  onClickSeeMore: PropTypes.func,
};

FloorLevelBar.defaultProps = {
  src: '',
  title: '',
  subTitle: '',
  seeMoreText: 'See more',
  onClickSeeMore () {},
};

const Wrapper = styled(Section)`
  &.floor-level-bar {
    &:before, &:after {
      background: ${({ palette }) => palette.data.vibrant};
    }

    .floor-icon-container {
      background: ${({ theme }) => theme.colorHelper.white};
    }

    .floor-title-container {
      .sub-title {
        color: ${({ theme }) => theme.colorHelper.helperText};
      }
    }

    a.see-more {
      color: ${({ theme }) => theme.colorHelper.link};
    }
  }
`;

export default FloorLevelBar;

/* eslint-disable no-console */

import d from 'debug';

import config from './config';


/**
 * Logger
 *
 * @return {debug: d.Debugger, error: d.Debugger}
 */
function logger () {
  const debug = d('app:debug');
  const error = d('app:error');
  const warn = d('app:warn');

  error.log = console.error.bind(console);
  debug.log = console.info.bind(console);
  warn.log = console.warn.bind(console);

  // enable all debugger
  if (config.NODE_ENV === 'development') d.enable('*');

  // disable all debugger
  else d.disable('*');

  return { debug, error, warn };
}

export default logger();

import axios from 'axios';

import config from '~/lib/config';


/**
 * Fetcher
 *
 * @return {import ('axios').AxiosInstance}
 */
function fetcher () {
  const { BASE_URL } = config;

  /** @type {import ('axios').AxiosRequestConfig} */
  const opts = {
    baseURL: BASE_URL,
  };

  return axios.create(opts);
}

export default fetcher();

import { useRef, useEffect } from 'react';

/**
 * ----------------------------------------------------------------------------
 * Did mount hook
 * ----------------------------------------------------------------------------
 *
 * @desciption prevent condition on first render
 * @returns
 */
function useDidMount () {
  const didMount = useRef(false);

  useEffect(() => {
    didMount.current = true;
  }, []);

  return didMount.current;
}

export default useDidMount;

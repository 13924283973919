import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Icon from '../Icon';
import Img from '../Img';
import { useTheme } from '~/hooks';


/**
 * Shop near by card
 *
 * @param {String} logo
 * @param {String} [title='']
 * @param {String} [subTitle='']
 * @param {String} [floor='']
 * @param {Function} onClick
 * @returns {JSX.Element}
 */
function ShopNearByCard ({
  logo,
  title,
  subTitle,
  floor,
  onClick,
}) {
  const { theme } = useTheme();

  return (
    <Wrapper theme={theme} floorId={floor.id} className="shop-nearby-card is-clickable" onClick={onClick}>
      <div className="shop-nearby-card-control">
        <div className="shop-nearby-card-img is-margin-1">
          <Img className="img" src={logo} />
        </div>
        <div className="shop-nearby-card-content">
          <div>
            <h3 className="title is-size-4">{title}</h3>
            <h5 className="sub-title">{subTitle}</h5>
            <div className="shop-nearby-card-floor">
              <p className="is-size-6">{floor.name}</p>
            </div>
          </div>
        </div>

        <div className="shop-nearby-card-icon">
          <Icon className="icon" name="InfoCircle" />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
&:hover {
    color: black;
    background: ${({ theme, floor }) => {
    const background = theme.floorColors[floor];
    return background && background.default;
  }};
}
`;

ShopNearByCard.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  floor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onClick: PropTypes.func,
};

ShopNearByCard.defaultProps = {
  logo: '',
  title: '',
  subTitle: '',
  floor: {},
  onClick () {},
};

export default ShopNearByCard;

/**
 * ----------------------------------------------------------------------------
 * Language converter
 * ----------------------------------------------------------------------------
 *
 * @description Convert language code to language name
 * @example langConverter('th') => 'ภาษาไทย' and langConverter('en') => 'English'
 * @param {String} languageCode
 * @returns
 */
function LanguageConverter (languageCode) {
  const language = {
    th: 'ภาษาไทย',
    en: 'English',
    jp: '日本語',
    cn: '中文',
    kr: '한국어',

  };

  return language[languageCode];
}

export default LanguageConverter;

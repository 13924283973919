import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TabPane from './components/TabPane';
import Content from './components/Content';
import { useTheme } from '~/hooks';

const Tab = ({
  children,
  className,
  defaultActiveKey,
  onChangeTab,
}) => {
  const { theme } = useTheme();
  const [ activeTab, setActiveTab ] = useState(defaultActiveKey);

  const willUseProps = onChangeTab && typeof onChangeTab === 'function';
  const isTabActive = (tabKey) => {
    if (willUseProps) return tabKey === defaultActiveKey;
    return tabKey === activeTab;
  };
  const handleClickTab = (tab) => {
    if (willUseProps) onChangeTab(tab);
    else setActiveTab(tab);
  };

  return (
    <Wrapper theme={theme} className="tab-container">
      <div className="tab">
        { React.Children.map(children, Child => (
          React.cloneElement(Child, {
            id: Child.key,
            className,
            disabled: Child.props.disabled,
            active: isTabActive(Child.key),
            onClick: handleClickTab,
          })
        ))}
      </div>
      {/* content */}
      <div className={`tab-content-container ${className}`}>
        { React.Children.map(children, Child => (
          React.cloneElement(Child.props.children, {
            ...Child.props.children.props,
            tabActive: Child.key === defaultActiveKey,
          })
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .tab-pane {
    border-color: ${({ theme }) => theme.tab.active};
    color: ${({ theme }) => theme.tab.text};

    &-indicator {
      background: ${({ theme }) => theme.colorHelper.secondary};
    }

    &.active {
      color: ${({ theme }) => theme.tab.activeText};
    }
  }
`;

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  onChangeTab: PropTypes.func,
};
Tab.defaultProps = {
  className: '',
  defaultActiveKey: '',
  onChangeTab: undefined,
};


Tab.TabPane = TabPane;
Tab.Content = Content;

export default Tab;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Avatar component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  src: String,
 *  alt: String,
 *  height: (String|Number),
 *  width: (String|Number),
 *  size: ('small'|'medium'|'large')
 *  rounded: Boolean,
 *  circle: Boolean,
 * }} props
 * @return {JSX.Element}
 */
function Avatar ({
  src,
  alt,
  height,
  width,
  size,
  rounded,
  circle,
}) {
  return (
    <Wrapper
      height={height}
      width={width}
      className={bindingClassName(
        'avatar',
        {
          [`is-${size}`]: size,
          'is-rounded': rounded,
          'is-circle': circle,
        },
      )}
    >
      <img alt={alt} src={src} />
    </Wrapper>
  );
}


Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
};

Avatar.defaultProps = {
  src: '',
  alt: '',
  height: '',
  width: '',
  size: 'medium',
  rounded: true,
  circle: false,
};

const Wrapper = styled.div`
  &.avatar {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
  }
`;

export default Avatar;

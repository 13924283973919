import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Graph from 'node-dijkstra';

import helper from './helper';

/** react context */
export const WayfinderContext = createContext();

/**
 * Wayfinder provider
 *
 * @param  {JSX.Element} children
 * @param  {{}} nodes
 * @param  {{}} defaultStartPoint
 */
const WayfinderProvider = ({ children, nodes, defaultStartPoint }) => {
  const [ startPoint, setStartPoint ] = useState(defaultStartPoint);
  const [ dijkstraGraph, setDijkstraGraph ] = useState();
  const [ masterNodes, setMasterNodes ] = useState(nodes);

  /**
   * Construct Dijkstragraph
   *
   * @param  {{}} nodeData
   */
  const constructGraph = (nodeData) => {
    const { routingMap, nodes: constructNodes } = helper.constructRoutingMap(nodeData);

    return {
      masterNodes: constructNodes,
      dijkstraGraph: new Graph(routingMap),
    };
  };

  useEffect(() => {
    const { masterNodes: masterNodeFromHelper, dijkstraGraph: dijkstraGraphFromHelper } = constructGraph(masterNodes);

    setStartPoint(defaultStartPoint);
    setDijkstraGraph(dijkstraGraphFromHelper);
    setMasterNodes(masterNodeFromHelper);
  }, [Object.keys(masterNodes).length]);

  return (
    <WayfinderContext.Provider
      value={{
        nodes: masterNodes,
        graph: dijkstraGraph,
        startPoint,
        setNodes: setMasterNodes,
      }}
    >
      {children}
    </WayfinderContext.Provider>
  );
};

WayfinderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultStartPoint: PropTypes.shape({}),
  nodes: PropTypes.shape({}),
};

WayfinderProvider.defaultProps = {
  defaultStartPoint: {},
  nodes: {},
};

export default WayfinderProvider;

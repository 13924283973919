/**
 * @deprecated since version 0.1.5
 * will be deleted in version 0.2.0
 *
 * please use config variable from `lib/config.js`
 */

import theme from './theme';

// eslint-disable-next-line
console.warn('`config/index.js` is marked as deprecated. Please use config variables from `lib/config.js`');

export {
  theme,
};

const {
  REACT_APP_FALLBACK_LANGUAGE: fallbackLanguage = 'en',
  REACT_APP_DC_URL: dcUrl = 'https://static-ugd.forvizdev.com',
  REACT_APP_ZENTRY_URL: zentryUrl = 'https://us-central1-forviz-2017.cloudfunctions.net/zentry',
} = process.env;

export default {
  /**
   * @constant
   * @description fallback language
   */
  fallbackLanguage,
  /**
   * @constant
   * @description distribution centre path url
   */
  dcUrl,
  /**
   * @constant
   * @description path to zentry service
   */
  zentryUrl,
};

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName, getFile } from '~/utils';
import Burger from './Burger';

/**
 * ----------------------------------------------------------------------------
 * Navbar brand component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  src: String,
 *  alt: String,
 *  size: ('small'|'normal'|'medium'|'large')
 *  burger: Boolean,
 *  isBurgerOpen: Boolean,
 *  toggleBurgerBar: Function,
 * }} props
 * @returns
 */
function Brand ({
  src,
  alt,
  size,
  burger,
  isBurgerOpen,
  toggleBurgerBar,
}) {
  return (
    <div
      className={bindingClassName(
        'navbar-brand', {
          'is-small': size === 'small',
          'is-medium': size === 'medium',
          'is-large': size === 'large',
        },
      )}
    >
      <div className="navbar-item">
        <img src={getFile(src)} alt={alt} />
      </div>
      {burger && <Burger active={isBurgerOpen} onClick={toggleBurgerBar} />}
    </div>
  );
}

Brand.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  size: PropTypes.oneOf([
    'small', 'normal', 'medium', 'large',
  ]),
  burger: PropTypes.bool,
  isBurgerOpen: PropTypes.bool,
  toggleBurgerBar: PropTypes.func,
};

Brand.defaultProps = {
  alt: '',
  size: 'normal',
  burger: false,
  isBurgerOpen: false,
  toggleBurgerBar () {},
};

export default Brand;

import domain from '~/store/modules/Domain';

export default {
  setProjectId (projectId) {
    return (dispatch) => {
      dispatch(domain.setProjectId({ payload: projectId }));
    };
  },
  setAppState (appState) {
    return (dispatch) => {
      dispatch(domain.setAppState({ payload: appState }));
    };
  },
  setProjectAccount (projectAccount) {
    return (dispatch) => {
      dispatch(domain.setProjectAccount({ payload: projectAccount }));
    };
  },
};

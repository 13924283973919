import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';
import FooterItem from './components/FooterItem';
import Img from './components/Img';

/**
 * ----------------------------------------------------------------------------
 * Card component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, className: String, onClick: Function }} props
 * @returns
 */
function Card ({ id, children, className, onClick }) {
  const { theme } = useTheme();
  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'app-card',
        { 'is-clickable': onClick },
        className,
      )}
      onClick={() => typeof onClick === 'function' && onClick()}
      key={id}
    >
      {children}
    </Wrapper>
  );
}

Card.Header = Header;
Card.Img = Img;
Card.Body = Body;
Card.Footer = Footer;
Card.Footer.Item = FooterItem;

Card.propTypes = {
  id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  className: '',
  onClick: undefined,
};

const Wrapper = styled.div`
  .app-card-footer {
    &-item {
      &.is-primary {
        background: ${({ theme }) => theme.colorHelper.primary};
        color: ${({ theme }) => theme.colorHelper.white};
      }
    }
  }

  .app-card-image {
    border-bottom-color: ${({ theme }) => theme.colorHelper.greyLighter};
  }
`;

export default Card;

/* eslint-disable */
import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';
import { useTheme } from '~/hooks';

import Brand from './components/Brand';
import Menu from './components/Menu';
import Start from './components/Start';
import End from './components/End';
import Item from './components/Item';
import Link from './components/Link';
import Dropdown from './components/Dropdown';
import Burger from './components/Burger';

/**
 * ----------------------------------------------------------------------------
 * Navigation bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, fluid: Boolean }} props
 * @returns
 */
function Navbar ({ children, fluid }) {
  const [ isBurgerOpen, setIsBurgerOpen ] = useState(false);
  const { theme } = useTheme();

  /**
   * Handle toggle burger bar
   * @param {('toggle'|'open'|'close')} [options='toggle']
   */
  const toggleBurgerBar = (options = 'toggle') => {
    switch (options) {
      case 'open':
        setIsBurgerOpen(true);
        break;

      case 'close':
        setIsBurgerOpen(false);
        break;
    
      case 'toggle':
      default:
        setIsBurgerOpen(!isBurgerOpen);
        break;
    }
  };

  return (
    <Wrapper className="navbar" theme={theme}>
      <div
        className={bindingClassName({
          container: !fluid,
          'container-fluid': fluid,
        })}
      >
        {children.map((child, index) => cloneElement(child, { key: index.toString(), isBurgerOpen, toggleBurgerBar }))}
      </div>
    </Wrapper>
  );
}

Navbar.Brand = Brand;
Navbar.Menu = Menu;
Navbar.Start = Start;
Navbar.End = End;
Navbar.Item = Item;
Navbar.Link = Link;
Navbar.Dropdown = Dropdown;
Navbar.Burger = Burger;

Navbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  fluid: PropTypes.bool,
};

Navbar.defaultProps = {
  fluid: false,
};

const Wrapper = styled.nav`
  a:link, a {
    color: ${({ theme }) => theme.global.textColor};
  }

  .navbar-dropdown {
    background: ${({ theme }) => theme.colorHelper.white};

    .navbar-item {
      &:hover {
        background: ${({ theme }) => theme.colorHelper.whiteTer};
      }
    }
  }
`;

export default Navbar;

import { createModule } from 'redux-modux';

const initialState = {};
const handlers = {
  setProject (state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export default createModule({
  moduleName: 'project',
  initialState,
  handlers,
});

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';
import Icon from '../Icon';

import Tag from '../Tag';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

/**
 * ----------------------------------------------------------------------------
 * Drawer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, position: ('left'|'right') }} props
 * @returns
 */
const Drawer = forwardRef(({ children, position, openAsDefault, tag }, ref) => {
  const { theme } = useTheme();
  const [ visible, setVisible ] = useState(openAsDefault);

  const [ isTouched, setIsTouched ] = useState(false);

  /**
   * Handle on toggle to expand and collapse
   */
  const handleToggle = () => {
    setIsTouched(true);
    setVisible(!visible);
  };

  /**
   * Handle on toggle to expand and collapse
   */
  const handleCollapse = () => {
    setIsTouched(true);
    setVisible(false);
  };

  /**
   * Handle on toggle to expand and collapse
   */
  const handleExpand = () => {
    setIsTouched(true);
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    toggle: () => handleToggle(),
    expand: () => handleExpand(),
    collapse: () => handleCollapse(),
  }));

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'drawer',
        `is-${position}`,
        { 'is-open': visible, 'is-close': !visible },
      )}
    >
      <div
        role="button"
        tabIndex="-1"
        className="drawer-toggle"
        onKeyPress={() => {}}
        onClick={handleToggle}
      >
        <div className="drawer-toggle-icon">
          <Icon name="ChevronRight" color={theme.colorHelper.white} />
        </div>
        {
          tag && (
            <div className={bindingClassName('tag-container', { hidden: isTouched })}>
              <Tag text={tag.text.toUpperCase()} />
            </div>
          )
        }
      </div>
      <div className="drawer-content">
        {children}
      </div>
    </Wrapper>
  );
});

Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  position: PropTypes.oneOf([ 'left', 'right' ]),
  openAsDefault: PropTypes.bool,
  tag: PropTypes.shape({
    text: PropTypes.string,
  }),
};

Drawer.defaultProps = {
  position: 'left',
  openAsDefault: true,
  tag: undefined,
};

const Wrapper = styled.div`
  &.drawer {
    .drawer-toggle {
      background-color: ${({ theme }) => theme.colorHelper.secondary};
    }

    .drawer-content {
      background-color: ${({ theme }) => theme.colorHelper.white};
    }
  }
`;

export default Drawer

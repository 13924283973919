import config from '~/lib/config';

import { BaseService } from '../base';


class Service extends BaseService {
  /**
   * Get a single project
   *
   * @param {any} params
   */
  async get (params) {
    const projects = await super.find(params);

    return projects.shift();
  }
}

export default {};
export const ProjectService = new Service(`${config.API_VERSION}/projects`);

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '~/hooks';

/**
 * ----------------------------------------------------------------------------
 * Popup Card body component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function PopupCard ({ children, style }) {
  const { theme } = useTheme();
  return (
    <Wrapper
      className="popup-card"
      theme={theme}
      style={style}
    >
      <div className="content">
        {children}
      </div>
    </Wrapper>
  );
}

PopupCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const Wrapper = styled.div`
  .content {
    background-color: ${({ theme }) => theme.colorHelper.white};
  }
  .content::before {
    border-color: transparent ${({ theme }) => theme.colorHelper.white} ${({ theme }) => theme.colorHelper.white} transparent;
  }
`;


export default PopupCard;

import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';

import { random, isEmpty } from '~/utils';

import sortBy from 'lodash.sortby';

// pin add more option unit and floor
const options = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  includeScore: true,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'title.en',
      weight: 0.4,
    },
    {
      name: 'title.th',
      weight: 0.4,
    },
    {
      name: 'displayUnit',
      weight: 0.4,
    },
    {
      name: 'floor.fields.name.en',
      weight: 0.4,
    },
    {
      name: 'floor.fields.name.th',
      weight: 0.4,
    },
    {
      name: 'keywords',
      weight: 0.1,
    },
  ],
};

const textSearch = (shops, keyword) => {
  if (isEmpty(keyword)) {
    const randomShops = random(10, shops.length);
    return randomShops.reduce((acc, randomIndex) => {
      return [
        ...acc,
        shops[randomIndex],
      ];
    }, []);
  }
  const fuse = new Fuse(shops, options); // "list" is the item array
  const fuseResult = fuse.search(keyword);
  // pin edit more
  const filterCutScore = fuseResult.filter((item => item.score < 0.5 || item.item.building === 'a-la-art'));
  // const filterCutScore = fuseResult.filter((item => item.score < 0.5));
  const calibratedResult = filterCutScore.map((item => item.item));
  const sortedCalibratedResult = sortBy(calibratedResult, result => result.name.en);
  return sortedCalibratedResult;
};

/**
 * Search auto complete hook
 *
 * @param {{}} items
 * @param {('origin'|'destination')} location
 * @return {{ filter: function, setItems: function }}
 */
const useSearchAutoComplete = (items, location) => {
  /** to prevent select in the same location of origin and destination */
  const revertLocation = location => (location === 'origin' ? 'destination' : 'origin');
  const revertShop = useSelector(state => state.search[`${revertLocation(location)}SearchShop`]);

  const filter = (text) => {
    const shopsWithOmitSelectedShop = revertShop && revertShop.slug ? items.filter(item => item.slug !== revertShop.slug) : items;
    return textSearch(shopsWithOmitSelectedShop, text);
  };

  return {
    filter,
  };
};

export default useSearchAutoComplete;

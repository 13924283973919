import { fetcher } from '~/utils';

/**
 * Content apis
 */
export default {
  /**
   * Get content by provided project id
   * @param {String} projectId
   */
  getThemeByProjectId (projectId) {
    return fetcher({
      useMock: true,
      jsonMock: `/theme/${projectId}.json`,
    });
  },
};

import React from 'react';
import { isMobile } from 'react-device-detect';

export default () => (
  <>
    {!isMobile && (
      <header id="customise">
        <div className="boxed">
          <div className="st-container">
            <div className="st-pusher">
              <div className="st-content">
                <div className="st-content-inner">
                  <div className="page-wrapper">
                    <div className="header-wrapper header-type-8">
                      <header className="header main-header">
                        <div className="container">
                          <div className="navbar" role="navigation">
                            <div className="container-fluid">
                              <div id="st-trigger-effects" className="column">
                                <button
                                  type="button"
                                  data-effect="mobile-menu-block"
                                  className="menu-icon"
                                />
                              </div>

                              <div className="header-logo">
                                <a href="https://www.terminal21.co.th/pattaya">
                                  <img
                                    src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_pattaya_logo.png"
                                    alt="ท่าอากาศยานช้อปปิ้งสุดชิค ที่ยกโลกทั้งใบมารวมไว้ในที่เดียว พร้อมต้อนรับทุกไลฟ์สไตล์จากทั่วโลก สู่ประสบการณ์ใหม่แห่งการช้อปปิ้งอันทันสมัย ท่ามกลางบรรยากาศที่ออกแบบสไตล์ Market Street"
                                  />
                                </a>
                              </div>

                              <div className="clearfix visible-md visible-sm visible-xs" />

                              <div className="navbar-header navbar-right">
                                <div className="navbar-right" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="menu-wrapper">
                          <div className="container">
                            <div className="collapse navbar-collapse">
                              <div className="menu-main-container">
                                <ul id="menu-main_menu_en-1" className="menu">
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-10917 item-level-0 menu-dropdown firstItem">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/home-en/"
                                      className="item-link"
                                    >
                                      Home
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8199 item-level-0 menu-dropdown">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/promotion-en/"
                                      className="item-link"
                                    >
                                      Promotion
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8200 item-level-0 menu-dropdown">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/gf-floor-en/"
                                      className="item-link"
                                    >
                                      Shop
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8201 item-level-0 menu-dropdown">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/event-activity-en/"
                                      className="item-link"
                                    >
                                      Event &amp; Activity
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8164 item-level-0 menu-dropdown">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/lifestyle/"
                                      className="item-link"
                                    >
                                      Lifestyle
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-10919 item-level-0 menu-dropdown">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/films-en/"
                                      className="item-link"
                                    >
                                      Films
                                    </a>
                                  </li>
                                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8202 item-level-0 menu-dropdown lastItem">
                                    <a
                                      href="https://www.terminal21.co.th/pattaya/en/contact/"
                                      className="item-link"
                                    >
                                      Contact Us
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="languages-area">
                              <div
                                id="text-3"
                                className="topbar-widget widget_text"
                              >
                                <div className="textwidget">
                                  <a
                                    className="link_top hide"
                                    href="https://www.terminal21.co.th/pattaya/how-to-go/"
                                  >
                                    HOW TO GO
                                  </a>
                                  <a
                                    className="link_top thlang"
                                    href="https://www.terminal21.co.th/pattaya/shopping-guide/"
                                  >
                                    SHOPPING GUIDE
                                    <img
                                      src="https://www.terminal21.co.th//korat/wp-content/uploads/2016/11/icon-link.png"
                                      alt="top_icon"
                                      width="19"
                                      height="19"
                                    />
                                  </a>
                                  <a
                                    className="link_top enlang"
                                    href="https://www.terminal21.co.th/pattaya/en/shopping-guide-en/"
                                  >
                                    SHOPPING GUIDE
                                    <img
                                      src="https://www.terminal21.co.th//korat/wp-content/uploads/2016/11/icon-link.png"
                                      alt="top_icon"
                                      width="19"
                                      height="19"
                                    />
                                  </a>
                                  <div className="lang hide">
                                    <a href="#" /> : <a href="#" />
                                  </div>
                                </div>
                              </div>

                              <div
                                id="polylang-2"
                                className="topbar-widget widget_polylang"
                              >
                                <ul>
                                  <li className="lang-item lang-item-62 lang-item-th lang-item-first current-lang firstItem">
                                    <a
                                      lang="th"
                                      hreFlang="th"
                                      href="https://www.terminal21.co.th/pattaya/"
                                    >
                                      <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFWSURBVHjaYvzPgAD/UNlYEUAAisQgBwAQhGGi/pzP8EBvG+BImqbL7pzuUlda9SJ7DMs85NYEBgX5Ir4AYvz/H2QHhIQz/mMDjIyMnz59AgggRkfXjTmZOu/e/fz7D2jH/7///v398+8PkPEHCEHsv3///fn978+/f8JCnGWlWwACiGX/7jOmhiKPHn3+8wck8fvPv9+//wLRr1//wORfOCkvz8fAsAUggIB++AdxJ8iRQNf++f/rF8TZ/4B6fgEZQPIXRAEoLAACCKjhx9+/f/78+f0LaC/YbIjxyGaDSaCFvxgYvgAEEAs3r5qKqhAPLzs4GP4CnQR2G9CMf2A2iPEH7BNJSe5Tp8wAAojx58+fzMzM//79wxU4EACUBYbS27dvAQKI5R87O1NJCQPEjX//MvwGkn8Yf/8GRggCAY0DSgFt2bsXIIAYv6JGJJ44hgCAAAMA8pZimQIezaoAAAAASUVORK5CYII="
                                        title="ไทย"
                                        alt="ไทย"
                                      />
                                    </a>
                                  </li>
                                  <li className="lang-item lang-item-65 lang-item-en">
                                    <a
                                      lang="en-US"
                                      hreFlang="en-US"
                                      href="https://www.terminal21.co.th/pattaya/en/home-en/"
                                    >
                                      <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHzSURBVHjaYkxOP8IAB//+Mfz7w8Dwi4HhP5CcJb/n/7evb16/APL/gRFQDiAAw3JuAgAIBEDQ/iswEERjGzBQLEru97ll0g0+3HvqMn1SpqlqGsZMsZsIe0SICA5gt5a/AGIEarCPtFh+6N/ffwxA9OvP/7//QYwff/6fZahmePeB4dNHhi+fGb59Y4zyvHHmCEAAAW3YDzQYaJJ93a+vX79aVf58//69fvEPlpIfnz59+vDhw7t37968efP3b/SXL59OnjwIEEAsDP+YgY53b2b89++/awvLn98MDi2cVxl+/vl6mituCtBghi9f/v/48e/XL86krj9XzwEEEENy8g6gu22rfn78+NGs5Ofr16+ZC58+fvyYwX8rxOxXr169fPny+fPn1//93bJlBUAAsQADZMEBxj9/GBxb2P/9+S/R8u3vzxuyaX8ZHv3j8/YGms3w8ycQARmi2eE37t4ACCDGR4/uSkrKAS35B3TT////wADOgLOBIaXIyjBlwxKAAGKRXjCB0SOEaeu+/y9fMnz4AHQxCP348R/o+l+//sMZQBNLEvif3AcIIMZbty7Ly6t9ZmXl+fXj/38GoHH/UcGfP79//BBiYHjy9+8/oUkNAAHEwt1V/vI/KBY/QSISFqM/GBg+MzB8A6PfYC5EFiDAABqgW776MP0rAAAAAElFTkSuQmCC"
                                        title="English"
                                        alt="English"
                                      />
                                    </a>
                                  </li>
                                  <li className="lang-item lang-item-556 lang-item-zh lastItem">
                                    <a
                                      lang="zh-CN"
                                      hreFlang="zh-CN"
                                      href="https://www.terminal21.cn/pattaya/"
                                    >
                                      <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAFqSURBVHjaYrzOwPAPjJgYQEDAleHVbhADIvgHLPgHiQ0QQCxAlkR9NW8sw+cV/1gV/7Gb/hV4+vfzhj8Mv/78//Pn/+/f/8AkhH1t0yaAAAJp4I37zyz2lDfu79uqv/++/WYz+cuq/vvLxt8gdb+A5K9/v34B2SyyskBLAAII5JAva/7/+/z367a/f3/8ZuT9+//Pr78vQUrB6n4CSSj6/RuoASCAWEDO/fD3ddEfhv9/OE3/sKj8/n7k9/fDQNUIs/+DVf8HawAIIJCT/v38C3Hr95N/GDh/f94AVvT7N8RUBpjxQAVADQABBNLw/y/Ifwy/f/399ufTOpDBEPf8g5sN0QBEDAwAAQTWABEChgOSA9BVA00E2wAQQCANQBbEif/AzoCqgLkbbBYwWP/+//sXqBYggFhAkfL7D7OkJFCOCSj65zfUeFjwg8z++/ffX5AGoGKAAGI8jhSRyIw/SJH9D4aAYQoQYAA6rnMw1jU2vQAAAABJRU5ErkJggg=="
                                        title="中文 (中国)"
                                        alt="中文 (中国)"
                                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>

                              <div
                                id="text-4"
                                className="topbar-widget widget_text"
                              >
                                <div className="textwidget">
                                  <img
                                    className="hidden-xs"
                                    src="https://www.terminal21.co.th/pattaya/wp-content/uploads/open_daily_16-07-2020.png"
                                    alt="Open"
                                    width="489"
                                    height="217"
                                  />
                                  <p
                                    className="visible-xs"
                                    style={{ marginBottom: 0, fontSize: 14 }}
                                  >
                                    <i className="fa fa-clock-o" />
                                    OPEN DAILY : 11.00 AM - 10.00 PM
                                  </p>

                                  <div className="subscribe hide">
                                    <div
                                      role="form"
                                      className="wpcf7"
                                      id="wpcf7-f3882-o1"
                                      lang="en-US"
                                      dir="ltr"
                                    >
                                      <div className="screen-reader-response" />
                                      <form
                                        action="/pattaya/#wpcf7-f3882-o1"
                                        method="post"
                                        className="wpcf7-form"
                                        noValidate="novalidate"
                                      >
                                        <div style={{ display: "none" }}>
                                          <input
                                            type="hidden"
                                            name="_wpcf7"
                                            value="3882"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_version"
                                            value="5.0.4"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_locale"
                                            value="en_US"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_unit_tag"
                                            value="wpcf7-f3882-o1"
                                          />
                                          <input
                                            type="hidden"
                                            name="_wpcf7_container_post"
                                            value="0"
                                          />
                                        </div>

                                        <p>
                                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                                          <label>
                                            Email
                                            <strong>Newsletters</strong>
                                          </label>
                                          <br />
                                          <span className="wpcf7-form-control-wrap your-email">
                                            <input
                                              id="email"
                                              type="email"
                                              name="your-email"
                                              value=""
                                              size="40"
                                              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                              aria-required="true"
                                              aria-invalid="false"
                                            />
                                          </span>
                                          <input
                                            type="submit"
                                            value="Subscribe"
                                            className="wpcf7-form-control wpcf7-submit"
                                          />
                                          <span className="ajax-loader" />
                                        </p>

                                        <input
                                          type="hidden"
                                          id="ct_checkjs_cf7_a5bfc9e07964f8dddeb95fc584cd965d"
                                          name="ct_checkjs_cf7"
                                          value="393142427"
                                        />
                                        <div className="wpcf7-response-output wpcf7-display-none" />
                                      </form>
                                    </div>
                                  </div>

                                  <div className="social-icon hide">
                                    <a
                                      href="https://www.instagram.com/terminal21korat/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/10/Instagram_App_Large_May2016_200.png"
                                        alt="ig_icon"
                                        width="33"
                                        height="33"
                                      />
                                    </a>
                                    <a
                                      href="https://www.facebook.com/Terminal21Korat/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/10/Facebook-Logo.png"
                                        alt="facebook"
                                        width="33"
                                        height="33"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )}
  </>
);

import React from 'react';
import PropTypes from 'prop-types';
import * as FaIcon from 'react-icons/fa';

/**
 * ----------------------------------------------------------------------------
 * Icon component
 * ----------------------------------------------------------------------------
 *
 * @param {{ name: String, color: String, className: String, onClick: Func }} props
 * @param {String} props.name
 * @param {String} props.color
 * @param {String} props.className
 * @param {Func} props.onClick
 */
const Icon = ({ name, color, className, onClick }) => {
  const Component = FaIcon[`Fa${name}`];

  return <Component color={color} className={`icon ${className}`} onClick={onClick} />;
};

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  name: '',
  color: '',
  className: '',
  onClick: () => null,
};

export default Icon;

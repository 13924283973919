import useReactRouter from 'use-react-router';
import regex from 'path-to-regexp';

/**
 * ----------------------------------------------------------------------------
 * Router hook
 * ----------------------------------------------------------------------------
 */
function useRouter () {
  const router = useReactRouter();
  const { match, history, location } = router;

  /**
   * Regex path config
   * @description using with push function
   * @example push({ path: `/:lang/:projectId/:origin${config.locatiom}` })
   * @constant
   */
  const config = {
    location: '([\\s\\S]*)',
  };

  /**
   * Change param of current pathname
   * @param {{}} params
   * @param {'push'|'replace'} strategy
   */
  const changeParam = (params, strategy = 'push') => {
    const { params: existingParams, path } = match;
    const toPath = regex.compile(path);

    history[strategy]({
      pathname: toPath({ ...existingParams, ...params }, { encode: value => value }),
    });
  };

  /**
   * Push
   * @param {{ regexPath: String, params: {}, search: {} }} props
   */
  const push = ({ regexPath, params, search }) => {
    const { params: existingParams } = match;
    const toPath = regex.compile(regexPath);
    const searchParam = new URLSearchParams(search);

    history.push({
      pathname: toPath({ ...existingParams, ...params }, { encode: value => value }),
      search: searchParam.toString(),
    });
  };

  /**
   * Set query string
   * @param {String} key
   * @param {String} value
   */
  const setQueryString = (key, value) => {
    const queryString = new URLSearchParams(location.search);
    queryString.set(key, value);

    history.replace({
      search: queryString.toString(),
    });
  };

  /**
   * Get query string
   * @param {(Array|String)} options
   * @param {({}|Array|String} defaultValue - a return value if option value doesn't exist. support string options
   */
  const getQueryString = (options, defaultValue) => {
    const queryString = new URLSearchParams(location.search);

    if (options === 'all') {
      const queries = queryString.entries();

      /** convert from array of array to key pair */
      /** @example [['one', 'value1], ['two', 'value2']] -> { one: 'value1', two: 'value2' } */
      return queries.reduce((result, query) => ({ ...result, [query[0]]: query[1] }), {});
    }

    /**
     * @example ?one=value1&two=value2
     * input: [ 'one', 'two' ]
     * output: [ 'value1', 'value2' ]
     */
    if (Array.isArray(options)) {
      return options.map((name) => {
        const value = queryString.get(name);
        return value;
      });
    }

    /**
     * @example ?one=value1&two=value2
     * const [ value1, value2 ] = getQueryString('values');
     */
    if (options === 'values') {
      return queryString.values();
    }

    /**
     * @example ?one=value1&two=value2
     * input: 'one',
     * output: 'value1'
     */
    if (typeof options === 'string') {
      return queryString.get(options) || defaultValue;
    }

    return undefined;
  };

  /**
   * Remove query string
   * @param {String} key
   */
  const removeQueryString = (key) => {
    const queryString = new URLSearchParams(location.search);
    queryString.delete(key);

    history.replace({
      search: queryString.toString(),
    });
  };

  return {
    ...router,
    config,
    changeParam,
    push,
    getQueryString,
    setQueryString,
    removeQueryString,
  };
}

export default useRouter;

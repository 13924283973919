import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import languages from './languages';
import config from '~/config';

/** react context */
export const TranslationContext = createContext();

/**
 * @constant
 * @type  {String}
 * @description fallback language
 */
const fallback = config.fallbackLanguage;

/**
 * ---
 * Translation provider
 * ---
 *
 * @param  {Object} props
 * @param  {JSX.Element} props.children
 */
const TranslationProvider = ({ defaultLang, children }) => {
  const [ currentLanguage, setCurrentLanguage ] = useState(defaultLang || fallback);

  return (
    <TranslationContext.Provider value={{ languages, currentLanguage, setCurrentLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultLang: PropTypes.string,
};

TranslationProvider.defaultProps = {
  defaultLang: undefined,
};

export default TranslationProvider;

import axios from 'axios';
import config from '~/config';

/**
 * A defaultOptions object
 * @typedef {Object<string, any>} Options
 *
 * @property {Boolean} useMock if true, the fetcher will fetch data from mock url.
 * @property {Number} delay the delay time in second unit.
 * @property {String} jsonMock path to json mock data file.
 * @property {('get'|'post'|'put'|'delete')} method http methods
 * @property {String} url endpoint of data on Distribution Centre
 * @property {{}} params
 * @property {{}} data
 * @property {{}} headers
 * @property {Boolean} ignoreError if true, the fetcher won't not throw an error.
 */

/**
 * @type {Options}
 * @description fetcher default option
 * @constant
 */
const defaultOptions = {
  useMock: false,
  delay: 0,
  jsonMock: '',
  method: 'get',
  url: '',
  params: {},
  data: {},
  headers: {},
  ignoreError: false,
  origin: config.dcUrl,
};

/**
 * Fetch with axios
 *
 * @param {Options} options
 */
const fetch = async (options) => {
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    if (options.ignoreError) {
      return error.response;
    }

    return error;
  }
};

/**
 * ---
 * Fetcher api
 * ---
 *
 * @deprecated since version 0.1.5 it will remove next major release (0.2.0)
 * @param  {Options} overrideOptions
 */
export default (overrideOptions) => {
  console.error('Fetcher is masked as deprecated since version 0.1.5. It\'ll remove in version 0.2.0');

  /**
   * @type {Options}
   * @description fetch option
   */
  const options = { ...defaultOptions, ...overrideOptions };
  const { useMock, jsonMock, url } = options;

  /**
   * @constant
   * @type {String} endpoint
   * @description the endpoint of service or mockup data
   */
  const endpoint = useMock ? `${process.env.PUBLIC_URL}/mockdata${jsonMock}` : `${options.origin}${url}`;
  options.url = endpoint;

  return new Promise(async (resolve, reject) => {
    try {
      if (useMock) options.method = 'get';
      const response = await fetch(options);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

const TabPane = ({
  id,
  active,
  onClick,
  title,
  disabled,

}) => (
  <div
    onClick={() => (disabled ? null : onClick(id))}
    role="button"
    tabIndex={0}
    onKeyPress={() => {}}
    className={bindingClassName({
      'tab-pane': true,
      active,
      'is-disabled': disabled,
    })}
  >
    {title}
    <div className="tab-pane-indicator" />
  </div>
);


TabPane.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

TabPane.defaultProps = {
  onClick: () => {},
  id: 0,
  active: true,
  title: '',
  disabled: false,
};

export default TabPane;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

const Header = ({
  title,
  height,
  borderless,
  background,
  renderLeft,
  renderRight,
}) => {
  const { theme } = useTheme();

  return (
    <Wrapper theme={theme} background={background} height={height} className={`header ${bindingClassName({ 'is-borderless': borderless })}`}>
      {
        renderLeft
        && (
          <div className="header-left-container">
            {renderLeft()}
          </div>
        )
      }
      <div className="header-title-container">
        {
          typeof title === 'string'
            ? <span className="header-title">{title}</span>
            : title()
        }
      </div>
      {
        renderRight
        && (
          <div className="header-right-container">
            {renderRight()}
          </div>
        )
      }
    </Wrapper>
  );
};

Header.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  borderless: PropTypes.bool,
  background: PropTypes.string,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
};

Header.defaultProps = {
  title: '',
  height: 44,
  borderless: false,
  background: '',
  renderLeft () {},
  renderRight () {},
};

export default Header;

const Wrapper = styled.nav`
  background: ${({ theme }) => theme.colorHelper.transparent};
  height: ${({ height }) => (typeof height === 'string' ? height : `${height}px`)};
`;

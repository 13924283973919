import React from 'react';

import { Provider } from 'react-redux';

import { CacheBuster } from '~/components';
import createStore from '~/store';
import ThemeProvider from '~/context/Theme';
import TranslationProvider from '~/context/translation';
import WayfinderProvider from '~/context/wayfinder';
import ModalProvider from '~/context/Modal';
import Routes from '~/routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '~/scss/index.scss';


/**
 * Application root
 *
 * @return {JSX.Element}
 */
function Application () {
  const store = createStore();

  return (
    <Provider store={store}>
      <ThemeProvider>
        <TranslationProvider>
          <WayfinderProvider>
            <ModalProvider>
              <CacheBuster>
                <Routes />
              </CacheBuster>
            </ModalProvider>
          </WayfinderProvider>
        </TranslationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default Application;

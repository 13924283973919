import { useEffect } from 'react';

/**
 * Use click outside
 * handle when click outside the reference element provided
 *
 * @param  {{ current: {} }} ref reference element
 * @param  {Function} listener function which run on click out
 */
function useClickOutside (ref, listener) {
  const handleClick = (e) => {
    if (!ref.current.contains(e.target)) {
      listener();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, []);
}

export default useClickOutside;

import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

/**
 * ----------------------------------------------------------------------------
 * Bottom drawer collapse component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @param {JSX.Element} props.children
 * @returns
 */
function Collapse ({ children, animatedProp }) {
  return (
    <animated.div
      className="bottom-drawer-content-collapse"
      style={{
        opacity: animatedProp.opacity.interpolate({
          range: [ 80, 125 ],
          output: [ 1, 0 ],
        }),
        display: animatedProp.opacity.interpolate(value => (
          value >= 110 ? 'none' : 'block'
        )),
      }}
    >
      {children}
    </animated.div>
  );
}

Collapse.displayName = 'Collapse';
Collapse.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  animatedProp: PropTypes.shape(),
};

Collapse.defaultProps = {
  animatedProp: {},
};

export default Collapse;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useRouter from 'use-react-router';
import { FiChevronLeft as BackIcon } from 'react-icons/fi';

import { useTheme, useSelector, useTranslation, useAction } from '~/hooks';
import { bindingClassName } from '~/utils';

import { domainSelector } from '~/store/selector';

import searchAction from '~/store/actions/Search';


/**
 * ----------------------------------------------------------------------------
 * Button-back component
 * ----------------------------------------------------------------------------
 *
 * @param {{ color: String }} props
 * @param {String} props.color
 * @return {JSX.Element}
 */
const Buttonback = ({ color, shadowless }) => {
  const actions = useAction(searchAction);
  const { theme } = useTheme();
  const { history } = useRouter();
  const { domain } = useSelector({
    domain: domainSelector.entire,
  });
  const { currentLanguage } = useTranslation();
  const { projectId } = domain;

  const handleBack = () => {
    const historyLength = history.length;
    if (historyLength > 1) {
      history.goBack();
    } else {
      actions.setOrigin({
        destinationSearchText: '',
        destinationShop: {},
      });
      actions.setDestination({
        destinationSearchText: '',
        destinationShop: {},
      });
      history.push(`/${currentLanguage}/${projectId}///search`);
    }
  };

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName({
        'back-button': true,
        'is-shadowless': shadowless,
      })}
      role="button"
      tabIndex={0}
      onClick={handleBack}
      onKeyPress={() => { }}
    >
      <BackIcon color={color || theme.colorHelper.white} />
    </Wrapper>
  );
};

Buttonback.propTypes = {
  color: PropTypes.string,
  shadowless: PropTypes.bool,
};

Buttonback.defaultProps = {
  color: '',
  shadowless: false,
};

const Wrapper = styled.div``;

export default Buttonback;

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Modal content component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children }} props
 * @param {JSX.Element} props.children
 */
function Content ({ children, centered }) {
  return (
    <div
      className={bindingClassName({
        'app-modal-content': true,
        'is-centered': centered,
      })}
    >
      {children}
    </div>
  );
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  centered: PropTypes.bool,
};

Content.defaultProps = {
  centered: false,
};

export default Content;

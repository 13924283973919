import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';
import Icon from '../Icon';

/**
 * ----------------------------------------------------------------------------
 * Button component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  title: string,
 *  variant: ('primary'|'link'|'secondary'|'plain'),
 *  size: ('small'|'medium'|'large'),
 *  fullWidth: Boolean,
 *  rounded: Boolean,
 *  disabled: Boolean
 *  leftIcon: String,
 *  rightIcon: String
 * }} props
 * @return {JSX.Element}
 */
function Button ({
  title,
  variant,
  size,
  fullWidth,
  rounded,
  disabled,
  leftIcon,
  rightIcon,
  className,
  ...rest
}) {
  const { theme } = useTheme();

  return (
    <Wrapper
      {...rest}
      theme={theme}
      disabled={disabled}
      className={bindingClassName(`button ${className}`, {
        [`is-${variant}`]: variant,
        [`is-${size}`]: size,
        'is-rounded': rounded,
        'is-diabled': disabled,
        'is-full-width': fullWidth,
      })}
    >
      {leftIcon && <Icon name={leftIcon} className="icon-left" />}
      {title}
      {rightIcon && <Icon name={rightIcon} className="icon-right" />}
    </Wrapper>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([ '', 'primary', 'link', 'secondary', 'plain' ]),
  size: PropTypes.oneOf([ '', 'small', 'medium', 'large' ]),
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  title: '',
  variant: '',
  size: '',
  fullWidth: true,
  rounded: false,
  disabled: false,
  leftIcon: '',
  rightIcon: '',
  className: '',
};

const Wrapper = styled.button`
  &.button {
    background-color: ${({ theme }) => theme.colorHelper.white};
    border-color: ${({ theme }) => theme.colorHelper.greyLight};

    &.is-primary {
      border-color: ${({ theme }) => theme.colorHelper.transparent};
      background: ${({ theme }) => theme.colorHelper.primary};
      color:${({ theme }) => theme.colorHelper.white};
      background-origin: border-box;
    }

    &.is-secondary {
      border-color: ${({ theme }) => theme.colorHelper.transparent};
      background: ${({ theme }) => theme.colorHelper.secondary};
      color:${({ theme }) => theme.colorHelper.white};
      background-origin: border-box;
    }

    &.is-link {
      background: ${({ theme }) => theme.colorHelper.link};
      border-color: ${({ theme }) => theme.colorHelper.link};
      color:${({ theme }) => theme.colorHelper.white};
    }

    &.is-plain {
      background: ${({ theme }) => theme.colorHelper.transparent};
      color: ${({ theme }) => theme.colorHelper.black};
    }
  }
`;

export default Button;

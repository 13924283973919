import React from 'react';

export default () => (
  <div id="customise">
    <footer className="main-footer main-footer-3 text-color-default">
      <div className="container">
        <div className="vc_row wpb_row vc_row-fluid bg_footer vc_custom_1475464395314">
          <div className="wpb_column vc_column_container vc_col-sm-6">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid left15 width33">
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner vc_custom_1475464663038">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/asok/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="384"
                                height="105"
                                src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/Terminal_asok_logo.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/Terminal_asok_logo.png 384w, https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/Terminal_asok_logo-300x82.png 300w"
                                sizes="(max-width: 384px) 100vw, 384px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                    <div className="vc_column-inner vc_custom_1475145263754">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/pattaya/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="435"
                                height="121"
                                src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/logo_pattaya.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/logo_pattaya.png 435w, https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/logo_pattaya-300x83.png 300w"
                                sizes="(max-width: 435px) 100vw, 435px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="young_bander wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left width60">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/youngbrander/#1"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="163"
                                height="119"
                                src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/YB-Header-elements_04.png"
                                className="vc_single_image-img attachment-medium"
                                alt=""
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wpb_column vc_column_container vc_col-sm-6">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-10">
                    <div className="vc_column-inner vc_custom_1475134815999">
                      <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element  vc_custom_1481628061335 copy normalscreen1">
                          <div className="wpb_wrapper">
                            <p style={{ textAlign: "center" }}>
                              Copyright © 2017 Terminal 21 (Terminal 21 Shopping
                              Mall). All rights reserved.
                            </p>
                          </div>
                        </div>

                        <hr className="divider" />
                        <div className="wpb_text_column wpb_content_element footermenu">
                          <div className="wpb_wrapper">
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/korat/about-us/">
                                ABOUT US
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/korat/contact-us/">
                                CONTACT US
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/korat/careers/">
                                CAREERS
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/korat/lg-floor/">
                                SHOP
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wpb_column vc_column_container vc_col-sm-2">
                    <div className="vc_column-inner vc_custom_1475464488093">
                      <div className="wpb_wrapper">
                        <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                          <div className="wpb_wrapper">
                            <div className="social-icon">
                              <a
                                href="https://www.instagram.com/terminal21korat/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/Instagram_App_Large_May2016_200.png"
                                  alt="ig_icon"
                                  width="33"
                                  height="33"
                                />
                              </a>
                              <a
                                href="https://www.facebook.com/Terminal21Korat/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/Facebook-Logo.png"
                                  alt="facebook"
                                  width="33"
                                  height="33"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element  vc_custom_1481628271588 copy smallscreen">
                  <div className="wpb_wrapper">
                    <p style={{ textAlign: "center" }}>
                      Copyright © 2017 Terminal 21 (Terminal 21 Shopping Mall).
                      All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

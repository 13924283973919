import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useRouter from 'use-react-router';

import { useTheme } from '~/hooks';
import { getFile } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Img component
 * ----------------------------------------------------------------------------
 *
 * @param {{ src: String, fallbackSrc: String }} props
 * @param {String} props.src
 * @param {String} props.fallbackSrc
 * @return {JSX.Element}
 */
function Img ({ src, fallbackSrc, className, ...rest }) {
  const { theme } = useTheme();
  const { match: { params: { projectId } } } = useRouter();

  const [ image, setImage ] = useState(src);

  useEffect(() => {
    setImage(src);
  }, [src]);

  const handleOnError = () => {
    setImage(fallbackSrc || getFile(`/images/${projectId}/shop-no-logo.png`));
  };

  return (
    <Wrapper
      {...rest}
      theme={theme}
      className={`img ${className}`}
      src={image}
      onError={handleOnError}
    />
  );
}

Img.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string,
  className: PropTypes.string,
};

Img.defaultProps = {
  fallbackSrc: '',
  className: '',
};

const Wrapper = styled.img`
  background: ${({ theme }) => theme.colorHelper.greyLight};
`;

export default Img;

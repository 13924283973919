import { createSelector } from 'reselect';

const getNodes = state => state.entity.nodes;
const getUnits = state => state.entity.shops;

export default {
  getFacilities: createSelector(
    [ getUnits, getNodes ],
    (units, nodes) => {
      const facilities = Object.entries(units).reduce((result, unit) => {
        if (unit[1].category === 'facility') {
          return [
            ...result,
            {
              id: unit[0],
              ...unit[1],
              node: nodes[unit[1].node],
            },
          ];
        }

        return result;
      }, []);

      return facilities;
    },
  ),
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import Button from '../Button';

import Img from '../Img';

function ShopDirectionHeroCard ({
  onClick,
  title,
  subTitle,
  src,
  text,
  alt,
}) {
  const { theme } = useTheme();

  return (
    <Wrapper theme={theme}>
      <div className="shop-direction-hero-card">
        <div className="left">
          <Img src={src} alt={alt} />
        </div>
        <div className="right">
          <div className="shop-name">{title}</div>
          <div className="shop-category">{subTitle}</div>
          {
            onClick && typeof onClick === 'function' && (
              <Button
                className="direction-button"
                title="direction"
                variant="secondary"
                leftIcon="Walking"
                onClick={onClick}
              />
            )
          }
          {
            text && <div className="shop-text">{text}</div>
          }
        </div>
      </div>
    </Wrapper>
  );
}

ShopDirectionHeroCard.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
};

ShopDirectionHeroCard.defaultProps = {
  onClick: undefined,
  title: '',
  subTitle: '',
  src: '',
  alt: '',
  text: undefined,
};

const Wrapper = styled.div`
  .shop-direction-hero-card {
    .left {
      border-right: 1px solid ${({ theme }) => theme.colorHelper.greyLighter};
    }
  }
`;

export default ShopDirectionHeroCard;

import { fetcher } from '~/utils';

/**
 * Content apis
 */
export default {
  /**
   * Get content by provided project id
   * @param {String} projectId
   */
  getContentByProjectId (projectId, onDownloadProgress) {
    return fetcher({
      useMock: false,
      method: 'get',
      jsonMock: `/json/${projectId}/project.json`,
      url: `/${projectId}/json/project.json`,
      onDownloadProgress,
    });
  },
  /**
   * Get mockup floor icon
   */
  getFloorIcon (projectId) {
    return fetcher({
      useMock: true,
      jsonMock: `/floor-icon/${projectId}/floor-icon.json`,
    });
  },
  /**
   * Get movement media
   */
  getMovementMedia () {
    return fetcher({
      useMock: true,
      jsonMock: '/movement.json',
    });
  },
};

import React, { Suspense, lazy } from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from 'history';

import config from '~/lib/config';
import { isProtocolFile } from '~/utils';

import { Route } from './components';


/**
 * Router config
 *
 * @constant
 */
const router = {
  basename: config.PUBLIC_URL,
  forceRefresh: false,
  keyLength: 6,
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
  hashType: 'noslash',
  routes: [
    {
      path: '/:lang?',
      name: 'home',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "home" */ '~/screens/Home')),
    },
    {
      path: '/:lang/:projectId/style-guide',
      name: 'guide',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "explore" */ '~/screens/_StyleGuide')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search/origin',
      name: 'search-origin',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search-origin" */ '~/screens/SearchOrigin')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search/origin/:page',
      name: 'search-origin-custom-page',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search-origin-custom-page" */ '~/screens/SearchOrigin')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search/destination',
      name: 'search-destination',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search-destination" */ '~/screens/SearchDestination')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search/destination/:page',
      name: 'search-destination-custom-page',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search-destination-custom-page" */ '~/screens/SearchDestination')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search/result',
      name: 'search-result',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search-result" */ '~/screens/SearchResult')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/search',
      name: 'search',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "search" */ '~/screens/Search')),
    },
    {
      path: '/:lang/:projectId/explore',
      name: 'explore',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "explore" */ '~/screens/Explore')),
    },
    {
      path: '/:lang/:projectId/category/:category/:floor',
      name: 'category',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "category" */ '~/screens/SeeMoreShop')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/wayfinder',
      name: 'wayfinder',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "wayfinder" */ '~/screens/Wayfinder')),
    },
    {
      path: '/:lang/:projectId/explore/:destination', /** eg. /en/iconsiam/explore/kfc */
      name: 'where-is-it',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "where-is-it" */ '~/screens/WhereIsIt')),
    },
    {
      path: '/:lang/:projectId/:origin([\\s\\S]*)/:destination([\\s\\S]*)/qrcode-generator',
      name: 'qrcode-generator',
      exact: true,
      component: lazy(() => import(/* webpackChunkName: "qrcode-generator" */ '~/screens/QrCodeGenerator')),
    },
    {
      name: 'not-found',
      component: lazy(() => import(/* webpackChunkName: "not-found" */ '~/screens/NotFound')),
    },
  ],
};

/**
 * Applicaiton routes
 *
 * @return {JSX.Element}
 */
function Routes () {
  const { routes, basename, forceRefresh, keyLength, getUserConfirmation, hashType } = router;

  const browserHistory = createBrowserHistory({ basename, forceRefresh, keyLength, getUserConfirmation });
  const hashHistory = createHashHistory({ hashType });

  const history = isProtocolFile() ? hashHistory : browserHistory;

  return (
    <Suspense fallback={<div />}>
      <Router history={history}>
        <Route routes={routes} />
      </Router>
    </Suspense>
  );
}

export default Routes;

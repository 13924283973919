import module from '~/store/modules/VisibilityFilter';

/**
 * Content action
 *
 * @function
 */
function action () {
  return {
    /**
     * Set content to entity store by project slug
     *
     * @param {object} building - buildingOption which has value === id
     */
    set (data) {
      return async (dispatch) => {
        dispatch(module.setBuilding({ payload: data }));
        return data;
      };
    },
  };
}

export default {};
export const BuildingAction = action();

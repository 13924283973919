import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Navbar end component
 * ----------------------------------------------------------------------------
 *
 * @param {{}} props
 * @returns
 */
function NavbarEnd ({ children }) {
  return (
    <div className="navbar-end">
      {children}
    </div>
  );
}

NavbarEnd.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default NavbarEnd;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';

/**
 * ----------------------------------------------------------------------------
 * Mobile view component
 * ----------------------------------------------------------------------------
 *
 * @param {{ render: Function }} props
 * @param {Function} props.render
 * @return {JSX.Element}
 */
function MobileView ({ render }) {
  const { theme } = useTheme();

  return (
    <Wrapper theme={theme} className="mobile-view has-content-centered">
      <div className="mobile-view-container">
        {render()}
      </div>
    </Wrapper>
  );
}

MobileView.propTypes = {
  render: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  &.mobile-view {
    background: ${({ theme }) => theme.colorHelper.greyLight};

    .mobile-view-container {
      background: ${({ theme }) => theme.colorHelper.white};
    }
  }
`;

export default MobileView;

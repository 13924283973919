import React from 'react';

export default () => (
  <>
    <div className="">
      <header className="header2">
        <div className="top-sec row md-hv75">
          <div className="col-md-6 col-xs-12">
            <a href="https://directory.terminal21.co.th/en/terminal21-asok/explore">
              <img
                src="https://www.terminal21.co.th/asok/includes/images/logo.png"
                className="img-responsive"
                id="logo"
              />
            </a>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="social-box">
              <span className="inb-right">
                <div className="input-group ssb">
                  <label>
                    Email <b>Newsletters</b>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="email"
                    className="form-control"
                    style={{ width: "133px" }}
                  />
                  <span className="input-group-btn" style={{ width: "127px" }}>
                    <button
                      type="button"
                      className="btn btn-default mbtn"
                      style={{ width: "100%" }}
                    >
                      Subscribe{" "}
                      <img src="https://www.terminal21.co.th/asok/includes/images/icon-plane.png" />
                    </button>
                  </span>
                </div>
              </span>
              <a href="https://instagram.com/terminal21asok" target="_blank">
                <img
                  src="https://www.terminal21.co.th/asok/includes/images/Instagram_new_icon.png"
                  style={{ width: "40px" }}
                />
              </a>
              <a
                href="https://www.facebook.com/Terminal21Asok"
                target="_blank"
                style={{ marginLeft: "0px" }}
              >
                <img
                  src="https://www.terminal21.co.th/asok/includes/images/facebook_icon.png"
                  style={{ width: "40px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  </>
);

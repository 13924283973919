import React, { useEffect, useState, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useModal, useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import Header from './components/Header';
import Content from './components/Content';
import Buttons from './components/Buttons';
import Logo from './components/Logo';
import Title from './components/Title';
import Body from './components/Body';
import Footer from './components/Footer';
import FooterItem from './components/FooterItem';

/**
 * ----------------------------------------------------------------------------
 * Modal component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Elemtn, name: String, position: (''|'center'|'right'|'left') }} props
 * @return {JSX.Element}
 */
function Modal ({ children, name, position }) {
  const {
    modals,
    isActive,
    close,
    initialModal,
    destroyModal,
  } = useModal();

  const { theme } = useTheme();

  const [ visible, setVisible ] = useState(false);

  const handleClickBackDrop = () => {
    close(name);
  };

  useEffect(() => {
    initialModal(name);

    return () => {
      destroyModal(name);
    };
  }, []);

  useEffect(() => {
    setVisible(isActive(name));
  }, [modals[name]]);

  return (
    <Wrapper
      id={name}
      theme={theme}
      className={bindingClassName(
        'app-modal',
        { 'is-open': visible, 'is-close': !visible },
      )}
    >
      <div
        role="button"
        tabIndex="-1"
        className="app-modal-back-drop"
        onClick={() => handleClickBackDrop()}
        onKeyPress={() => {}}
      />
      <div
        role="button"
        tabIndex="-1"
        className={bindingClassName(
          'app-modal-content-container',
          { [`is-position-${position}`]: position },
        )}
        onClick={(e) => { e.stopPropagation(); }}
        onKeyPress={() => { }}
      >
        {Children.map(children, childrenEl => (
          cloneElement(childrenEl, { name })
        ))}
      </div>
    </Wrapper>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
  position: PropTypes.oneOf([ '', 'center', 'right', 'left' ]),
};

Modal.defaultProps = {
  name: 'modal',
  position: 'center',
};

const Wrapper = styled.div`
  .app-modal {
    &-header {
      border-bottom-color: ${({ theme }) => theme.colorHelper.greyLighter};
    }

    &-footer {
      border-top-color: ${({ theme }) => theme.colorHelper.greyLighter};

      &-item {
        &.is-primary {
          background: ${({ theme }) => theme.palette.primary};
          color: ${({ theme }) => theme.palette.onPrimary};
        }
      }
    }

    &-back-drop {
      background-color: rgba(0, 0, 0, .7);
    }

    &-content-container {
      background-color: ${({ theme }) => theme.colorHelper.white};
    }

    &-buttons {
      &-item {
        border-color: ${({ theme }) => theme.colorHelper.greyLight};
        background-color: ${({ theme }) => theme.colorHelper.white};
        background-origin: border-box;

        &.is-primary {
          border-color: rgba(255, 255, 255, 0);
          border-color: ${({ theme }) => theme.colorHelper.primary};
          background: ${({ theme }) => theme.colorHelper.primary};
          color: ${({ theme }) => theme.colorHelper.white};
        }
      }
    }

    &-body {
      color: ${({ theme }) => theme.colorHelper.greyLight};
    }
  }
`;

Modal.Header = Header;
Modal.Content = Content;
Modal.Buttons = Buttons;
Modal.Logo = Logo;
Modal.Title = Title;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Footer.Item = FooterItem;

export default Modal;

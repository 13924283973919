import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Tiny bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, fluid: Boolean, variant: String }} props
 * @returns
 */
function ParagraphTitle ({ className, head, tail }) {
  const { theme } = useTheme();
  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(`paragraph-title ${className}`)}
    >
      {head && typeof head === 'function' && head()}
      {tail && typeof tail === 'function' && tail()}
    </Wrapper>
  );
}

ParagraphTitle.propTypes = {
  className: PropTypes.string,
  head: PropTypes.func.isRequired,
  tail: PropTypes.func,
};

ParagraphTitle.defaultProps = {
  className: '',
  tail: () => null,
};

const Wrapper = styled.div`
  .title {
    color: theme
  }
  .sub-title {

  }
`;

export default ParagraphTitle;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Hero body component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function Body ({ children }) {
  return (
    <div className="hero-body">
      <div className="container">
        {children}
      </div>
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Body;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Section
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: (JSX.Element|[JSX.Element])}} props
 * @param {(JSX.Element|[JSX.Element])} props.children
 * @param {String} props.className
 * @param {Boolean} [props.horizontal = false]
 * @param {Boolean} [props.vertical = true]
 * @return {JSX.Element}
 */
const Section = ({ children, className, horizontal, vertical }) => (
  <Wrapper
    className={`${bindingClassName({
      section: true,
      'has-horizontal-padding': horizontal,
      'has-vertical-margin': vertical,
    })} ${className}`}
  >
    {children}
  </Wrapper>
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
};

Section.defaultProps = {
  className: '',
  horizontal: false,
  vertical: true,
};

const Wrapper = styled.div``;

export default Section;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';

import { Route as ReactRoute } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import { LoadingSwitch } from '~/explicitComponents';


/**
 * Route component
 *
 * @param {object} props
 * @param {any[]} props.routes
 * @return {JSX.Element}
 */
function Route ({ routes }) {
  const renderRoute = (route, index) => {
    const { name, path, exact, children } = route;
    const hasChildren = Array.isArray(route.children) && !isEmpty(route.children);

    return (
      <ReactRoute
        key={name || index.toString()}
        path={path}
        exact={hasChildren ? false : exact}
        render={props => (
          <route.component
            {...props}
            RouterView={() => <Route routes={children} />}
          />
        )}
      />
    );
  };

  return (
    <LoadingSwitch>
      {routes.map(renderRoute)}
    </LoadingSwitch>
  );
}

Route.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()),
};

Route.defaultProps = {
  routes: [],
};

export default Route;

import helper from '../helper';

import { WAYFINDER_METHOD_OVERVIEW, WAYFINDER_METHOD_STEP_AND_MORE } from '~/app/variables';

/**
 * Entire
 * @description get entire search from redux store
 */
const entire = helper.getEntireSearch;

/**
 * Entire
 * @description verify wayFinderMethod equal to WAYFINDER_METHOD_OVERVIEW or not
 */
const isWayFinderMethodOverview = ({ search }) => search.wayFinderMethod === WAYFINDER_METHOD_OVERVIEW;

/**
 * Entire
 * @description verify wayFinderMethod equal to WAYFINDER_METHOD_STEP_AND_MORE or not
 */
const isWayFinderMethodStepAndMore = ({ search }) => search.wayFinderMethod === WAYFINDER_METHOD_STEP_AND_MORE;

export default {
  entire,
  isWayFinderMethodOverview,
  isWayFinderMethodStepAndMore,
};

import sortBy from 'lodash.sortby';
import { SORT_BY_ALPHABET, SORT_BY_FLOOR } from '~/app/variables';

const getFloorOrderById = (floorId, floors) => {
  const targetFloor = floors.find(floor => floor.id === floorId);
  return (targetFloor && targetFloor.order) || 0;
};

const sortByFloor = (items, floors, direction = 'asc') => {
  if (direction === 'asc') {
    return sortBy(items, ({ floor }) => getFloorOrderById(floor, floors));
  }
  return sortBy(items, ({ floor }) => getFloorOrderById(floor, floors) * -1);
};

const sortByAlphabet = (items, direction = 'asc') => {
  if (direction === 'asc') {
    items.sort((a, b) => {
      if (a.name.en < b.name.en) { return -1; }
      if (a.name.en > b.name.en) { return 1; }
      return 0;
    });
    return items;
  }
  items.sort((a, b) => {
    if (a.name.en < b.name.en) { return 1; }
    if (a.name.en > b.name.en) { return -1; }
    return 0;
  });
  return items;
};

/**
 * ----------------------------------------------------------------------------
 * Explore filter hook
 * ----------------------------------------------------------------------------
 *
 * @param {Array} items
 * @param {{ text: String, category: (String|Number) }} filterOptions
 * @returns
 */
function useExploreFilter (defaultItems, floors, sortBy) {
  /**
   * Shop filter
   * @function
   */
  const filter = ({ text, category }) => {
    if (text || category) {
      let filtered = defaultItems;
      if (category) {
        filtered = filtered.filter(item => item.category === category || item.categories.indexOf(Number(category)) !== -1 || item.categories.indexOf(`${category}`) !== -1);
      }
      if (text) {
        filtered = filtered.filter(item => (
          Object.values(item.name).some(name => name.toLowerCase().includes(text.toLowerCase()))
        ));
      }

      if (sortBy === SORT_BY_ALPHABET) {
        return sortByAlphabet(filtered);
      }
      if (sortBy === SORT_BY_FLOOR) {
        return sortByFloor(filtered, floors);
      }
      return filtered;
    }
    if (sortBy === SORT_BY_ALPHABET) {
      return sortByAlphabet(defaultItems);
    }
    if (sortBy === SORT_BY_FLOOR) {
      return sortByFloor(defaultItems, floors);
    }
    return defaultItems;
  };

  return {
    filter,
  };
}

export default useExploreFilter;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Img from '../Img';
import { useTheme } from '~/hooks';


/**
 * Shop near by card
 *
 * @param {String} logo
 * @param {String} [title='']
 * @param {String} [subTitle='']
 * @param {String} [floor='']
 * @returns {JSX.Element}
 */
function ShopDirectionalCard ({
  logo,
  direction,
  title,
  subTitle,
  floor,
}) {
  const { theme } = useTheme();

  return (
    <Wrapper className="shop-directional-card" theme={theme}>
      <div className="shop-directional-card-img">
        <Img className="img" src={logo} />
      </div>

      <div className="shop-directional-card-content">
        <div>
          <h3 className="is-size-5">{direction}</h3>
          <h3 className="title">{title}</h3>
          <h5 className="sub-title">{subTitle}</h5>
        </div>
      </div>

      <div className="shop-directional-card-floor">
        <h2>{floor.name}</h2>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  label: ShopDirectionalCard;
`;

ShopDirectionalCard.propTypes = {
  logo: PropTypes.string,
  direction: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  floor: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
};

ShopDirectionalCard.defaultProps = {
  logo: '',
  direction: '',
  title: '',
  subTitle: '',
  floor: {},
};

export default ShopDirectionalCard;

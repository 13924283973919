import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Modal logo component
 * ----------------------------------------------------------------------------
 *
 * @param {{ src }} props
 * @param {String} props.src
 */
function Logo ({ src, alt, className }) {
  return (
    <div className="app-modal-logo-container">
      <img className={`app-modal-logo ${className}`} src={src} alt={alt} />
    </div>
  );
}

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Logo.defaultProps = {
  alt: '',
  className: '',
};

export default Logo;

/* eslint-disable max-len */

import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { domainSelector } from '~/store/selector';

import TerminalAsokFooter from './terminal21/Asok';
import TerminalPattayaFooter from './terminal21/Pattaya';
import TerminalKoratFooter from './terminal21/Korat';
import ThePromenade from './thepromenade';
import FashionislandFooter from './fashionisland';

/**
 * Customise footer
 *
 * @return {JSX.Element}
 */
function Footer () {
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    })
  );

  let Footer = <div />;

  switch (projectId) {
    default:
      break;
    case 'terminal21-asok':
      Footer = <TerminalAsokFooter />;
      break;
    case 'terminal21-korat':
      Footer = <TerminalKoratFooter />;
      break;
    case 'terminal21-pattaya':
      Footer = <TerminalPattayaFooter />;
      break;
    case 'fashionisland':
      Footer = <FashionislandFooter />;
      break;
    case 'thepromenade':
      Footer = <ThePromenade />;
      break;
  }

  return <>{Footer}</>;
}

export default Footer;

export default {
  /**
   * Get medias
   *
   * @param {{ entity: {} }} state
   * @param {{}} state.entity
   * @return {Array}
   */
  getMedias ({ entity }) {
    const medias = Object.entries(entity.medias).reduce((result, media) => (
      [ ...result, { id: media[0], ...media[1] }]
    ), []);

    return medias;
  },
};

import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children, className, tabActive }) => (
  <div className={`${className} tab-content ${tabActive === true ? '' : 'is-disabled'}`}>
    {children}
  </div>
);

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  tabActive: PropTypes.bool,
};

Content.defaultProps = {
  className: '',
  tabActive: false,
  children: {},
};

export default Content;

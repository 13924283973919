import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire media
 * @description entire media from redux store
 */
const entire = helper.getEntireMedia;

/**
 * Get media by id
 * @description Get a single media by provide id
 * @param {String|Number} id
 */
const getMediaById = createSelector(
  [entire],
  entireMedia => id => entireMedia[id],
);

export default {
  entire,
  getMediaById,
};

import module from '~/store/modules/Search';
import { WayFinder } from '~/enums/way-finder';
import logger from '~/lib/logger';


/**
 * Project action
 *
 * @function
 */
function action () {
  return {
    /**
     * Set direction strategy
     *
     * @param {import ('../enums/way-finder').WayFinder} strategy - direction startegy enum
     */
    setDirectionStrategy (strategy) {
      return (dispatch) => {
        // use `strategy.value` to prevent error from the current behavior of enum
        // If needed to refactor enum of application please use only `strategy`
        // that mean use only enum object instead of value of enum object.
        dispatch(module.setDirectionStrategy({ payload: strategy.value }));
      };
    },

    /**
     * Set way finder method
     *
     * @deprecated Marked as deprecated since 0.1.5 version. Will remove in 0.2.0 version
     * @param {string} method - way finder method
     */
    setWayFinderMethod (method) {
      return (dispatch) => {
        logger.warn('SearchModule.setWayFinderMethod is marked as deprecated since version 0.1.5. Will remove in 0.2.0 version');

        if (!WayFinder.contains(method)) throw new Error('Expected method of WayFinder Enum');

        // use `method.value` to prevent error from the current behavior of enum
        // If needed to refactor enum of application please use only `method`
        // that mean use only enum object instead of value of enum object.
        dispatch(module.setWayFinderMethod({ payload: method.value }));
      };
    },
  };
}

export default {};
export const SearchAction = action();

import { useEffect, useRef } from 'react';

import useRouter from './useRouter';

const useLightbox = () => {
  const lightboxRef = useRef(null);
  const { getQueryString, setQueryString, removeQueryString } = useRouter();
  const lightbox = getQueryString('lightbox');

  useEffect(() => {
    if (lightbox && lightboxRef.current) {
      lightboxRef.current.toggle('open', lightbox);
    }
  }, [ lightbox, lightboxRef.current ]);

  /**
   * Handle on open and on change lightbox
   * @param {Number} targetIndex
   * @returns {Void}
   */
  const onChangeLightBox = (targetIndex) => {
    setQueryString('lightbox', targetIndex);
  };

  /**
   * Handle on close lightbox
   * @returns {Void}
   */
  const onCloseLightBox = () => {
    removeQueryString('lightbox');
  };

  return {
    lightboxRef,
    onChangeLightBox,
    onCloseLightBox,
  };
};

export default useLightbox;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import Icon from '../Icon';

/**
 * ----------------------------------------------------------------------------
 * Buttons component
 * ----------------------------------------------------------------------------
 *
 * @param {{ items: Array, size: ('small'|'medium'|'large') }} props
 * @return {JSX.Element}
 */
function ButtonAddons ({ items, size }) {
  const [ active, setActive ] = useState(0);
  const { theme } = useTheme();

  const onClick = (index, onClick) => {
    setActive(index);
    if (onClick) onClick();
  };

  const renderEach = (item, index) => (
    <button
      key={index.toString()}
      type="button"
      onClick={() => onClick(index, item.onClick)}
      className={bindingClassName(
        'button-addons-item',
        { 'is-active': index === active },
        { [`is-${size}`]: size },
      )}
    >
      <span
        className={bindingClassName(
          'button-addons-title',
        )}
      >
        <span>{item.label}</span>
      </span>
      <span
        className={bindingClassName(
          'button-addons-icon',
        )}
      >
        <Icon name={item.icon} />
      </span>
    </button>
  );

  return (
    <Wrapper theme={theme}>
      <div className="button-addons">
        {items.map(renderEach)}
      </div>
    </Wrapper>
  );
}

ButtonAddons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
};

ButtonAddons.defaultProps = {
  size: undefined,
};

const Wrapper = styled.div`
  .button-addons {
    &-item {
      &.is-active {
        color: ${({ theme }) => theme.colorHelper.white};
    
        &:before {
          background: ${({ theme }) => theme.colorHelper.primary};
        }
      }
    }
  }
`;

export default ButtonAddons;

import { useSelector as useReduxSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/**
 * ----------------------------------------------------------------------------
 * Selector hooks
 * ----------------------------------------------------------------------------
 *
 * @param {{}} selectors
 * @returns {{}}
 */
function useSelector (selectors) {
  const state = useReduxSelector(createStructuredSelector(selectors));
  return state;
}

export default useSelector;

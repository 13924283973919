import React from 'react';
import styled from '@emotion/styled';

import { useTranslation, useTheme } from '~/hooks';
/**
 * ----------------------------------------------------------------------------
 * Filter category component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function HeroText () {
  const { translate } = useTranslation();
  const { theme } = useTheme();

  const { line, palette } = theme.heading;

  const paletteSize = palette.length;

  const renderMainText = () => {
    const mainText = translate('exploreStore');
    const mainTextLength = mainText.length;
    const elements = [];
    let j = 0;
    for (let i = 0; i < mainTextLength; i += 1) {
      const colorIndex = j % paletteSize;
      const colorHex = palette[colorIndex];
      const char = mainText[i];
      if (char !== ' ') {
        j += 1;
      }
      const element = (
        <span key={`${char}-${i}`} style={{ color: colorHex }}>
          {char}
        </span>
      );
      elements.push(element);
    }

    return elements;
  };

  return (
    <Wrapper className="hero-text">
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
      <div className="hero-text-container">
        <div className="text"><span>{renderMainText()}</span></div>
        <div className="text"><span>&</span></div>
        <div className="text"><span>{translate('wayFinding')}</span></div>
      </div>
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
    </Wrapper>
  );
}

HeroText.propTypes = {
};

HeroText.defaultProps = {
};

const Wrapper = styled.div`
  /* margin: 20px 0; */
`;

const Line = styled.div`
  border: ${({ color }) => `1px solid ${color}`};
`;

export default HeroText;

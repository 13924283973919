import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { domainSelector, projectSelector } from '~/store/selector';
import { useTheme, useTranslation } from '~/hooks';
import { getFile, bindingClassName } from '~/utils';

import HamburgerIcon from './components/HamburgerIcon';

import LanguageSwitcher from '../LanguageSwitcher';

function MobileNavigationButton ({ openAsDefault }) {
  const [ visible, setVisible ] = useState(openAsDefault);

  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  const { domain, project } = useSelector(createStructuredSelector({
    domain: domainSelector.entire,
    project: projectSelector.entire,

  }));
  const { theme } = useTheme();
  const { translate } = useTranslation();

  const { projectId } = domain;
  const { navbar } = project;
  const { leftMenu } = navbar;

  return (
    <Wrapper
      theme={theme}
      className="mobile-navigation-button"
    >
      <div
        className="icon-left"
        role="button"
        tabIndex="-1"
        onKeyPress={() => { }}
        onClick={handleOpen}
      >
        <HamburgerIcon />
      </div>
      <div
        className={bindingClassName(
          'opacity-panel',
          { 'is-open': visible, 'is-close': !visible },
        )}
        role="button"
        tabIndex="-1"
        onKeyPress={() => { }}
        onClick={handleClose}
      />
      <div
        className={bindingClassName(
          'drawer-content',
          { 'is-open': visible, 'is-close': !visible },
        )}
      >
        <div
          className="drawer-content-header"
          onKeyPress={() => { }}
          onClick={handleClose}
          role="button"
          tabIndex="-1"
        >
          <img src={getFile(`/images/${projectId}/logo-navbar.png`)} alt="" />
        </div>
        <div className="drawer-content-body">
          {
            leftMenu.map(menu => (
              <div className="menu-item">
                <a target="_blank" rel="noopener noreferrer" href={menu.href}><span className="is-size-4">{translate(menu.label)}</span></a>
              </div>
            ))
          }
        </div>
        <div className="drawer-content-footer">
          <LanguageSwitcher />
        </div>
      </div>
    </Wrapper>
  );
}

MobileNavigationButton.propTypes = {
  openAsDefault: PropTypes.bool,
};

MobileNavigationButton.defaultProps = {
  openAsDefault: true,
};

const Wrapper = styled.div`
  .drawer-content {
    background: ${({ theme }) => theme.colorHelper.white};
  }
  .drawer-content-body span {
    color: ${({ theme }) => theme.colorHelper.secondary};
  }
`;

export default MobileNavigationButton;

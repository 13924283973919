/**
 * Transform node from Phlox API to fit the current format
 *
 * @return {object}
 */
function transform (nodes) {
  return nodes.reduce((result, node) => ({
    ...result,
    [node.title]: {
      ...node,
      neighbor: node.neighbors.map(neighbor => neighbor.title),
    },
  }), {});
}

export default transform;

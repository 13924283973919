import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Masonry component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }}
 * @returns {JSX.Element}
 */
function Masonry ({ children, columns }) {
  const computeSize = (el, property) => parseInt(window.getComputedStyle(el).getPropertyValue(property), 10);

  const handleResize = () => {
    console.debug('call resize');
    const masonry = document.getElementsByClassName('masonry')[0];
    const rowHeight = computeSize(masonry, 'grid-auto-rows');
    const rowGap = computeSize(masonry, 'grid-row-gap');

    masonry.childNodes.forEach((child) => {
      const { height } = child.childNodes[0].getBoundingClientRect();
      const rowEnd = Math.round((height + rowGap) / (rowHeight + rowGap));

      child.style.gridRowEnd = `span ${rowEnd}`; /* eslint-disable-line */
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [children.length]);

  return (
    <Wrapper
      className={bindingClassName(
        'masonry',
        { [`has-${columns}`]: columns },
      )}
    >
      {children}
    </Wrapper>
  );
}

Masonry.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  columns: PropTypes.number,
};

Masonry.defaultProps = {
  columns: 2,
};

const Wrapper = styled.div``;

export default Masonry;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Left from './components/Left';
import Right from './components/Right';
import Item from './components/Item';

/**
 * ----------------------------------------------------------------------------
 * Level component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function Level ({ children }) {
  return (
    <Wrapper className="level">
      {children}
    </Wrapper>
  );
}

Level.Left = Left;
Level.Right = Right;
Level.Item = Item;
Level.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const Wrapper = styled.nav``;


export default Level;

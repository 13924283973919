import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Drawer footer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function Footer ({ children }) {
  return (
    <div className="drawer-footer">
      {children}
    </div>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

/**
 * ----------------------------------------------------------------------------
 * Bottom drawer expanded component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @param {JSX.Element} props.children
 * @returns
 */
function Expanded ({ children, animatedProp }) {
  return (
    <animated.div
      className="bottom-drawer-content-expanded"
      style={{
        opacity: animatedProp.opacity.interpolate({
          range: [ 125, 165 ],
          output: [ 0, 1 ],
        }),
        display: animatedProp.opacity.interpolate(value => (
          value >= 110 ? 'block' : 'none'
        )),
      }}
    >
      {children}
    </animated.div>
  );
}

Expanded.displayName = 'Expanded';
Expanded.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  animatedProp: PropTypes.shape(),
};

Expanded.defaultProps = {
  animatedProp: {},
};

export default Expanded;

import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire
 * @description entire project account from redux store
 */
const entire = helper.getEntireDomain;

/**
 * Project id
 * @description get project id from domain module on redux store
 */
const projectId = createSelector(
  [entire],
  ({ projectId }) => projectId,
);

/**
 * Project account
 * @description get project account from domain module on redux store
 */
const projectAccount = createSelector(
  [entire],
  ({ projectAccount }) => projectAccount,
);

export default {
  entire,
  projectId,
  projectAccount,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ListItem = ({
  id,
  items,
  keyExtractor,
  containerClassName,
  renderAs: RenderAs,
  injectContainerProp,
  numItems,
  render,
  renderEmpty,
  renderSeparator,
  renderHeader,
  renderFooter,
}) => {
  const renderList = () => (
    items.slice(0, numItems).map((item, index) => (
      <Fragment key={keyExtractor(item, index)}>
        <Fragment>
          {render(item, index)}
        </Fragment>
        {items.slice(0, numItems).length - 1 !== index && renderSeparator && renderSeparator()}
      </Fragment>
    ))
  );

  return (
    <RenderAs id={id} {...injectContainerProp} className={containerClassName}>
      {renderHeader && renderHeader()}
      {
        items.length <= 0
          ? renderEmpty()
          : renderList()
      }
      {renderFooter && renderFooter()}
    </RenderAs>
  );
};

const Wrapper = styled.ul``;

ListItem.propTypes = {
  id: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.arrayOf(PropTypes.array),
  ]),
  keyExtractor: PropTypes.func,
  containerClassName: PropTypes.string,
  renderAs: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
    PropTypes.string,
  ]),
  injectContainerProp: PropTypes.shape(),
  numItems: PropTypes.number,
  render: PropTypes.func,
  renderEmpty: PropTypes.func,
  renderSeparator: PropTypes.func,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
};

ListItem.defaultProps = {
  id: '',
  items: [],
  keyExtractor () {},
  containerClassName: '',
  renderAs: Wrapper,
  injectContainerProp: {},
  numItems: undefined,
  render () {},
  renderEmpty () {},
  renderSeparator () {},
  renderHeader: undefined,
  renderFooter: undefined,
};

export default ListItem;

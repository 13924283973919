import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Redux store action hook
 * ----------------------------------------------------------------------------
 *
 * @param {{}} actions - receive data as an object
 * @returns
 */
function useAction (actions) {
  const dispatch = useDispatch();

  if (!isEmpty(actions)) {
    return bindActionCreators(actions, dispatch);
  }

  return dispatch;
}

export default useAction;

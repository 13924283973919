import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useTheme } from '~/hooks';
import { getFile, bindingClassName } from '~/utils';
import {
  domainSelector,
} from '~/store/selector';
import { Icon } from '~/components';

function BuildingSelector () {
  const { projectId } = useSelector(
    createStructuredSelector({
      projectId: domainSelector.projectId,
    }),
  );
  const [ active, setActive ] = useState(false);
  const { theme } = useTheme();
  const contentRef = useRef(null);
  const buildings = [];
  const hasMoreThanOneBuilding = buildings.length > 0;
  return (
    <Wrapper theme={theme}>
      <div className="building-selector">
        <div className="building-selector-logo">
          <img
            className="logo"
            src={getFile(`/images/${projectId}/logo.png`)}
            alt="logo"
          />
          {
            hasMoreThanOneBuilding && (
              <div
                className={bindingClassName({
                  'logo-chevron rotate': active,
                  'logo-chevron': !active,
                })}
                onClick={() => setActive(!active)}
                role="button"
                tabIndex="0"
                onKeyPress={() => {}}
                ref={contentRef}
              >
                <Icon name="ChevronDown" color={theme.colorHelper.white} />
              </div>
            )
          }
        </div>
        <div
          className={bindingClassName(
            'building-selector-list',
            { 'is-active': active },
          )}
        >
          <div className="list-container has-padding-layout">
            {buildings.map((item, index) => (
              <>
                <p className="list">{item}</p>
                {index !== buildings.length - 1 && <div className="list-separator" />}
              </>
            ))}
          </div>
        </div>
      </div>
      <div
        className={bindingClassName(
          'backdrop',
          { 'is-active': active },
        )}
        onClick={() => setActive(!active)}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  label: BuildingSelector;
  height: 100%;
  .list-separator {
    background: ${({ theme }) => theme.colorHelper.separator};
  }
`;

BuildingSelector.propTypes = {
};
BuildingSelector.defaultProps = {
};

export default BuildingSelector;

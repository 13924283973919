import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import cx from 'classnames';

import { Layout, ProgressBar } from '~/components';
import { useTheme } from '~/hooks';


/**
 * Initial loading component
 *
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.text
 * @param {number} props.progress
 * @param {number} props.strokeWidth
 * @param {boolean} props.spinning
 * @param {React.ReactChildren} props.children
 * @return {JSX.Element}
 */
function Loading ({ src, text, progress, strokeWidth, spinning, children }) {
  const { theme } = useTheme();

  return (
    <Wrapper theme={theme} className="loading">
      {
        spinning && (
          <div className={cx('loading-container')}>
            <div className="loading-banner">
              <img src={src} alt="logo" />
            </div>

            <Layout className="loading-body">
              <div className="has-content-centered">
                <ProgressBar.Circular progress={progress} strokeWidth={strokeWidth} />
                <h3 className="loading-text is-size-3">{text}</h3>
              </div>
            </Layout>
          </div>
        )
      }

      {!spinning && children}
    </Wrapper>
  );
}

Loading.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string,
  progress: PropTypes.number,
  strokeWidth: PropTypes.number,
  spinning: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Loading.defaultProps = {
  text: 'Loading',
  progress: 0,
  spinning: true,
  strokeWidth: 12,
};

const Wrapper = styled.div`
  .loading-banner {
    background: ${({ theme }) => theme.header.large};
  }

  .loading-page-main-container {
    background: ${({ theme }) => theme.progressBar.background};
  }
`;

export default Loading;

import React from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';

/**
 * ----------------------------------------------------------------------------
 * Widget component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, animatedProp: {} }} props
 * @param {JSX.Element} props.children
 * @param {{}} props.animatedProp
 * @return
 */
function Widget ({ children, animatedProp }) {
  return (
    <animated.div style={{ transform: animatedProp.transform.interpolate(value => value) }} className="bottom-drawer-widget">
      {children}
    </animated.div>
  );
}

Widget.displayName = 'Widget';
Widget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  animatedProp: PropTypes.shape(),
};

Widget.defaultProps = {
  animatedProp: {},
};

export default Widget;

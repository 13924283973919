import { combineReducers } from 'redux';

import domain from './Domain';
import entity from './Entity';
import search from './Search';
import project from './Project';
import visibilityFilter from './VisibilityFilter';

export default combineReducers({
  domain: domain.state,
  entity: entity.state,
  search: search.state,
  project: project.state,
  visibilityFilter: visibilityFilter.state,
});

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Card body component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function Body ({ children, left, right }) {
  return (
    <div className={bindingClassName('app-card-body', { left, right })}>
      {children}
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool,
};

Body.defaultProps = {
  left: false,
  right: false,
};

export default Body;

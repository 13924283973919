import fetcher from '~/lib/fetcher';


class Service {
  /**
   * Constructor
   *
   * @constructor
   * @param {string} endpoint
   */
  constructor (endpoint) {
    this.endpoint = endpoint;
  }

  /**
   * Find resources
   *
   * @param {{}} params
   * @return {any[]}
   */
  async find (params, options) {
    const { data } = await fetcher(this.endpoint, { params, ...options });

    return data;
  }

  /**
   * Get a single resource
   *
   * @param {string | number} id - resource id
   * @param {object} query
   */
  async get (id, query) {
    const params = new URLSearchParams(query);
    const { data } = await fetcher(`${this.endpoint}/${id}`, { params });

    return data;
  }

  /**
   * Create new resource
   *
   * @param {any} resource - resource data
   */
  async create (resource) {
    const { data } = await fetcher.post(this.endpoint, resource);

    return data;
  }

  /**
   * Update the existing resource
   *
   * @param {string | number} id - resource id
   * @param {any} resource - request body
   */
  async update (id, resource) {
    const { data } = await fetcher.put(`${this.endpoint}/${id}`, resource);

    return data;
  }

  /**
   * Remove the existing resource
   *
   * @param {number | string} id - resource id
   * @param {any} params - url query params
   */
  async remove (id, params) {
    const { data } = await fetcher.delete(`${this.endpoint}/${id}`, { params });

    return data;
  }
}

export default {};
export const BaseService = Service;

import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire category
 * @description entire category from redux store
 */
const entire = helper.getEntireCategory;

/**
 * Categories
 * @description Categories which convert to array
 */
const categories = createSelector(
  [entire],
  entireCategory => Object.entries(entireCategory).reduce((result, category) => ([ ...result, { id: category[0], ...category[1] }]), []),
);

/**
 * Get category by id
 * @description Get a single category by provide id
 * @param {String|Number} id
 */
const getCategoryById = createSelector(
  [helper.getEntireCategory],
  entireCategory => id => entireCategory[id],
);

/**
 * Get only categories
 * @description get an array of category which is a parent of relationship (category)
 */
const getOnlyCategories = createSelector(
  [categories],
  categories => categories.filter(category => !category.parent),
);

/**
 * Get only sub-categories
 * @description get an array of category which has a parent property (sub-category)
 */
const getOnlySubCategories = createSelector(
  [categories],
  categories => categories.filter(category => category.parent),
);

export default {
  entire,
  categories,
  getCategoryById,
  getOnlyCategories,
  getOnlySubCategories,
};

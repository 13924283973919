import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Drawer header component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, fixed: Boolean }} props
 * @returns
 */
function Header ({ children, fixed }) {
  return (
    <div
      className={bindingClassName(
        'drawer-header',
        { 'is-position-fixed': fixed },
      )}
    >
      {children}
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
};

Header.defaultProps = {
  fixed: false,
};

export default Header;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useClickOutside } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Navbar dropdown component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function Dropdown ({
  children,
  items,
  uppercase,
  lowercase,
  capitalise,
}) {
  const [ visible, setVisible ] = useState(false);
  const elRef = useRef(null);

  useClickOutside(elRef, () => {
    setVisible(false);
  });

  return (
    <div ref={elRef} className="navbar-item has-dropdown">
      <div
        className={bindingClassName(
          'is-fullwidth',
          { 'is-text-uppercase': uppercase },
          { 'is-text-lowercase': lowercase },
          { 'is-text-capitalise': capitalise },
        )}
        role="button"
        tabIndex="-1"
        onClick={() => setVisible(!visible)}
        onKeyPress={() => {}}
      >
        {children}
      </div>

      <div
        className={bindingClassName(
          'navbar-dropdown',
          { 'is-active': visible },
        )}
      >
        {
          items.map(({ icon: Icon, label, onClick }, index) => (
            <div
              tabIndex="-1"
              role="button"
              className="navbar-item"
              key={index.toString()}
              onClick={onClick}
              onKeyPress={() => {}}
            >
              {Icon && (
                <div className="navbar-item-icon">
                  {Icon}
                </div>
              )}
              {label}
            </div>
          ))
        }
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalise: PropTypes.bool,
};

Dropdown.defaultProps = {
  uppercase: false,
  lowercase: false,
  capitalise: false,
};

export default Dropdown;

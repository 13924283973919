/**
 * Get building from redux store
 * @description get building and return without any action on buildin data
 * @param {{ entity: {} }} state
 */
// use building not buildings followed by firestore data
const getEntireBuilding = ({ entity }) => entity.building;

/**
 * Get shops from redux store
 * @description get shops and return without any action on shop data
 * @param {{ entity: {} }} state
 */
const getEntireShop = ({ entity }) => entity.shops;

/**
 * Get floors from redux store
 * @description get floors and return without any action on floor data
 * @param {{ entity: {} }} state
 */
const getEntireFloor = ({ entity }) => entity.floors;

/**
 * Get medias from redux store
 * @description get medias and return without any action on media data
 * @param {{ entity: {} }} state
 */
const getEntireMedia = ({ entity }) => entity.medias;

/**
 * Get categories from redux store
 * @description get categories and return without any action on category data
 * @param {{ entity: {} }} state
 */
const getEntireCategory = ({ entity }) => entity.categories;

/**
 * Get visibility filter from redux store
 * @param {{ visibilityFilter: {} }} state
 */
const getEntireVisibilityFilter = ({ visibilityFilter }) => visibilityFilter;

/**
 * Get entire floor icon
 * @description get floor icon and return without any action on floor icon data
 * @param {{ entity: {} }} state
 */
const getEntireFloorIcon = ({ entity }) => entity.floorIcon;

/**
 * Get entire project from redux store
 * @description get proejct and return without any action on project data
 * @param {{ project: {} }} state
 */
const getEntireProject = ({ project }) => project;

/**
 * Get entire setting from redux store
 * @description get setting and return without any action on setting data
 * @param {{ entity: {} }} state
 */
const getEntireSetting = ({ entity }) => entity.setting;

/**
 * Get entire search from redux store
 * @description get search and return without any action on search data
 * @param {{ search: {} }} state
 */
const getEntireSearch = ({ search }) => search;

/**
 * Get entire domain from redux store
 * @description get domain and return without any action on domain data
 * @param {{ domain: {} }} state
 */
const getEntireDomain = ({ domain }) => domain;

/**
 * Get entire kiosk from redux store
 * @description get kiosk and return without any action on kiosk data
 * @param {{ entity: {} }} state
 */
const getEntireKiosk = ({ entity }) => entity.kiosks;

export default {
  getEntireBuilding,
  getEntireShop,
  getEntireFloor,
  getEntireMedia,
  getEntireCategory,
  getEntireVisibilityFilter,
  getEntireFloorIcon,
  getEntireProject,
  getEntireSetting,
  getEntireSearch,
  getEntireDomain,
  getEntireKiosk,
};

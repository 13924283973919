import React from 'react';

import { useTheme } from '~/hooks';

function HamBurgerIcon () {
  const { theme } = useTheme();
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="32.674"
      height="19.041"
      stroke={theme.colorHelper.secondary}
      viewBox="0 0 32.674 19.041"
    >
      <g
        fill="none"
        strokeLinecap="round"
        strokeWidth="2"
        data-name="Group 1"
        transform="translate(1 1)"
      >
        <path d="M0 0h30.674" data-name="Path 1" />
        <path
          d="M0 0L17.041 0"
          data-name="Line 2"
          transform="translate(0 8.521)"
        />
        <path
          d="M0 0L23.857 0"
          data-name="Line 3"
          transform="translate(0 17.041)"
        />
      </g>
    </svg>
  );
}

export default HamBurgerIcon;

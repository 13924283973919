import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';

import { theme as defaultTheme } from '~/config';

/** react context */
export const ThemeContext = createContext();

/**
 * Theme provider
 *
 * @param  {Object} props
 * @param  {JSX.Element} props.children
 */
const ThemeProvider = ({ children }) => {
  const [ theme, setTheme ] = useState(defaultTheme);
  const { global } = theme;

  const globalTheme = css`
    html {
      font-size: ${global.fontSize};
    }

    body {
      color: ${global.textColor};
      font-family: ${global.fontFamily} !important;
      line-height: ${global.lineHeight};
    }

    *:focus {outline:none;} ::-moz-focus-inner {border:0;}

    a:active,
    a:focus,
    input,
    input:active,
    input:focus {
        outline: 0;
        outline-style:none;
        outline-width:0;
    }
    a:active,
    a:focus,
    button::-moz-focus-inner,
    input[type="reset"]::-moz-focus-inner,
    input[type="button"]::-moz-focus-inner,
    input[type="submit"]::-moz-focus-inner,
    input[type="file"] > input[type="button"]::-moz-focus-inner {
        border: none;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
    }
  `;

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Global styles={globalTheme} />
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeProvider.defaultProps = {
};

export default ThemeProvider;

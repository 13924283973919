import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useModal } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Modal buttons component
 * ----------------------------------------------------------------------------
 *
 * @param {{ items, name }} props
 * @param {Array} props.items
 * @param {String} props.name
 */
function Buttons ({ items, name }) {
  const { close, closeAll } = useModal();

  const handleOnClick = (onClick = () => {}, clickBehavior) => {
    onClick();

    const func = clickBehavior === 'close-all' ? closeAll : close;
    func(name);
  };

  const renderButton = () => (
    <Fragment>
      {
        items.map(({
          style,
          variant,
          onClick,
          title,
          clickBehavior,
        }, index) => (
          <button
            type="button"
            key={index.toString()}
            className={bindingClassName({
              'app-modal-buttons-item': true,
              [`is-${style}-style`]: style,
              [`is-${variant}`]: variant,
            })}
            onClick={() => handleOnClick(onClick, clickBehavior)}
          >
            {title}
          </button>
        ))
      }
    </Fragment>
  );

  return (
    <div className="app-modal-buttons">
      {renderButton()}
    </div>
  );
}

Buttons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    variant: PropTypes.string,
    clickBehavior: PropTypes.oneOf([ 'close', 'close-all' ]),
    onClick: PropTypes.func,
  })),
  name: PropTypes.string,
};

Buttons.defaultProps = {
  items: [],
  name: '',
};

export default Buttons;

import color from '../Color';

export default {
  /** global variant */
  global: {
    fontSize: '16px',
    fontFamily: 'Helvetica, Kanit, Arial',
    textColor: color.black,
  },
  /** color helper such as primary, secondary, or success */
  colorHelper: {
    primary: color.getDirection,
    success: color.green,
    danger: color.red,
    warn: color.orange,
    link: color.blue,
    helperText: color.greyLight,
    separator: color.greyLighter,
  },

  input: {},

  img: {},

  progressBar: {
    mode: 'color',
    track: color.greyLighter,
    indicator: color.getDirection,
  },

  header: {
    large: color.greyLighter,
    normal: color.greyLighter,
  },

  wayfinding: {
    originPin: color.getDirection,
    destination: color.getDirection,
    routingPathTrack: color.greyLight,
    routingPathIndicator: color.getDirection,
  },


  backgroundColor: color.white,
  textColor: color.black,
  placeholderColor: color.greyLight,
  borderColor: color.greyLight,
  headerBorderColor: color.greyLighter,
  separatorColor: color.greyLighter,
  helperTextColor: color.greyLight,
  boxShadow: '0 0 5px hsla(0, 0%, 0%, .4)',
  successColor: color.green,
  greyLightColor: color.greyLight,
  primaryColor: color.getDirection,
  whiteColor: color.white,
  transparentColor: 'transparent',
  linkColor: color.blue,
  largeHeaderBackground: color.cyan,
  headerBackground: color.cyan,
};

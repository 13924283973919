/**
 * @deprecated since version 0.1.5
 */

// color
export const COLOR_MAIN = '#000000';
export const COLOR_SECOND = '#fffffff';
export const COLOR_THIRD = '#404404';

// mediaQuery
export const breakpoints = {
  xs: '575px',
  md: '767px',
  lg: '991px',
  xl: '1199px',
};

// zIndex
export const ZINDEX_HEADER = 9;
export const ZINDEX_FOOTER = 9;
export const ZINDEX_POPUP = 8;

// dimension
export const DIM_HEADER_HEIGHT = '100px';
export const DIM_FOOTER_HEIGHT = '100px';

// wayfinder method
export const WAYFINDER_METHOD_OVERVIEW = 'overview';
export const WAYFINDER_METHOD_STEP_AND_MORE = 'step-and-more';

// sort by
export const SORT_BY_ALPHABET = 'sort-by-alphabet';
export const SORT_BY_FLOOR = 'sort-by-floor';

// location
export const ORIGIN = 'origin';
export const DESTINATION = 'destination';

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Column item component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  chilren: JSX.Element,
 *  size: (String|Number),
 *  gaps: (String|Number),
 * }} props
 * @returns
 */
function Item ({ children, size, gaps }) {
  return (
    <div
      className={bindingClassName(
        'column-item',
        { [`is-${size}`]: size },
        { [`is-gaps-${gaps}`]: gaps },
      )}
    >
      {children}
    </div>
  );
}

/**
 * Custom prop types for size and mobile size
 */
const size = ({ size }) => {
  if (typeof size === 'number' && (size < 1 || size > 12)) {
    return new Error('Invalid size (1 - 12)');
  }

  const twentyPercentSystem = [ 'one-fifth', 'two-fifth', 'three-fifth', 'four-fifth' ];

  if (typeof size === 'string' && !twentyPercentSystem.includes(size)) {
    return new Error(`Invalide size ${twentyPercentSystem}`);
  }

  return null;
};

const gaps = ({ gaps }) => {
  if (typeof gaps === 'number' && (gaps < 1 || gaps > 12)) {
    return new Error('Invalid size (1 - 12)');
  }

  const twentyPercentSystem = [ 'one-fifth', 'two-fifth', 'three-fifth', 'four-fifth' ];

  if (typeof gaps === 'string' && !twentyPercentSystem.includes(gaps)) {
    return new Error(`Invalide size ${twentyPercentSystem}`);
  }

  return null;
};


Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  size,
  gaps,
};

Item.defaultProps = {
  size: 0,
  gaps: 0,
};

export default Item;

import React from 'react';
// import PropTypes from "prop-types";
import styled from '@emotion/styled';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { projectSelector } from '~/store/selector';

import { useTranslation, useRouter } from '~/hooks';
import { bindingClassName } from '~/utils';

import { Flag } from '~/components';

function LanguageSwitcher () {
  const { project } = useSelector(
    createStructuredSelector({
      project: projectSelector.entire,
    }),
  );
  const { changeParam } = useRouter();
  const { currentLanguage, changeLanguage } = useTranslation();

  const handleSelectLanguage = (language) => {
    changeParam({ lang: language });
    changeLanguage(language);
  }

  return (
    <Wrapper className="language-switcher">
      {
        project.languages.map(language => (
          <div
            className={bindingClassName('flag-container', {
              'is-active': currentLanguage === language,
            })}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectLanguage(language)}
            onKeyPress={() => null}
          >
            <Flag code={language === 'en' ? 'gb' : language} />
          </div>
        ))
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
`;

export default LanguageSwitcher;

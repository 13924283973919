import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Navbar burger component
 * ----------------------------------------------------------------------------
 *
 * @param {{ active: Boolean, onClick: Function }} props
 * @returns {JSX.Element}
 */
function Burger ({ active, onClick }) {
  return (
    <div
      role="button"
      tabIndex="-1"
      className={bindingClassName(
        'navbar-burger',
        { 'is-active': active },
      )}
      onClick={onClick}
      onKeyPress={() => {}}
    >
      <span />
    </div>
  );
}

Burger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Burger.defaultProps = {
  active: false,
  onClick () {},
};

export default Burger;

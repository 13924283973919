// copy cat from gist.github.com
// https://gist.github.com/stefanwalther/b7ad36b8d6428bb57112

class EnumSymbol {
  /**
   * Constructor
   *
   * @constructor
   * @param {string} key
   * @param {any} value
   */
  constructor (key, value) {
    if (!Object.is(value, undefined)) this.value = value;
    if (key) this.symbol = Symbol.for(key);

    Object.freeze(this);
  }

  toString () {
    return this.symbol.toString();
  }

  valueOf () {
    return this.value;
  }
}

class Enum {
  /**
   * Constructor
   *
   * @constructor
   * @param {{}} enums
   */
  constructor (enums) {
    const entries = Object.entries(enums);
    this.indexes = {};

    entries.forEach(([ key, value ]) => {
      this[key] = new EnumSymbol(key, value);
      this.indexes[value] = this[key];
    });

    Object.freeze(this);
  }

  contains (symbol) {
    if (!(symbol instanceof EnumSymbol)) return false;

    return symbol === this[Symbol.keyFor(symbol.symbol)];
  }

  /**
   * Get the target enum by value
   *
   * @param {string} value - value of enum
   */
  search (value) {
    return this.indexes[value];
  }
}


export default {};
export { Enum };

import { useEffect } from "react";

import { searchSelector, shopSelector, kioskSelector } from "~/store/selector";
import searchActions from "~/store/actions/Search";

import useSelector from "./useSelector";
import useAction from "./useAction";

import useTranslation from "./useTranslation";
import useRouter from "./useRouter";
import { isEmpty, slugify } from "~/utils";

/**
 * Splite string which use in split function
 * @example
 * input: 'hello$world';
 * output: [ 'hello', 'world' ]
 * @constant
 */
const SPLIT_STRING = "$";

/**
 * ----------------------------------------------------------------------------
 * Search location checker hooks
 * ----------------------------------------------------------------------------
 * Checking the url pathname for assign origin and destination to redux store, if there are provided.
 *
 * @param {Function} effect Imperative function that can return a cleanup function
 * @param {!Array} deps If present, effect will only activate if the values in the list change.
 * @return {Void}
 */
const useSearchLocationChecker = (effect = () => {}, deps = []) => {
  const { setOrigin, setDestination, setIsAbleToGetDirection } = useAction(
    searchActions
  );
  const { search, shops, kiosks } = useSelector({
    search: searchSelector.entire,
    shops: shopSelector.shops,
    kiosks: kioskSelector.kiosks,
  });

  const { match } = useRouter();
  const { translate } = useTranslation();

  const { originShop, destinationShop } = search;
  const {
    origin: originRouterParam,
    destination: destinationRouterParam,
  } = match.params;

  /**
   * Get shop or kiosk by name and its floor
   * @param {String} name
   * @param {String} floor
   */
  const getByNameAndFloor = (name, floor) => {
    try {
      const trimAndLowerCaseName = slugify.encode(name.trim().toLowerCase());
      const pointOfInterests = [...shops, ...kiosks];
      return pointOfInterests.find((pointOfInterest) => {
        const names =
          typeof pointOfInterest.name === "string"
            ? slugify.encode(pointOfInterest.name.trim().toLowerCase())
            : [...Object.values(pointOfInterest.name), pointOfInterest.unit]
                .filter(Boolean)
                .map((name) => slugify.encode(name.trim().toLowerCase()))
                .filter(Boolean);

        if (pointOfInterest.floor === floor) {
          if (typeof names === "string") {
            return names === trimAndLowerCaseName;
          }
          return names.indexOf(trimAndLowerCaseName) !== -1;
        }
        return false;
      });
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  /**
   * Get shop or kiosk by name
   * @param {String} name
   */
  const getByName = (name) => {
    try {
      const trimAndLowerCaseName = slugify.encode(name.trim().toLowerCase());
      const pointOfInterests = [...shops, ...kiosks];
      return pointOfInterests.find((pointOfInterest) => {
        const names =
          typeof pointOfInterest.name === "string"
            ? slugify.encode(pointOfInterest.name.trim().toLowerCase())
            : [...Object.values(pointOfInterest.name), pointOfInterest.unit]
                .filter(Boolean)
                .map((name) => slugify.encode(name.trim().toLowerCase()))
                .filter(Boolean);

        if (typeof names === "string") {
          return names === trimAndLowerCaseName;
        }
        return names.indexOf(trimAndLowerCaseName) !== -1;
      });
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  /**
   * Set origin
   */
  const handleSetOrigin = async () => {
    try {
      if (originRouterParam) {
        const [name, floor] = originRouterParam.split(SPLIT_STRING);
        let shop = {};

        if (name && floor) {
          shop = getByNameAndFloor(name, floor);
        } else {
          shop = getByName(name);
        }
        let originSearchText =
          typeof shop.name === "string" ? shop.name : translate(shop.name);
        if (!originSearchText || originSearchText === "") {
          originSearchText = shop.unit;
        }
        await setOrigin({
          originSearchText,
          originShop: shop,
        });
      }
    } catch (error) {
      console.error(error);
      effect(error);
    }
  };

  /**
   * Set destination
   */
  const handleSetDestination = async () => {
    try {
      if (destinationRouterParam) {
        const [name, floor] = destinationRouterParam.split(SPLIT_STRING);
        let shop = {};

        if (name && floor) {
          shop = getByNameAndFloor(name, floor);
        } else {
          shop = getByName(name);
        }

        let destinationSearchText =
          typeof shop.name === "string" ? shop.name : translate(shop.name);
        if (!destinationSearchText || destinationSearchText === "") {
          destinationSearchText = shop.unit;
        }
        await setDestination({
          destinationSearchText,
          destinationShop: shop,
        });
      }
    } catch (error) {
      console.error(error);
      effect(error);
    }
  };

  const checkingUrlPath = async () => {
    if (isEmpty(originShop) && isEmpty(destinationShop)) {
      await handleSetOrigin();
      await handleSetDestination();
    }
    effect();
  };

  useEffect(() => {
    checkingUrlPath();
  }, deps);

  useEffect(() => {
    if (originShop && destinationShop) {
      if (originShop.node && destinationShop.node) {
        setIsAbleToGetDirection(true);
        return;
      }
      setIsAbleToGetDirection(false);
    }
  }, [originShop, destinationShop]);
};

export default useSearchLocationChecker;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import Body from './components/Body';

/**
 * ----------------------------------------------------------------------------
 * Hero component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  children: JSX.Element,
 *  size: (''|'medium'|'large'|'full'),
 *  variant: (''|'secondary'|'secondary-variant'),
 *  background: String,
 * }} props
 * @returns
 */
function Hero ({ children, size, variant, background }) {
  const { theme } = useTheme();

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'hero',
        { [`is-${size}`]: size },
        { [`is-${variant}`]: variant },
      )}
      background={background}
    >
      {children}
    </Wrapper>
  );
}

Hero.Body = Body;
Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  size: PropTypes.oneOf([ '', 'medium', 'large', 'full' ]),
  variant: PropTypes.oneOf([ '', 'secondary', 'secondary-variant' ]),
  background: PropTypes.string,
};

Hero.defaultProps = {
  size: '',
  variant: '',
  background: '',
};

const Wrapper = styled.div`
  background-color: ${({ theme, background }) => `${theme.colorHelper.whiteTer} url(${background}) no-repeat center/cover`};
  color: ${({ theme }) => theme.colorHelper.black};

  &.is-secondary-variant {
    background: ${({ theme, background }) => `${theme.palette.secondaryVariant} url(${background}) no-repeat center/cover`};
    color: ${({ theme }) => theme.palette.onSecondaryVariant};
  }

  &.is-secondary {
    background: ${({ theme, background }) => `${theme.palette.secondary} url(${background}) no-repeat center/cover`};
    color: ${({ theme }) => theme.palette.onSecondary};
  }
`;

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Card header component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @returns
 */
function Header ({ children }) {
  return (
    <div className="app-card-header">
      {children}
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';
import Icon from '../Icon';

/**
 * ----------------------------------------------------------------------------
 * AnimatedGradientButton component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  texts: Array<String>,
 *  disabled: Boolean
 *  icon: String
 * }} props
 * @return {JSX.Element}
 */
function Button ({
  texts,
  icon,
  active,
  showArrow,
  arrowPosition,
  disabled,
  ...rest
}) {
  const { theme } = useTheme();
  const iconColor = active ? theme.colorHelper.white : theme.colorHelper.grey;
  return (
    <Wrapper
      {...rest}
      theme={theme}
      disabled={disabled}
      className={bindingClassName(`animated-gradient-button ${arrowPosition}`, {
        active,
        'show-arrow': showArrow,
      })}
    >
      <div className="title">
        {texts.map(text => <p>{text}</p>)}
      </div>
      {icon && <Icon name={icon} color={iconColor} />}
    </Wrapper>
  );
}

Button.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  showArrow: PropTypes.bool,
  arrowPosition: PropTypes.string,
  icon: PropTypes.string,
};

Button.defaultProps = {
  texts: [ 'STEP', 'AND', 'MORE' ],
  disabled: false,
  active: false,
  showArrow: false,
  arrowPosition: 'bottom',
  icon: undefined,
};

const Wrapper = styled.button`
  background: ${({ theme }) => theme.colorHelper.grey2};
  position: relative;

  @media only screen and (max-width: 500px) {
    background: ${({ theme }) => theme.colorHelper.white};
  }

  &.active {
    background: ${({ theme }) => theme.wayFinderMethodNavigation.activeColor};
    .title {
      p {
        color: ${({ theme }) => theme.colorHelper.white};
      }
    }
  }
  &.show-arrow.top {
    &:before {
      border-bottom: 0px;
      border-top: 15px solid ${({ theme }) => theme.wayFinderMethodNavigation.arrowLastGradient};
    }
    
    @media only screen and (max-width: 500px) {
      &:before {
        border-top: 10px solid ${({ theme }) => theme.wayFinderMethodNavigation.arrowLastGradient};
      } 
    }
  }
  &.show-arrow.bottom {
    &:before {
      border-top: 0px;
      border-bottom: 15px solid ${({ theme }) => theme.wayFinderMethodNavigation.arrowFirstGradient};
    }
  }
  .title {
    p {
      color: ${({ theme }) => theme.colorHelper.grey};
    }
  }
`;

export default Button;

import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import modules from './modules';

export default (initialState = {}) => {
  const middlewares = [thunk];

  if (process.env.NODE_ENV !== 'production') middlewares.push(logger);

  return createStore(
    modules,
    initialState,
    applyMiddleware(...middlewares),
  );
};

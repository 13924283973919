import { createModule } from 'redux-modux';

const initialState = {
  projectId: '',
  appState: '',
};

const handlers = {
  setDomain (state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  setProjectId (state, action) {
    return {
      ...state,
      projectId: action.payload,
    };
  },
  setAppState (state, action) {
    return {
      ...state,
      appState: action.payload,
    };
  },
  setProjectAccount (state, action) {
    return {
      ...state,
      projectAccount: action.payload,
    };
  },
};

export default createModule({
  moduleName: 'domain', /** Optional (Prefix when console.log  action's type) */
  initialState,
  handlers,
});

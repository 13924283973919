import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Field from './components/Field';
import Control from './components/Control';

const Form = ({ children }) => (
  <Wrapper className="form">
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Form.Field = Field;
Form.Control = Control;

export default Form;

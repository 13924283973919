import helper from '../helper';

/**
 * Entire
 * @description entire project from redux store
 */
const entire = helper.getEntireProject;

export default {
  entire,
};

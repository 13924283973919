import { useEffect, useState } from 'react';

import { categorySelector, floorSelector } from '~/store/selector';

import useRouter from './useRouter';
import useTranslation from './useTranslation';
import useSelector from './useSelector';
import { slugify } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Search see more hooks
 * ----------------------------------------------------------------------------
 */
function useSearchSeeMore () {
  const { categories, getFloorById } = useSelector({
    categories: categorySelector.getOnlyCategories,
    getFloorById: floorSelector.getFloorById,
  });

  const [ category, setCategory ] = useState({});
  const [ floor, setFloor ] = useState({});

  const { translate } = useTranslation();
  const { match } = useRouter();

  useEffect(() => {
    const { category: categoryURLParam, floor: floorURLParam } = match.params;

    setCategory(categories.find(category => categoryURLParam === slugify.encode(translate(category.name))));
    setFloor(getFloorById(floorURLParam));
  }, []);

  return {
    category,
    floor,
  };
}

export default useSearchSeeMore;

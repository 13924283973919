import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Img from '../Img';

/**
 * ----------------------------------------------------------------------------
 * Gallery component
 * ----------------------------------------------------------------------------
 * Remake: The lightbox can open by set 'lightbox' query string with target index.
 *
 * @param {{ items: Array onClick: Function, renderEmpty: Function }} props
 * @returns
 */
function Gallery ({ items, onClick, renderEmpty }) {
  const getImageSrc = (item) => {
    if (item && typeof item === 'string') return item;
    if (item && item.url && typeof item.url === 'string') return item.url;
    return undefined;
  };
  /**
   * Render function
   * @render
   * @returns
   */
  const render = () => (
    <div className="gallery-row">
      {items.map((item, index) => (
        <div
          key={index.toString()}
          className="gallery-item"
          role="button"
          tabIndex="-1"
          onKeyPress={() => {}}
          onClick={() => onClick(index)}
        >
          <Img
            src={getImageSrc(item)}
          />
        </div>
      ))}
    </div>
  );

  /**
   * Render when length of items property equal to 0
   * @render
   * @returns
   */
  const renderEmptyGallery = () => (
    renderEmpty ? renderEmpty() : (
      <div>empty</div>
    )
  );

  return (
    <Wrapper className="gallery-container">
      {items.length > 0 ? render() : renderEmptyGallery()}
    </Wrapper>
  );
}

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
  renderEmpty: PropTypes.func,
};

Gallery.defaultProps = {
  items: [],
  onClick () {},
  renderEmpty: undefined,
};

const Wrapper = styled.div``;

export default Gallery;

import React from 'react';

export default () => (
  <footer className="footer  md-hv70">
    <div className="inner">
      <div className="row">
        <div className="col-md-6 col-xs-12 wrp-logo">
          <a
            href="https://www.terminal21.co.th/pattaya/"
            className="mr-10"
            target="_blank"
          >
            <img src="https://www.terminal21.co.th/asok/includes/images/logo_pattaya.png" />
            >
          </a>
          <a
            href="https://www.terminal21.co.th/korat/"
            className="mr-10"
            target="_blank"
          >
            <img src="https://www.terminal21.co.th/asok/includes/images/logo-terminal-s.png" />
          </a>
          <a
            href="https://www.terminal21.co.th/youngbrander/"
            className="mr-10"
            target="_blank"
          >
            <img src="https://www.terminal21.co.th/asok/includes/images/YB-Header-elements_04.png" />
          </a>
          <a href="https://www.grandecentrepointhotels.com/" target="_blank">
            <img src="https://www.terminal21.co.th/asok/includes/images/grand-centre-point-logo.png" />
          </a>
          <a href="https://www.tripadvisor.com.my/" target="_blank">
            <img src="https://www.terminal21.co.th/asok/includes/images/logo-trip.png" />
          </a>
        </div>
        <div className="col-md-6 col-xs-12">
          <p style={{ marginBottom: 10 }}>
            Copyright © 2020 Terminal21 (Terminal21 Shopping Mall). All rights
            reserved.
          </p>
          <a href="https://www.terminal21.co.th/asok/aboutus/en">ABOUT US</a>
          <a href="https://www.terminal21.co.th/asok/contactus/en">
            CONTACT US
          </a>
          <a href="https://www.terminal21.co.th/asok/careers/en">CAREERS</a>
          <a href="https://www.terminal21.co.th/asok/shop/en">SHOP</a>
          <a href="https://www.terminal21.co.th/asok/privacy/en">PRIVACY</a>
        </div>
      </div>
    </div>
  </footer>
);

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';
import Icon from '../Icon';

/**
 * ----------------------------------------------------------------------------
 * Search bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{ id, name, value, placeholder, onTextChange, renderIconLeft, renderIconRight, onClearInput }} props
 */
const SearchBar = ({
  id,
  name,
  value,
  text,
  placeholder,
  onTextChange,
  onEnter,
  clearInput,
  autoFocus,
  marginless,
  paddingless,
  renderIconLeft,
  renderIconRight,
  onClearInput,
}) => {
  const { theme } = useTheme();
  const elRef = useRef(null);

  const [ inputValue, setInputValue ] = useState(value);
  const [ inputTimeout, setInputTimeout ] = useState(0);

  /**
   * On text changing function
   *
   * @description This function is called after onChange event from <input /> el is fired.
   * @param {String} text
   */
  const handleOnTextChange = (text) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputValue(text);

    setInputTimeout(setTimeout(() => {
      onTextChange(text);
    }, 400));
  };

  const handleOnClearInput = () => {
    elRef.current.value = '';
    setInputValue('');

    onClearInput();

    setTimeout(() => {
      onTextChange('');
      elRef.current.focus();
    }, 400);
  };

  const handleOnKeyPress = (event) => {
    const { keyCode } = event;
    if (typeof onEnter === 'function' && keyCode === 13) {
      elRef.current.blur();
      onEnter();
    }
  };

  useEffect(() => {
    if (autoFocus) {
      elRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Wrapper
      theme={theme}
      id={id}
      className={bindingClassName(
        'search-bar', {
          'is-marginless': marginless,
          'is-paddingless': paddingless,
        },
      )}
    >
      <div className="text-head">
        {text}
      </div>
      <div
        className={bindingClassName(
          'search-bar-container', {
            'has-icons-left': renderIconLeft,
            'has-icons-right': renderIconRight,
          },
        )}
      >
        <input
          name={name}
          type="text"
          ref={elRef}
          placeholder={placeholder}
          value={inputValue}
          onChange={({ target }) => handleOnTextChange(target.value)}
          onKeyDown={handleOnKeyPress}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
        />
        {
          renderIconLeft && (
            <span className="input-icon is-left">
              {renderIconLeft()}
            </span>
          )
        }
        {
          !clearInput && renderIconRight && (
            <span className="input-icon is-right">
              {renderIconRight()}
            </span>
          )
        }
        {
          inputValue && clearInput && (
            <span
              className="input-icon is-right"
              role="button"
              tabIndex="-1"
              onClick={() => handleOnClearInput()}
              onKeyPress={() => { }}
            >
              <Icon name="Times" />
            </span>
          )
        }
      </div>
      <div className="divided" />
    </Wrapper>
  );
};

SearchBar.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func,
  onEnter: PropTypes.func,
  autoFocus: PropTypes.bool,
  clearInput: PropTypes.bool,
  marginless: PropTypes.bool,
  paddingless: PropTypes.bool,
  renderIconLeft: PropTypes.func,
  renderIconRight: PropTypes.func,
  onClearInput: PropTypes.func,
};

SearchBar.defaultProps = {
  id: '',
  name: '',
  value: '',
  text: '',
  placeholder: '',
  onTextChange () {},
  onEnter: () => null,
  autoFocus: false,
  clearInput: undefined,
  marginless: false,
  paddingless: false,
  renderIconLeft: undefined,
  renderIconRight: undefined,
  onClearInput () { },
};

const Wrapper = styled.div`
  &.search-bar {
    .text-head .first-letter {
      color: ${({ theme }) => theme.colorHelper.secondary};
    }
    .search-bar-container {
      input[type=text] {
        ::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }

        & ~ span {
          color: ${({ theme }) => theme.input.placeholder};
        }

        &:focus {
          border-color: ${({ theme }) => theme.input.active};

          & ~ span {
            color: ${({ theme }) => theme.input.active};
          }
        }

      }
    }
    .divided {
      border-color: ${({ theme }) => theme.colorHelper.greyLight}
    }
  }
`;


export default SearchBar;

import React from 'react';

export default () => (
  <>
    <footer id="customise">
      <section id="footer1">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="sidemap hidden-sm hidden-xs visible-lg">
                <a href="https://www.thepromenade.co.th/event">EVENT</a>
                <a href="https://www.thepromenade.co.th/about">ABOUT US</a>
                <a href="https://www.thepromenade.co.th/directory/13">
                  DIRECTORY
                </a>
                <a href="https://www.thepromenade.co.th/promotion">PROMOTION</a>
                <a href="https://www.thepromenade.co.th/gallery">GALLERY</a>
                <a href="https://www.thepromenade.co.th/e-newsletter">
                  E-Newsletter
                </a>
                <a href="https://www.thepromenade.co.th/contact">CONTACT US</a>
              </div>

              <div className="sidemap" style={{ padding: "10px 0" }}>
                <div id="contact" className="row justify-content-center">
                  <div className="col-sm-4 col-md-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ThePromenadeTH"
                    >
                      <img
                        src="https://www.thepromenade.co.th//images/icon1.png"
                        className=""
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/thepromenadeth"
                    >
                      <img
                        src="https://www.thepromenade.co.th//images/icon2.png"
                        className=""
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/explore/locations/14838252/"
                    >
                      <img
                        src="https://www.thepromenade.co.th//images/icon3.png"
                        className=""
                        alt=""
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://line.me/ti/p/%40thepromenadeth"
                    >
                      <img
                        src="https://www.thepromenade.co.th//images/line.png"
                        className=""
                        alt=""
                      />
                    </a>
                  </div>

                  <div
                    className="col-sm-4 col-md-3 hidden-sm hidden-xs visible-lg"
                    style={{ padding: "10px 0px" }}
                  >
                    <div className="row">
                      <div className="col-xs-12">
                        <form
                          action="https://www.thepromenade.co.th/directory.php"
                          method="POST"
                        >
                          <div className="input-group">
                            <input
                              type="text"
                              id="tagss"
                              className="form-control input-sm"
                              name="text"
                              placeholder="Search directory..."
                            />
                            <span className="input-group-btn">
                              <button
                                className="btn btn-default"
                                name="submit_search"
                                type="submit"
                              >
                                Search!
                              </button>
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-- col-md-3">
                    <a href="https://www.thepromenade.co.th/card/">
                      <img
                        src="https://www.thepromenade.co.th//images/card.png"
                        className="img-responsive img"
                        style={{ height: 80, margin: "15px auto -10px auto" }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="sidemap" style={{ padding: "10px 0px" }}>
                <div id="email-to" className="row justify-content-center">
                  <div className="col-sm-6 col-md-6">
                    <a href="mailto:info@thepromenade.co.th">
                      info@thepromenade.co.th
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  </>
);

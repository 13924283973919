import { css } from '@emotion/core';
import variables from './Variables';

export default css`
  html {
    font-size: 10px;
  }

  body {
    color: ${variables.textColor};
  }

  * {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  }

  h1 {
    font-size: 5rem;
    font-weight: bold;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3.75rem;
  }

  h4 {
    font-size: 3.25rem;
  }

  h5 {
    font-size: 3rem;
  }

  h6 {
    font-size: 2.75rem;
  }
`;

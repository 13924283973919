import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactSlick from 'react-slick';

import { useTheme } from '~/hooks';

import { breakpoints } from '~/app/variables';

import Icon from './components/Icon';

const defaultSettings = {
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: false,
  dots: false,
  arrows: true,
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return <Icon className={className} style={style} name="ChevronRight" onClick={onClick} />;
};

NextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return <Icon className={className} style={style} name="ChevronLeft" onClick={onClick} />;
};

PrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

/**
 * ----------------------------------------------------------------------------
 * Slider component
 * ----------------------------------------------------------------------------
 *
 */
const Slider = forwardRef(({ children, slidesToShowByBreakpoint, settings, onBeforeChange, onAfterChange }, ref) => {
  const { theme } = useTheme();

  /* slider method */
  const handleBeforeSliderChange = (current, next) => {
    if (onBeforeChange) onBeforeChange(current, next);
  };

  const handleAfterSliderChange = (next) => {
    if (onAfterChange) onAfterChange(next);
  };

  const getSlideResponsiveSetting = slidesToShowByBreakpoint => Object.keys(breakpoints).map((windowSize) => {
    const [breakpoint] = breakpoints[windowSize].split('px');
    const slidesToShow = slidesToShowByBreakpoint[windowSize];
    return {
      breakpoint: Number(breakpoint) + 1,
      settings: {
        slidesToShow,
      },
    };
  });

  const mergedSettings = {
    ...defaultSettings,
    ...settings,
    responsive: getSlideResponsiveSetting(slidesToShowByBreakpoint),
  };

  /* slider method */
  return (
    <Wrapper theme={theme}>
      <ReactSlick
        ref={ref}
        {...mergedSettings}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        beforeChange={handleBeforeSliderChange}
        afterChange={handleAfterSliderChange}
      >
        {children}
      </ReactSlick>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  .slick-arrow {
    color: ${({ theme }) => theme.colorHelper.secondary};
    z-index: 10;
  }
  .slick-arrow.slick-next {
    right: 0;
  }
  .slick-arrow.slick-prev {
    left: 0;
  }
  .slick-arrow.slick-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

Slider.propTypes = {
  children: PropTypes.node,
  onBeforeChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  settings: PropTypes.shape({}),
  slidesToShowByBreakpoint: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
};
Slider.defaultProps = {
  children: <div />,
  onBeforeChange: () => null,
  onAfterChange: () => null,
  settings: {},
  slidesToShowByBreakpoint: {
    xs: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

export default Slider;

import { createSelector } from 'reselect';

const getFloors = state => state.entity.floors;
const getMedias = state => state.entity.medias;
const getShops = ({ entity: { shops } }) => (
  Object.entries(shops).reduce((result, shop) => (
    [ ...result, { id: shop[0], ...shop[1] }]
  ), [])
);

export default {
  /**
   * Get floors
   *
   * @param {{ entity: {} }} state
   * @param {{}} state.entity
   * @return {Array}
   */
  getFloors: createSelector(
    [ getFloors, getMedias, getShops ],
    (floors, medias, shops) => (
      Object.entries(floors).reduce((result, floor) => {
        const { elements, fields, units, id: floorId } = floor[1];

        return (
          {
            ...result,
            [floor[0]]: {
              ...floor[1],
              fields: {
                ...fields,
                floorplan: medias[fields.floorplan].url,
              },
              elements: elements.map((element) => {
                if (element.icon) {
                  return {
                    ...element,
                    category: 'facility',
                    icon: medias[element.icon] ? medias[element.icon].url : undefined,
                  };
                }

                return {};
              }),
              units: units.map((unit) => {
                const shopOnUnit = shops.find(shop => (
                  shop.units && shop.units.length > 0
                    ? shop.units.some(shopUnit => shopUnit.unitNo === unit.id && shopUnit.floorId === floorId)
                    : undefined
                ));
                if (shopOnUnit && shopOnUnit.logo) {
                  const logoKey = shopOnUnit.logo;
                  return {
                    ...unit,
                    x: unit.elements.logo.x,
                    y: unit.elements.logo.y,
                    category: 'shop',
                    icon: medias[logoKey] ? medias[logoKey].url : undefined,
                    hasShop: true,
                  };
                }

                return {
                  id: unit.id,
                  ...unit,
                  category: 'shop',
                  hasShop: false,
                };
              }),
            },
          }
        );
      }, [])
    ),
  ),
};

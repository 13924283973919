import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName, isEmpty } from '~/utils';

import Img from '../Img';
import Icon from '../Icon';

/**
 * ----------------------------------------------------------------------------
 *EntityOverviewCard component
 * ----------------------------------------------------------------------------
 * Remake: The lightbox can open by set 'lightbox' query string with target index.
 *
 * @param {{ items: Array onClick: Function, renderEmpty: Function }} props
 * @returns
 */
const EntityOverviewCard = ({ type, logo, title, subtitle, floor, description, contacts }) => {
  const { theme } = useTheme();
  const [ viewMoreActive, setViewMore ] = useState(false);

  const handleToggleViewMore = () => {
    setViewMore(!viewMoreActive);
  };
  const renderDescription = () => description;
  const renderContactIcon = (contactType) => {
    switch (contactType) {
      case 'tel':
        return <Icon name="PhoneSquare" />;
      case 'facebook':
        return <Icon name="FacebookSquare" />;
      case 'instagram':
        return <Icon name="Instagram" />;
      default:
        return '';
    }
  };
  return (
    <Wrapper className={`entity-overview-card ${type}`} theme={theme}>
      <div className="text-shop" />
      <div className="logo-container">
        <Img
          className="entity-logo"
          src={logo}
        />
      </div>
      <div className="entity-body">
        <div className="entity-header">
          <div className="entity-title-block">
            <span className="entity-floor-text is-text-uppercase is-size-6">{floor}</span>
            <h2 className="entity-title-text is-text-uppercase is-size-4">{title}</h2>
            <h3 className="entity-subtitle-text is-text-uppercase is-size-5">{subtitle}</h3>
          </div>
        </div>
        <div className="entity-description">
          <h4 className={bindingClassName('entity-description-text is-size-6', { extend: viewMoreActive })}>
            {renderDescription()}
          </h4>
          {
            renderDescription() !== '' && (
              <div tabIndex={0} role="button" className="button-view-more is-text-uppercase" onClick={handleToggleViewMore} onKeyPress={() => null}>
                {viewMoreActive ? 'hide' : 'see more'}
              </div>
            )
          }
        </div>
        {!isEmpty(contacts) && <hr className="section-line" />}
        <div className="entity-contact-container">
          {
            Object.keys(contacts).reduce((acc, contactKey) => {
              const contact = contacts[contactKey];
              const node = (
                <div className="entity-contact-item" key={contactKey}>
                  {renderContactIcon(contactKey)}
                  <span className="is-size-7">{`${contact}`}</span>
                </div>
              );
              return [
                ...acc,
                node,
              ];
            }, [])
          }
        </div>
      </div>
    </Wrapper>
  );
};

EntityOverviewCard.propTypes = {
  type: PropTypes.oneOf(['shop']),
  logo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  floor: PropTypes.string,
  description: PropTypes.string,
  contacts: PropTypes.shape({}),
};

EntityOverviewCard.defaultProps = {
  type: 'shop',
  logo: '',
  title: '',
  subtitle: '',
  floor: '',
  description: '',
  contacts: {},
};

const Wrapper = styled.div`
  label: EntityOverviewCard;
  .entity-floor-text {
    color: ${({ theme }) => theme.colorHelper.greyLight};
  }
  .entity-title-text {
    color: ${({ theme }) => theme.colorHelper.black};
  }
  .entity-subtitle-text {
    color: ${({ theme }) => theme.colorHelper.grey};
  }
  .entity-description-text {
    color: ${({ theme }) => theme.colorHelper.black};
  }
  .button-view-more {
    color: ${({ theme }) => theme.colorHelper.secondary};
  }
  .section-line {
    background-color: ${({ theme }) => theme.colorHelper.greyLight};
  }
`;

export default EntityOverviewCard;

import React, { useReducer, useRef, cloneElement, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Hammer from 'react-hammerjs';
import styled from '@emotion/styled';
import { useSpring, animated, config } from 'react-spring';

import { useTheme } from '~/hooks';

import Widget from './components/Widget';
import Content from './components/Content';
import Expanded from './components/Expanded';
import Collapse from './components/Collapse';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'pan-start':
      return { ...state, ...payload, isPanning: true };

    case 'pan':
      return { ...state, ...payload };

    case 'pan-end':
      return { ...state, ...payload, isPanning: false };

    default:
      throw new Error('Unexpected action');
  }
};

const initialState = {
  start: 0,
  move: 0,
  current: 0,
  direction: '',
  isPanning: false,
};

/**
 * ----------------------------------------------------------------------------
 * Bottom drawer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @param {JSX.Element} props.children
 * @returns
 */
const BottomDrawer = forwardRef(({ children }, ref) => {
  const { theme } = useTheme();
  const elRef = useRef();
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const renderChildren = children.reduce((result, child) => ({ ...result, [child.type.displayName]: child }), {});

  const [ animatedProp, setAnimated ] = useSpring(() => ({
    from: {
      transform: 'translateY(0px)',
      opacity: 1,
    },
    to: {
      transform: `translateY(${-state.move}px)`,
      opacity: state.current,
    },
    immediate: true,
  }));

  /**
   * Handle on pan start
   *
   * @param {{ changedPointers: [], additionalEvent: String }} event
   * @param {Array} event.changedPointers
   * @param {String} event.additionalEvent
   */
  const handleOnPanStart = ({ changedPointers, additionalEvent }) => {
    dispatch({
      type: 'pan-start',
      payload: {
        start: changedPointers[0].clientY,
        direction: additionalEvent,
      },
    });
  };

  /**
   * Handle on pan
   *
   * @param {{ changedPointers: [] }} event
   * @param {Array} event.changedPointers
   */
  const handleOnPan = ({ changedPointers }) => {
    const { start, current } = state;
    const delta = (start - changedPointers[0].clientY) + current;
    const maxTranslate = elRef.current.clientHeight / 1.2;
    const move = delta > maxTranslate ? maxTranslate : delta;

    setAnimated({
      to: {
        transform: `translateY(${-move}px)`,
        opacity: delta,
      },
      immediate: true,
    });

    dispatch({
      type: 'pan',
      payload: { move },
    });
  };

  /**
   * Handle on pan end
   */
  const handleOnPanEnd = () => {
    const { move } = state;
    const maxTranslate = 160;
    console.debug(maxTranslate - 80);
    let current = move;

    if (move < maxTranslate - 80 && move > 0) {
      current = 0;
    } else {
      current = 80;
    }


    setAnimated({
      to: {
        transform: `translateY(${-current}px)`,
        opacity: current,
      },
      immediate: false,
    });

    dispatch({
      type: 'pan-end',
      payload: { current },
    });
  };

  const handleToggle = () => {
    setAnimated({
      to: {
        transform: `translateY(${-80}px)`,
        opacity: 80,
      },
      config: config.slow,
      immediate: false,
    });

    dispatch({
      type: 'pan-end',
      payload: { current: 80 },
    });
  };

  useImperativeHandle(ref, () => ({
    toggle: handleToggle,
  }));

  return (
    <Wrapper
      theme={theme}
      className="bottom-drawer"
    >
      <div ref={elRef}>
        {/* Widget */}
        {renderChildren.Widget && cloneElement(renderChildren.Widget, { animatedProp })}
        <Hammer
          direction="DIRECTION_VERTICAL"
          onPanStart={handleOnPanStart}
          onPan={handleOnPan}
          onPanEnd={handleOnPanEnd}
        >
          <animated.div
            style={{ transform: animatedProp.transform.interpolate(value => value) }}
            className="bottom-drawer-container"
          >
            {/* pinch bar */}
            <div className="pinch-bar" />

            {/* Drawer content */}
            {renderChildren.Content && cloneElement(renderChildren.Content)}

            {renderChildren.Collapse && cloneElement(renderChildren.Collapse, { animatedProp })}
            {renderChildren.Expanded && cloneElement(renderChildren.Expanded, { animatedProp })}
          </animated.div>
        </Hammer>
      </div>
    </Wrapper>
  );
});

BottomDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

BottomDrawer.defaultProps = {
};

BottomDrawer.Widget = Widget;
BottomDrawer.Content = Content;
BottomDrawer.Expanded = Expanded;
BottomDrawer.Collapse = Collapse;

const Wrapper = styled.div`
  &.bottom-drawer {
    .bottom-drawer-container {
      background: ${({ theme }) => theme.colorHelper.white};
    }

    .pinch-bar {
      &:after {
        background: ${({ theme }) => theme.colorHelper.primary};
      }
    }
  }
`;

export default BottomDrawer;

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Input text component
 * ----------------------------------------------------------------------------
 */
const Input = ({
  plainText,
  onClick,
  onChange,
  onEnter,
  id,
  name,
  className,
  label,
  value,
  helpText,
  borderless,
  renderIconLeft,
  renderIconRight,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [ inputTimeout, setInputTimeout ] = useState(0); /* eslint-disable-line */
  const [ inputText, setInputText ] = useState(value);

  const { theme } = useTheme();

  const handleOnTextChange = (event) => {
    const { target: { value } } = event;
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputText(value);
    setInputTimeout(setTimeout(() => {
      onChange(value, event);
    }, 400));
  };

  const handleOnKeyPress = (event) => {
    const { keyCode } = event;
    if (typeof onEnter === 'function' && keyCode === 13) {
      inputRef.current.blur();
      onEnter();
    }
  };

  useEffect(() => {
    setInputText(value);
  }, [value]);

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'input',
        className,
        { 'is-borderless': borderless },
      )}
      onClick={onClick}
    >
      {
        label
        && (
          <div className="input-label-container">
            <label htmlFor={id} className="input-label">{label}</label>
          </div>
        )
      }
      <div
        className={bindingClassName(
          'input-container',
          {
            'has-icons-left': renderIconLeft,
            'has-icons-right': renderIconRight,
          },
        )}
      >
        <input
          id={id}
          name={name}
          ref={inputRef}
          className={bindingClassName({ 'is-plain': plainText })}
          onChange={handleOnTextChange}
          onKeyDown={handleOnKeyPress}
          value={inputText}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          {...rest}
        />
        {
          renderIconLeft && (
            <span className="input-icon is-left">
              {renderIconLeft()}
            </span>
          )
        }
        {
          renderIconRight && (
            <span className="input-icon is-right">
              {renderIconRight()}
            </span>
          )
        }
      </div>
      {/* {
        helpText
        && (
          <div className="help-text-container">
            <span className="help-text">{helpText}</span>
          </div>
        )
      } */}
    </Wrapper>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf([ 'text', 'password' ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  plainText: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  helpText: PropTypes.string,
  borderless: PropTypes.bool,
  renderIconLeft: PropTypes.func,
  renderIconRight: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  onChange () {},
  onEnter: () => null,
  plainText: false,
  readOnly: false,
  onClick () {},
  id: '',
  name: '',
  className: '',
  label: '',
  value: '',
  helpText: '',
  borderless: false,
  renderIconLeft: undefined,
  renderIconRight: undefined,
};

const Wrapper = styled.div`
  &.input {
    &.is-active {
      .input-container {
        input[type=text] {
          border-color: ${({ theme }) => theme.input.active};
        }
      }
    }
    .input-container {
      input[type=text] {
        border-color: ${({ theme }) => theme.input.border};

        ::placeholder {
          color: ${({ theme }) => theme.input.placeholder};
        }

        & ~ span {
          color: ${({ theme }) => theme.input.placeholder};
        }

        &:read-only {
          & ~ span {
            color: ${({ theme }) => theme.input.active};
          }
        }

        &:focus {
          border-color: ${({ theme }) => theme.input.active};

          & ~ span {
            color: ${({ theme }) => theme.input.active};
          }
        }
      }
    }
  }

  .help-text {
    color: ${({ theme }) => theme.colorHelper.helperText};
  }
`;

export default Input;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Modal body component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children }} props
 * @param {JSX.Element} props.children
 */
function Body ({ children }) {
  return (
    <div className="app-modal-body">
      {children}
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Body;

/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

function FacilityItem ({ items, onClick, active }) {
  const [ showItem, setShowItem ] = useState(false);

  const { theme } = useTheme();

  const handleShowMore = () => {
    setShowItem(true);
  };

  const numberOfItems = showItem ? items.length : 5;

  return (
    <Wrapper theme={theme}>
      <div className="facility-list">
        {items.slice(0, numberOfItems).map(item => (
          <button
            className={bindingClassName(
              'item',
              { 'is-active': active },
            )}
            onClick={onClick}
          >
            <div className="facility-icon">
              {item}
            </div>
          </button>
        ))}
        <button className="button-show-more" onClick={handleShowMore}>More</button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .facility-list {
    background: linear-gradient(
      to bottom,
      rgba(255, 0, 0, 0),
      rgba(255, 255, 255, 1)
    );

    .item,
    .button-show-more {
      background: ${({ theme }) => theme.colorHelper.white};
    }
    .item {
      .facility-icon {
        svg {
          fill: ${({ theme }) => theme.colorHelper.grey2};
        }

        &.is-active {
          svg {
            fill: #b6a16c;
          }
        }
      }
    }
  }
`;

FacilityItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

FacilityItem.defaultProps = {
  items: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
  onClick: undefined,
  active: false,
};

export default FacilityItem;

import React, { useEffect, Fragment, useReducer } from 'react';
import PropTypes from 'prop-types';
import LocalStorage from 'local-storage';

import { getFile } from '~/utils';

const APP_VERSION = 'app-version';

/**
 * Semver greater than
 *
 * @param {String} latestVersion
 * @param {String} currentVersion
 */
const semverGreaterThan = (latestVersion, currentVersion) => {
  const latest = latestVersion.split(/\./g);
  const current = currentVersion.split(/\./g);

  while (latest.length || current.length) {
    const a = Number(latest.shift());

    const b = Number(current.shift());
    /* eslint-disable-next-line no-continue */
    if (a === b) continue;
    /* eslint-disable-next-line no-restricted-globals */
    return a > b || isNaN(b);
  }
  return false;
};

const reducer = (state, { loading, isLatestVersion }) => ({
  ...state,
  loading,
  isLatestVersion,
});

const initialState = {
  loading: true,
  isLatestVersion: false,
};

/**
 * ----------------------------------------------------------------------------
 * Cache buster component
 * ----------------------------------------------------------------------------
 */
function CacheBuster ({ children }) {
  const [{ loading, isLatestVersion }, dispatch ] = useReducer(reducer, initialState);

  /**
   * Refresh cache and reload
   *
   * @returns {Promise<void>}
   */
  const refreshCacheAndReload = async () => {
    console.debug('Clearing cahce and hard reloading...');
    try {
      if (Object.getOwnPropertyNames(caches).length > 0) {
        const { names } = await caches.keys();
        /** Service worker cache should be cleared with caches.delete() */
        names.map(name => caches.delete(name));
      }
    } finally {
      /** delete browser cache and hard reload */
      window.location.reload(true);
    }
  };

  /**
   * Check app version
   *
   * @returns {Promise<void>}
   */
  const checkVersion = async () => {
    const response = await fetch(getFile('/meta.json'));
    const meta = await response.json();
    const latestVersion = meta.version;
    const currentVersion = LocalStorage.get(APP_VERSION) || '0.0.0';
    console.debug(currentVersion);

    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

    if (shouldForceRefresh) {
      console.debug(`We have a new version - ${latestVersion}. Should force refresh`);

      LocalStorage.set(APP_VERSION, meta.version);
      dispatch({ loading: false, isLatestVersion: false });
      return;
    }

    console.debug(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
    dispatch({ loading: false, isLatestVersion: true });
  };

  useEffect(() => {
    checkVersion();
  }, []);

  /** clear cache */
  useEffect(() => {
    if (!loading && !isLatestVersion) {
      console.debug({ loading, isLatestVersion });
      refreshCacheAndReload();
    }
  }, [ loading, isLatestVersion ]);

  return (
    <Fragment>
      { !loading && children }
    </Fragment>
  );
}

CacheBuster.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CacheBuster;

import deprecatedShopSelector from './shopDeprecated';
import deprecatedFloorSelector from './floorDeprecated';
import deprecatedMediaSelector from './mediaDeprecated';
import facilitySelector from './facilityDeprecated';

import buildingSelector from './building';
import categorySelector from './category';
import visibilityFilterSelector from './visibilityFilter';
import shopSelector from './shop';
import mediaSelector from './media';
import floorSelector from './floor';
import floorIconSelector from './floorIcon';
import projectSelector from './project';
import settingSelector from './setting';
import searchSelector from './search';
import domainSelector from './domain';
import kioskSelector from './kiosk';

export default {
  ...deprecatedShopSelector,
  ...deprecatedFloorSelector,
  ...deprecatedMediaSelector,
  ...facilitySelector,
};

export {
  buildingSelector,
  categorySelector,
  visibilityFilterSelector,
  shopSelector,
  mediaSelector,
  floorSelector,
  floorIconSelector,
  projectSelector,
  settingSelector,
  searchSelector,
  domainSelector,
  kioskSelector,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

/**
 * ----------------------------------------------------------------------------
 * Shop banner component
 * ----------------------------------------------------------------------------
 *
 * @param {{ title: String, renderAs: (String|{}|Function) }} props
 * @returns
 */
function ShopBanner ({ title, renderAs: RenderAs }) {
  return (
    <Wrapper className="shop-banner">
      <div className="shop-banner-name-container">
        <RenderAs style={{ width: '100%', height: '100%' }}>{title}</RenderAs>
      </div>
    </Wrapper>
  );
}

ShopBanner.propTypes = {
  title: PropTypes.string,
  renderAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
    PropTypes.func,
  ]),
};

ShopBanner.defaultProps = {
  title: '',
  renderAs: 'h1',
};

const Wrapper = styled.div``;

export default ShopBanner;

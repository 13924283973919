import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useRouter from 'use-react-router';

import { useSelfBBox } from '~/hooks';
import { getFile, getEllipseText } from '~/utils';

function PopupShopGetDirection ({
  x, y, logo, title, subTitle, text,
}) {
  const { match: { params: { projectId } } } = useRouter();
  const { selfRef, selfSize } = useSelfBBox();

  return (
    <Wrapper ref={selfRef} x={x - (selfSize.width / 2)} y={y - (selfSize.height)} onClick={() => handleClickPopUp()}>
      <g data-name="Layer 1">
        <g data-name="Group 7210">
          <g data-name="Group 7208">
            <path
              fill="#fff"
              stroke="#707070"
              strokeWidth="0.25"
              d="M99.89 95.79H4.58a4.46 4.46 0 01-4.46-4.45V4.58A4.46 4.46 0 014.58.12H208.5a4.46 4.46 0 014.5 4.46v86.76a4.45 4.45 0 01-4.45 4.45H114.2l-7.2 12.09z"
              data-name="Union 1-2"
            />
            <path
              fill="none"
              stroke="#707070"
              strokeWidth="0.25"
              d="M8.25 6.47H89.95V88.17H8.25z"
              data-name="709px-H M-Logo"
            />
            <g data-name="Group 7173">
              <g data-name="Group 387">
                <text
                  transform="translate(101.64 24.17)"
                  style={{ isolation: 'isolate' }}
                  data-name="H M"
                  fontFamily="DB Adman X"
                  fontSize="12"
                  letterSpacing=".1px"
                >
                  {getEllipseText(title, 12)}
                </text>
                <text
                  transform="translate(101.64 33.96)"
                  style={{ isolation: 'isolate' }}
                  fill="#8f8f8f"
                  data-name="FASHION ACCESSORIES"
                  fontFamily="DB Adman X"
                  fontSize="9"
                  fontWeight="300"
                  letterSpacing="-.06em"
                >
                  {getEllipseText(subTitle, 25)}
                </text>
              </g>
            </g>
            <g data-name="Group 7174">
              <text
                transform="translate(102.1 82.61)"
                style={{ isolation: 'isolate' }}
                fill="#6a6a6a"
                data-name="1ST FLOOR"
                fontFamily="DB Adman X"
                fontSize="14"
                letterSpacing=".075em"
              >
                {getEllipseText(text, 15)}
              </text>
            </g>
          </g>
          <rect
            id="get-direction-button"
            width="93.5"
            height="22.26"
            x="107.51"
            y="42.28"
            fill="#d91b5c"
            data-name="Rectangle 3052"
            rx="1.78"
          />
          <g data-name="Group 7209" id="get-direction-button">
            <g id="get-direction-button">
              <g id="get-direction-button">
                <path
                  id="get-direction-button"
                  fill="#fff"
                  d="M129.38 47.91a1.36 1.36 0 10-1.38-1.36 1.36 1.36 0 001.36 1.36zm.08 4.67h3.69v-1.36h-2.71l-1.51-2.48a1.39 1.39 0 00-1.13-.68.64.64 0 00-.38.08l-4.07 1.28v3.91h1.36v-2.78l1.58-.53-2.94 11.6h1.36l2.18-6.1 1.74 2.33v3.77H130V56.8l-1.88-3.39.53-2.19z"
                  data-name="Path 346"
                />
              </g>
            </g>
            <text
              id="get-direction-button"
              transform="translate(136.93 56.79)"
              style={{ isolation: 'isolate' }}
              fill="#fff"
              fontFamily="DB Adman X"
              fontSize="9"
              fontWeight="300"
              letterSpacing=".075em"
            >
              DIR
              <tspan id="get-direction-button" x="15.22" y="0" letterSpacing=".02em">
                E
              </tspan>
              <tspan id="get-direction-button" x="20.39" y="0" letterSpacing=".05em">
                C
              </tspan>
              <tspan id="get-direction-button" x="26.2" y="0" letterSpacing=".075em">
                TION
              </tspan>
            </text>
          </g>
        </g>
        <g>
          <image
            width="224"
            height="225"
            data-name="Caknjg"
            transform="matrix(.36 0 0 .36 8.81 7.13)"
            xlinkHref={logo || getFile(`/images/${projectId}/shop-no-logo.png`)}
          />
        </g>
      </g>
    </Wrapper>
  );
}

PopupShopGetDirection.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  logo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
};

PopupShopGetDirection.defaultProps = {
  x: 0,
  y: 0,
  logo: '',
  title: 'Title',
  subTitle: 'Subtitle',
  text: '',
};

const Wrapper = styled.g`
  transform: ${({ x, y }) => `translate(${x}px, ${y}px)`};
`;

export default PopupShopGetDirection;

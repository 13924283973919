import { fetcher } from '~/utils';

export default {
  getProject (projectId) {
    return fetcher({
      useMock: true,
      jsonMock: `/projects/${projectId}.json`,
    });
  },
};

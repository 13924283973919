import project from '~/store/modules/Project';
import { actionFetcher } from '~/utils';
import projectService from '~/apis/Project';

export default {
  getProjectByProjectId (projectId) {
    return actionFetcher({
      loadingMessage: 'Fetching project',
      successMessage: 'Success fetching project',
      async callAction (dispatch) {
        const fetchedProject = await projectService.getProject(projectId);
        dispatch(project.setProject({ payload: fetchedProject }));
        return fetchedProject;
      },
    });
  },
};

import { useState, useEffect } from 'react';
import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';

const localStorageKey = 'recent-search';
const RECENT_SEARCH_LIMIT = 5;

/**
 * ----------------------------------------------------------------------------
 * Recent search hook
 * ----------------------------------------------------------------------------
 *
 * @param {('origin'|'destination')} location
 */
const useRecentSearch = (location, recentSearchLimitLength = RECENT_SEARCH_LIMIT) => {
  const { match: { params: { projectId } } } = useRouter();
  const recentSearchFromLocalStorage = JSON.parse(localStorage.getItem(localStorageKey));
  const [ recentSearch, setRecentSearch ] = useState(recentSearchFromLocalStorage || {});

  /** to prevent select in the same location of origin and destination */
  const revertLocation = location => (location === 'origin' ? 'destination' : 'origin');
  const searchText = useSelector(state => state.search[`${revertLocation(location)}SearchText`]);

  /**
   * Push to recent search
   *
   * @description set an array of recent search to local storage item "recent-search"
   * @param {{}} newRecentSearch
   * @return void
   */
  const push = (newRecentSearchItem) => {
    /** remove duplicate item */
    const indexOfDuplicateItem = recentSearch[projectId].findIndex(recentSearch => recentSearch.id === newRecentSearchItem.id);
    if (indexOfDuplicateItem !== -1) recentSearch[projectId].splice(indexOfDuplicateItem, 1);

    const newState = [ newRecentSearchItem, ...recentSearch[projectId] ];
    const limitFive = newState.slice(0, recentSearchLimitLength);
    const newRecentSearch = {
      ...recentSearch,
      [projectId]: limitFive,
    };

    localStorage.setItem(localStorageKey, JSON.stringify(newRecentSearch));
    setRecentSearch(newRecentSearch);
  };

  /**
   * Clear recent search
   *
   * @description delete item "recent-search" on local storage
   */
  const clear = () => {
    setRecentSearch({});
    localStorage.removeItem(localStorageKey);
  };

  useEffect(() => {
    if (!recentSearchFromLocalStorage || !recentSearchFromLocalStorage[projectId]) {
      const defineRecentSearchWithEmptyArray = {
        ...recentSearchFromLocalStorage,
        [projectId]: [],
      };

      localStorage.setItem(localStorageKey, JSON.stringify(defineRecentSearchWithEmptyArray));
      setRecentSearch(defineRecentSearchWithEmptyArray);
    }
  }, []);

  /** to prevent select in the same location of origin and destination */
  const checkRecentSearchProperty = () => Object.getOwnPropertyNames(recentSearch).length === 0 || !recentSearchFromLocalStorage[projectId];
  const recentSearchItems = checkRecentSearchProperty() ? [] : recentSearch[projectId].filter(item => (
    !searchText || !Object.values(item.label).some(labelName => (
      labelName.toLowerCase().includes(searchText.toLowerCase())
    ))
  ));

  return {
    push,
    clear,
    recentSearchItems,
  };
};

export default useRecentSearch;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { usePalette } from 'react-palette';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Floor Item component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 * }} props
 * @returns
 */
function CategoryIcon ({ categoryKey, active, onClick }) {
  const { theme } = useTheme();
  const categoryIcons = theme.categoryIcons || {};
  const targetCategoryKey = Object.keys(categoryIcons).find((key) => {
    const regex = new RegExp(key, 'ig');
    return regex.test(categoryKey);
  });
  const targetCategorySrc = targetCategoryKey ? categoryIcons[targetCategoryKey] : '';
  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'category-icon',
        { active },
      )}
      onClick={onClick}
    >
      <img src={targetCategorySrc} alt="" />
    </Wrapper>
  );
}

CategoryIcon.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

CategoryIcon.defaultProps = {
  active: false,
  onClick () {},
};

const Wrapper = styled.div`
`;

export default CategoryIcon;

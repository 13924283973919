import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { getFile } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Get direction logo component
 * ----------------------------------------------------------------------------
 *
 * @param {{ className: String, height: Number, width: Number }} props
 * @param {String} props.className
 * @param {Number} props.height
 * @param {Width} props.width
 * @returns
 */
function GetDirectionLogo ({ className }) {
  return (
    <Wrapper className={`get-direction-logo ${className}`}>
      <img src={getFile('/images/get-direction-logo.svg')} alt="Get Direction Logo" />
    </Wrapper>
  );
}

GetDirectionLogo.propTypes = {
  className: PropTypes.string,
};

GetDirectionLogo.defaultProps = {
  className: '',
};

const Wrapper = styled.div``;

export default GetDirectionLogo;

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Content component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @param {JSX.Element} props.children
 * @return
 */
function Content ({ children }) {
  return (
    <div className="bottom-drawer-content">
      {children}
    </div>
  );
}

Content.displayName = 'Content';
Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Content;

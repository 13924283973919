import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { bindingClassName } from '~/utils';

import { Button } from '~/components';

import { useTheme, useTranslation } from '~/hooks';

import {
  projectSelector,
  domainSelector,
} from '~/store/selector';

/**
 * ----------------------------------------------------------------------------
 * PrivacyPolicy component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  texts: Array<String>,
 *  disabled: Boolean
 *  icon: String
 * }} props
 * @return {JSX.Element}
 */
function PrivacyPolicy ({
  className,
  ...rest
}) {
  const { project, projectId } = useSelector(
    createStructuredSelector({
      project: projectSelector.entire,
      projectId: domainSelector.projectId,
    }),
  );

  const { privacyCookiePolicy = {} } = project;
  const { message = {}, cookie = {}, privacy = {} } = privacyCookiePolicy;
  const { link: cookieLink, text: cookieText } = cookie;
  const { link: privacyLink, text: privacyText } = privacy;

  const hasPrivacyPolicyLinkConfig = Object.keys(privacyCookiePolicy) > 0;
  const showPrivacyPolicyLink = hasPrivacyPolicyLinkConfig && (localStorage.getItem(`web-directory-${projectId}`) !== 'accepted');

  const elRef = useRef(null);
  const [ show, setShow ] = useState(showPrivacyPolicyLink);

  const { theme } = useTheme();
  const { translate } = useTranslation();

  const handleAccept = () => {
    setShow(false);
    // local storage
    localStorage.setItem(`web-directory-${projectId}`, 'accepted');
  };

  if (!hasPrivacyPolicyLinkConfig) return <div />;

  return (
    <Wrapper
      {...rest}
      ref={elRef}
      theme={theme}
      className={bindingClassName(`privacy-policy ${className}`, {
        show,
      })}
    >
      <div>
        <p className="privacy-policy-text is-size-6">
          {translate(message)}
          {' '}
          <a href={cookieLink} target="_blank" without rel="noopener noreferrer"><span className="privacy-policy-text is-size-6">{translate(cookieText)}</span></a>
          {' และ '}
          <a href={privacyLink} target="_blank" without rel="noopener noreferrer"><span className="privacy-policy-text is-size-6">{translate(privacyText)}</span></a>
        </p>
      </div>
      <Button
        title={translate('privacy-policy-accept')}
        variant="secondary"
        onClick={handleAccept}
        fullWidth={false}
        rounded
      />
    </Wrapper>
  );
}

PrivacyPolicy.propTypes = {
  className: PropTypes.string,
};

PrivacyPolicy.defaultProps = {
  className: '',
};

const Wrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colorHelper.black}`};
  background-color: ${({ theme }) => theme.colorHelper.black};
  .privacy-policy-text {
    color: ${({ theme }) => theme.colorHelper.white};
    > a > span {
      color: ${({ theme }) => theme.colorHelper.secondary};
    }
  }
  
`;

export default PrivacyPolicy;

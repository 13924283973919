import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring';

import { useTheme } from '~/hooks';

/**
 * ----------------------------------------------------------------------------
 * Circular progress bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{ size: number, strokeWidth: number, progress: number, onAnimationEnd: function }} props
 * @param {Number} props.size
 * @param {Number} props.strokeWidth
 * @param {Number} props.progress
 * @param {String} props.fontSize
 * @param {Function} props.onAnimationEnd
 */
const Circular = ({
  size,
  strokeWidth,
  progress,
  onAnimationEnd,
}) => {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - dashArray * progress / 100;
  const { theme } = useTheme();

  const animationProps = useSpring({
    from: { strokeDashoffset: dashArray },
    to: { strokeDashoffset: dashOffset },
    onFrame ({ strokeDashoffset }) {
      if (strokeDashoffset === 0) onAnimationEnd();
    },
  });

  return (
    <Svg
      className="circular-progress"
      width={size}
      height={size}
      viewBox={viewBox}
      theme={theme}
    >
      {
        theme.progressBar.mode === 'gradient' && (
          <defs>
            <linearGradient id="gradient-fill" gradientTransform="rotate(45)">
              {
                theme.progressBar.indicator.stops.map((stop, index) => (
                  <stop
                    key={index.toString()}
                    stopColor={stop}
                    offset={`${(index * 100) / theme.progressBar.indicator.stops.length - 1}%`}
                  />
                ))
              }
            </linearGradient>
          </defs>
        )
      }
      <circle
        className="circular-progress-background"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
      />
      <animated.circle
        className="circular-progress-indicator"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeLinecap="round"
        style={{
          ...animationProps,
          strokeDasharray: dashArray,
          display: 'inline',
        }}
      />
      <text
        className="circular-progress-text is-size-2"
        x="50%"
        y="50%"
        textAnchor="middle"
        alignmentBaseline="central"
      >
        {`${progress}%`}
      </text>
    </Svg>
  );
};

Circular.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  progress: PropTypes.number,
  onAnimationEnd: PropTypes.func,
};

Circular.defaultProps = {
  size: 200,
  strokeWidth: 8,
  progress: 30,
  onAnimationEnd () {},
};

const Svg = styled.svg`
  &.circular-progress {
    .circular-progress-background {
      fill: none;
      stroke: ${({ theme }) => theme.progressBar.track};
    }
    .circular-progress-indicator {
      fill: none;
      stroke: ${({ theme }) => (theme.progressBar.mode === 'color' ? theme.progressBar.indicator : theme.progressBar.indicator.url)};
    }
    .circular-progress-text {
    }
  }
`;

export default Circular;

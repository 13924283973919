import { createSelector } from 'reselect';

const getShops = ({ entity }) => {
  const shops = Object.entries(entity.shops).reduce((result, shop) => (
    [ ...result, { id: shop[0], ...shop[1] }]
  ), []);

  return shops;
};

const getMedias = state => state.entity.medias;

export default {
  getShops,
  getShopByName: createSelector(
    getShops,
    shops => name => shops.find((shop) => {
      const shopsName = Object.values(shop.name).map(name => name.trim().toLowerCase());
      return shopsName.includes(name.trim().toLowerCase());
    }),
  ),
  /**
   * Get shops name for use with auto-complete function
   *
   * @description get all shop name
   * @return {Array}
   */
  shopsForAutoCompleteSearch: createSelector(
    [ getShops, getMedias ],
    (shops, medias) => shops.reduce((result, shop) => {
      const { name, logo, floor, id } = shop;
      const isDuplicate = result.find(item => item.id === id);

      if (!isDuplicate) {
        return [ ...result,
          {
            id,
            label: name,
            logo: medias[logo] ? medias[logo].url : undefined,
            floor,
          },
        ];
      }

      return result;
    }, []),
  ),
};

import React from 'react';
import PropTypes from 'prop-types';

import { AnimatedSwitch, spring } from 'react-router-transition';

import { ScrollToTop } from '~/components';


/**
 * Transition switch
 *
 * @return {JSX.Element}
 */
function Switch ({ children }) {
  const slide = val => spring(val, { stiffness: 80, damping: 16 });

  return (
    <ScrollToTop>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: slide(0) }}
        atActive={{ opacity: slide(1) }}
        mapStylesPage={styles => ({ opacity: styles.opacity })}
        className="switch-wrapper"
      >
        {children}
      </AnimatedSwitch>
    </ScrollToTop>
  );
}

Switch.propTypes = {
  children: PropTypes.node.isRequired,
};

Switch.defaultProps = {};

export default Switch;

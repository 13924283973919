import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import CategoryIcon from '../CategoryIcon';

/**
 * ----------------------------------------------------------------------------
 * Category bar component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function CategoryBar ({ categoryKey, title, subtitle }) {
  const { theme } = useTheme();

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName(
        'category-bar',
      )}
    >
      <div className="container">
        <div className="category-bar-body">
          <div className="category-bar-icon">
            <CategoryIcon categoryKey={categoryKey} />
          </div>
          <p className="category-bar-title is-size-3">{title}</p>
        </div>
        <div className="category-bar-body with-subtitle">
          <p className="category-bar-subtitle">{subtitle}</p>
        </div>
      </div>
    </Wrapper>
  );
}

CategoryBar.propTypes = {
  categoryKey: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CategoryBar.defaultProps = {
  categoryKey: '',
  title: '',
  subtitle: '',
};

const Wrapper = styled.div`
`;

export default CategoryBar;

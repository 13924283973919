import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

/** react context */
export const ModalContext = createContext();

/**
 * ----------------------------------------------------------------------------
 * Modal provider
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element }} props
 * @param {JSX.Element} props.children
 */
function ModalProvider ({ children }) {
  const [ modals, setModals ] = useState({});

  return (
    <ModalContext.Provider value={{ modals, setModals }}>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Tiny bar component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, fluid: Boolean, variant: String }} props
 * @returns
 */
function TinyBar ({ children, fluid, variant }) {
  const { theme } = useTheme();

  return (
    <Wrapper
      theme={theme}
      className={bindingClassName('tiny-bar', `is-${variant}`)}
    >
      <div className={bindingClassName('tiny-bar-content', { container: !fluid, 'container-fluid': fluid })}>
        {children}
      </div>
    </Wrapper>
  );
}

TinyBar.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
  variant: PropTypes.string,
};

TinyBar.defaultProps = {
  fluid: false,
  variant: 'primary',
};

const Wrapper = styled.div`
  &.is-primary {
    background: ${({ theme }) => theme.colorHelper.primary};
    color: ${({ theme }) => theme.colorHelper.white};
  }
`;

export default TinyBar;

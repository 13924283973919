import React from 'react';
import PropTypes from 'prop-types';

/**
 * ----------------------------------------------------------------------------
 * Modal title component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children }} props
 * @param {JSX.Element} props.children
 */
function Title ({ children }) {
  return (
    <h5 className="app-modal-title">{children}</h5>
  );
}

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Title;

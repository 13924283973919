import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';


/**
 * ----------------------------------------------------------------------------
 * Filter category component
 * ----------------------------------------------------------------------------
 *
 * @returns
 */
function SectionTitle ({ children }) {
  const { theme } = useTheme();
  const { line } = theme.heading;
  return (
    <Wrapper className="hero-text">
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
      {children}
      <div className="hero-text-line-container">
        <Line className="line" color={line} />
      </div>
    </Wrapper>
  );
}

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

SectionTitle.defaultProps = {
};

const Wrapper = styled.div`
  margin: 20px 0;
`;

const Line = styled.div`
  border: ${({ color }) => `1px solid ${color}`};
`;

export default SectionTitle;

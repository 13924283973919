// import AlertMessage from 'glud-component/lib/AlertMessage';
// import Toastify from 'glud-component/lib/Toastify';

export default ({ loadingMessage = 'Loading', successMessage = 'Success', callAction }) => async (
  dispatch,
  getState,
) => {
  // const loading = Toastify({
  //   type: 'LOADING',
  //   message: loadingMessage,
  // });
  console.debug(loadingMessage);

  try {
    const result = await callAction(dispatch, getState);
    // loading.close();
    console.debug(successMessage);
    // Toastify({
    //   type: 'SUCCESS',
    //   closeTime: 2,
    //   message: successMessage,
    // });
    return result;
  } catch ({ message }) {
    console.error(`Error ${message}`);
    // loading.close();
    // const errorMessage = `Error ${message}`;
    // AlertMessage.open({
    //   type: 'ERROR',
    //   message: errorMessage,
    // });
    return undefined;
  }
};

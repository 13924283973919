import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button } from '~/components';

import { useTheme } from '~/hooks';

const GetDirectionButton = ({ onClick, title }) => {
  const { theme } = useTheme();
  return (
    <Wrapper theme={theme} className="get-direction-button">
      <Button
        variant="secondary"
        leftIcon="Walking"
        fullWidth={false}
        rounded={false}
        onClick={onClick}
      />
      <p className="title">{title}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  > p.title {
    color: ${({ theme }) => theme.colorHelper.secondary};
  }
`;

GetDirectionButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

GetDirectionButton.defaultProps = {
  onClick: () => {},
  title: '',
};

export default GetDirectionButton;

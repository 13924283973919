export default {
  /**
   * Encode string with slugify
   *
   * @param {String} str
   * @param {String} replacement
   * @param {Boolean} lower
   * @param {String} remove
   * @return {String} "the dog is sleeping" => "the-dog-is-sleeping"
   */
  encode (str) {
    /* eslint-disable */
    const replacedStr = str.replace(/\s+/g, '-') /** replace spaces with - */
      .replace(/\-\-+/g, '-') /** replace multiple - with single - */
      .replace(/^-+/, '') /** Trim - from start of text */
      .replace(/-+$/, '');
    /* eslint-enable */

    return replacedStr.toLowerCase();
  },
  /**
   * Decode slug string
   *
   * @param {String} str
   * @return {String} "the-dog-is-sleeping" => "the dog is sleeping"
   */
  decode (str) {
    const decodeStr = decodeURI(str);
    return decodeStr.replace(/-/g, ' ');
  },
};

import Entity from '~/store/modules/Entity';


/**
 * Content action
 *
 * @function
 */
function action () {
  return {
    /**
     * Set content to entity store
     *
     * @param {any} payload - content data
     */
    set (payload) {
      return async (dispatch) => {
        dispatch(Entity.setEntity({ payload }));
      };
    },
  };
}

export default {};
export const ContentAction = action();

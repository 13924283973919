import config from './config';


export default {
  /**
   * Public join
   *
   * @param {string} path
   * @return {string}
   */
  public (path) {
    return [ config.PUBLIC_URL, path ].join('');
  },
};

import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire
 * @description visibility filter from redux store
 */
const entire = helper.getEntireVisibilityFilter;

/**
 * Get text property
 * @description get text property from redux store
 */
const text = createSelector(
  [entire],
  filter => filter.text,
);

/**
 * Get category property
 * @description get category property from redux store
 */
const category = createSelector(
  [entire],
  filter => filter.category,
);

export default {
  entire,
  text,
  category,
};

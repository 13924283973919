import React from 'react';

export default () => (
  <>
    {/* Footer */}
    <footer>
      <div className="container">
        <div className="wrp-logo">
          <a href="https://www.fashionisland.co.th">
            <img
              src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/logo.png"
              className="img-fluid"
            />
          </a>
        </div>
        <div className="fs-row">
          <div className="col-30">
            <ul
              id="menu-footer-menu"
              className="footer-menu d-flex justify-content-center justify-content-lg-end"
            >
              <li
                id="menu-item-517"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-517"
              >
                <a
                  href="https://www.fashionisland.co.th/about-us/"
                  className="menu-image-title-after"
                >
                  ABOUT US
                </a>
              </li>
              <li
                id="menu-item-515"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-515"
              >
                <a
                  href="https://www.fashionisland.co.th/contact/"
                  className="menu-image-title-after"
                >
                  CONTACT
                </a>
              </li>
              <li
                id="menu-item-516"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-516"
              >
                <a
                  href="https://www.fashionisland.co.th/career/"
                  className="menu-image-title-after"
                >
                  CAREER
                </a>
              </li>
            </ul>
          </div>
          <div className="col-40">
            <div className="wrp-socials">
              <a
                href="https://www.facebook.com/fashionislandmall/"
                target="_blank"
              >
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-facebook.png" />
              </a>
              <a
                href="https://www.instagram.com/fashionislandth/"
                target="_blank"
              >
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-instagram.png" />
              </a>
              <a
                href="https://www.youtube.com/user/FashionIslandBKK"
                target="_blank"
              >
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-youtube.png" />
              </a>
              <a href="https://twitter.com/fashionislandTH" target="_blank">
                <img src="https://www.fashionisland.co.th/wp-content/themes/fashion-island/img/icon-twitter.png" />
              </a>
            </div>
          </div>
          <div className="col-30 ml-auto">
            <div className="text-contact text-left">
              <p>
                <a href="tel:+6629475000">CALL US : +662-947-5000</a>
                <br />
                <strong>
                  <span style={{ display: "block", padding: 10 }}>
                    OPEN DAILY
                    <br />
                    1 FL - 3 FL : 10.00 - 21.00 hrs
                    <br />B FL : 10.00 - 22.00 hrs
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="copy-rights mv-35">
          <p>
            © 2020 FASHIONISLAND SHOPPING MALL.
            <br />
            ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </footer>
    {/* //Footer */}
    {/* Mobile Bar */}
    <div className="mobile-bar d-flex justify-content-around">
      <a
        href="https://www.google.com/maps?ll=13.825588,100.678795&z=16&t=m&hl=en&gl=TH&mapclient=embed&daddr=Fashion+island+shopping+mall+587,589,+589/7+9+Thanon+Ram+Intra+Khan+Na+Yao,+Bangkok+10230@13.8255881,100.6787954"
        className="menu-image-title-after menu-image-not-hovered"
      >
        <img
          src="https://www.fashionisland.co.th/wp-content/uploads/2020/01/icon-location-36x32.png"
          className="menu-image menu-image-title-after"
          alt
        />
        <span className="menu-image-title">LOCATION</span>
      </a>
      <a
        href="tel:029475000"
        className="menu-image-title-after menu-image-not-hovered"
      >
        <img
          src="https://www.fashionisland.co.th/wp-content/uploads/2020/01/icon-contact-36x32_footer-29x36.png"
          className="menu-image menu-image-title-after"
          alt
        />
        <span className="menu-image-title">CONTACT</span>
      </a>
    </div>
    ;{/* //Mobile Bar */}
  </>
);

import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Card footer component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, borderless: Boolean }} props
 * @returns
 */
function Footer ({ children, borderless }) {
  return (
    <div
      className={bindingClassName(
        'app-card-footer',
        { 'is-borderless': borderless },
      )}
    >
      {children}
    </div>
  );
}

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  borderless: PropTypes.bool,
};

Footer.defaultProps = {
  borderless: false,
};

export default Footer;

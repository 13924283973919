import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '~/hooks';

function Tag ({ text, position, className }) {
  const { theme } = useTheme();
  return (
    <Wrapper theme={theme} className={className}>
      <div className="tag">
        { position === 'right' && <div className="triangle-right" /> }
        { position === 'right' && <div className="rectangle-right">{text}</div>}
        { position === 'left' && <div className="triangle-left" /> }
        { position === 'left' && <div className="rectangle-left">{text}</div>}
      </div>
    </Wrapper>
  );
}

Tag.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['right, left']),
};

Tag.defaultProps = {
  className: '',
  position: 'right',
};

const Wrapper = styled.div`
.rectangle-right {
  color: ${({ theme }) => theme.colorHelper.white}
}

.rectangle-left {
  color: ${({ theme }) => theme.colorHelper.white}
}
`;

export default Tag;

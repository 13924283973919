import ProjectModule from '~/store/modules/Project';

import { ProjectService } from '~/local-services/project';
import logger from '~/lib/logger';


/**
 * Project action
 *
 * @function
 */
function action () {
  return {
    /**
     * Set project
     *
     * @param {any} payload - project data
     */
    set (payload) {
      return (dispatch) => {
        dispatch(ProjectModule.setProject({ payload }));
      };
    },

    /**
     * Set project meta to store and return the data by project slug and dispatch to store
     *
     * @deprecated Marked as deprecated since 0.1.5 version. Will remove in 0.2.0 version
     * @param {string} slug - project slug
     */
    setBySlug (slug) {
      return async (dispatch) => {
        const project = await ProjectService.getBySlug(slug);

        logger.debug(project);
        logger.warn('ProjectAction.setBySlug is marked as deprecated since version 0.1.5. Will remove in 0.2.0 version');

        dispatch(ProjectModule.setProject({ payload: project }));
        return project;
      };
    },
  };
}

export default {};
export const ProjectAction = action();

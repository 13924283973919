import { useContext } from 'react';
import { ThemeContext } from '~/context/Theme';

/**
 * ----------------------------------------------------------------------------
 * Theme hooks
 * ----------------------------------------------------------------------------
 */
const useTheme = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  /**
   * Change current theme
   *
   * @param {{}} targetTheme
   * @return void
   */
  const changeTheme = (targetTheme) => {
    if (Object.getOwnPropertyNames(targetTheme).length > 0) {
      setTheme(targetTheme);
      return;
    }
    console.error('error occur at "changeTheme()" did you assign the correct theming object');
  };

  return {
    theme,
    changeTheme,
  };
};

export default useTheme;

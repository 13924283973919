import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Level item component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  children: JSX.Element,
 *  align: ('left', 'right'),
 * }} props
 * @returns
 */
function Item ({ children, align, className }) {
  return (
    <div
      className={bindingClassName(
        'level-item',
        { [`has-align-${align}`]: align },
        className,
      )}
    >
      {children}
    </div>
  );
}

Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  align: PropTypes.oneOf([ '', 'left', 'right' ]),
  className: PropTypes.string,
};

Item.defaultProps = {
  align: '',
  className: '',
};

export default Item;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useTheme } from '~/hooks';
import { bindingClassName } from '~/utils';

import { WalkingSVG, LocationSVG } from './components';

/**
 * Avatar card component
 *
 * @param {{
 *  src: String,
 *  alt: String,
 *  title: String,
 *  titleClassName: String,
 *  description: String,
 *  descriptionClassName: String,
 *  className: String,
 *  onClick: Function,
 * }} props
 * @return {JSX.Element}
 */
const AvatarCard = ({
  src,
  alt,
  title,
  titleClassName,
  description,
  descriptionClassName,
  shopCategory,
  shopCategoryClassName,
  className,
  onClick,
  showNavigateButton,
}) => {
  const { theme } = useTheme();

  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick();
  };

  return (
    <Wrapper
      theme={theme}
      onClick={handleOnClick}
      className={bindingClassName('avatar-card', className, {
        'is-clickable': onClick,
      })}
    >
      <div className="avatar-card-image-container">
        <img src={src} alt={alt} />
      </div>
      <div className="avatar-card-content">
        <div className="title-and-category">
          <h6 className={titleClassName}>{title}</h6>
          <p className={shopCategoryClassName}>{shopCategory}</p>
        </div>
        <div className="location">
          <LocationSVG />
          <p className={descriptionClassName}>{description}</p>
        </div>
      </div>
      { showNavigateButton
        ? (
          <div className="avatar-card-navigate-button">
            <WalkingSVG />
          </div>
        ) : (
          <></>
        )}
    </Wrapper>
  );
};

AvatarCard.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  description: PropTypes.string,
  descriptionClassName: PropTypes.string,
  shopCategory: PropTypes.string,
  shopCategoryClassName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  showNavigateButton: PropTypes.bool,
};

AvatarCard.defaultProps = {
  src: '',
  alt: '',
  title: '',
  titleClassName: '',
  description: '',
  descriptionClassName: '',
  shopCategory: '',
  shopCategoryClassName: '',
  className: '',
  onClick: undefined,
  showNavigateButton: false,
};

const Wrapper = styled.div`
  .avatar-card {
    &-image-container {
      background-color: ${({ theme }) => theme.colorHelper.white};
    }
    &-navigate-button {
      border: 1px solid lightgrey;
    }
  }
`;

export default AvatarCard;

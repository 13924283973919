import { createModule } from 'redux-modux';

const initialState = {};

/**
 * handler action
 */
const handlers = {
  setEntity (state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  setFloorIcon (state, action) {
    return {
      ...state,
      floorIcon: action.payload,
    };
  },
  addMedia (state, action) {
    return {
      ...state,
      medias: {
        ...state.medias,
        ...action.payload,
      },
    };
  },
};

export default createModule({
  moduleName: 'entity', /** Optional (Prefix when console.log action's type) */
  initialState,
  handlers,
});

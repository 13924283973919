import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Card footer item component
 * ----------------------------------------------------------------------------
 *
 * @param {{ children: JSX.Element, renderAs: (String|JSX.Element) }} props
 * @returns
 */
function FooterItem ({ children, renderAs: RenderAs, variant, ...rest }) {
  return (
    <RenderAs
      className={bindingClassName(
        'app-card-footer-item',
        { [`is-${variant}`]: variant },
      )}
      {...rest}
    >
      {children}
    </RenderAs>
  );
}

FooterItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  renderAs: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
    PropTypes.string,
  ]),
  variant: PropTypes.oneOf([
    '', 'primary',
  ]),
};

FooterItem.defaultProps = {
  variant: '',
};

FooterItem.defaultProps = {
  renderAs: 'button',
};

export default FooterItem;

import React from 'react';
// import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Header } from '~/components';

import MobileNavigationButton from '../MobileNavigationButton';
import BuildingSelector from '../BuildingSelector';

import { useTheme } from '~/hooks';

function GetDirectionHeader () {
  const { theme } = useTheme();
  return (
    <Wrapper theme={theme} className="get-direction-header">
      <Header
        renderLeft={() => <MobileNavigationButton openAsDefault={false} />}
        title={() => <BuildingSelector />}
      />
    </Wrapper>
  );
}

GetDirectionHeader.propTypes = {
};

GetDirectionHeader.defaultProps = {
};

const Wrapper = styled.div`
  label: GetDirectionHeader;
  background: ${({ theme }) => theme.header.large};
`;

export default GetDirectionHeader;

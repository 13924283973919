import React from 'react';
import { isMobile } from 'react-device-detect';

export default () => (
  <>
    {!isMobile && (
      <header id="customise">
        <div className="boxed">
          <div className="st-container">
            <div className="header-wrapper header-type-8">
              <header className="header main-header">
                <div className="container">
                  <div className="navbar" role="navigation">
                    <div className="container-fluid">
                      <div id="st-trigger-effects" className="column">
                        <button
                          type="button"
                          data-effect="mobile-menu-block"
                          className="menu-icon"
                        />
                      </div>

                      <div className="header-logo">
                        <a href="https://www.terminal21.co.th/korat">
                          <img
                            src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/09/Terminal_korat_logo.png"
                            alt="พบประสบการณ์ ศูนย์การค้า Shopping Mall เทรนด์ใหม่ กลางสุขุมวิท อโศก แบบ Market Street ที่รวบรวมบรรยากาศทั่วทุกมุมโลกมาไว้ในเทอร์มินอล"
                          />
                        </a>
                      </div>

                      <div className="clearfix visible-md visible-sm visible-xs" />

                      <div className="navbar-header navbar-right">
                        <div className="navbar-right" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-wrapper">
                  <div className="container">
                    <div className="collapse navbar-collapse">
                      <div className="menu-main-container">
                        <ul id="menu-main_menu-1" className="menu">
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-12 current_page_item menu-item-30 item-level-0 menu-dropdown firstItem">
                            <a
                              href="https://www.terminal21.co.th/korat/"
                              className="item-link"
                            >
                              Home
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-29 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/promotion/"
                              className="item-link"
                            >
                              Promotion
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3526 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/lg-floor/"
                              className="item-link"
                            >
                              Shop
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7952 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/event-activity/"
                              className="item-link"
                            >
                              Event &amp; Activity
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/films/"
                              className="item-link"
                            >
                              Films
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/contact-us/"
                              className="item-link"
                            >
                              Contact Us
                            </a>
                          </li>
                          <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2416 item-level-0 menu-dropdown">
                            <a
                              href="https://www.terminal21.co.th/korat/sky-deck/"
                              className="item-link"
                            >
                              Sky Deck
                            </a>
                          </li>
                          <li className="hide menu-item menu-item-type-custom menu-item-object-custom menu-item-5543 item-level-0 menu-dropdown lastItem">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.sfcinemacity.com/index.php/en/timetable/lookup/cinema/Terminal-21-Korat"
                              className="item-link"
                            >
                              Films
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="languages-area">
                      <div id="text-3" className="topbar-widget widget_text">
                        <div className="textwidget">
                          <a
                            className="link_top enlang"
                            href="https://www.terminal21.co.th/korat/reward/"
                          >
                            TERMINAL 21 REWARD
                            <img
                              src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/11/icon-link.png"
                              alt="top_icon"
                              width="19"
                              height="19"
                            />
                          </a>
                        </div>
                      </div>

                      <div id="text-4" className="topbar-widget widget_text">
                        <div className="textwidget">
                          <div className="subscribe">
                            <div
                              role="form"
                              className="wpcf7"
                              id="wpcf7-f3882-o1"
                              lang="en-US"
                              dir="ltr"
                            >
                              <div className="screen-reader-response" />
                              <form
                                action="/korat/#wpcf7-f3882-o1"
                                method="post"
                                className="wpcf7-form"
                                noValidate="novalidate"
                              >
                                <div style={{ display: "none" }}>
                                  <input
                                    type="hidden"
                                    name="_wpcf7"
                                    value="3882"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_version"
                                    value="4.5.1"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_locale"
                                    value="en_US"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpcf7_unit_tag"
                                    value="wpcf7-f3882-o1"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wpnonce"
                                    value="54ca0c78fc"
                                  />
                                </div>

                                <p>
                                  <label htmlFor="email">
                                    Email
                                    <strong style={{ fontSize: 12 }}>
                                      Newsletters
                                    </strong>
                                  </label>
                                  <span className="wpcf7-form-control-wrap your-email">
                                    <input
                                      type="email"
                                      id="email"
                                      name="your-email"
                                      value=""
                                      size="40"
                                      className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                      aria-required="true"
                                      aria-invalid="false"
                                    />
                                  </span>
                                  <input
                                    type="submit"
                                    value="Subscribe"
                                    className="wpcf7-form-control wpcf7-submit"
                                  />
                                  <img
                                    className="ajax-loader"
                                    src="https://www.terminal21.co.th/korat/wp-content/plugins/contact-form-7/images/ajax-loader.gif"
                                    alt="กำลังส่ง ..."
                                    style={{ visibility: "hidden" }}
                                  />
                                </p>

                                <input
                                  type="hidden"
                                  id="ct_checkjs_cf7_7ce3284b743aefde80ffd9aec500e085"
                                  name="ct_checkjs_cf7"
                                  value="1782719180"
                                />

                                <div className="wpcf7-response-output wpcf7-display-none" />
                              </form>
                            </div>
                          </div>

                          <div className="social-icon">
                            <a
                              href="https://www.instagram.com/terminal21korat/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/Instagram_App_Large_May2016_200.png"
                                alt="ig_icon"
                                width="33"
                                height="33"
                              />
                            </a>

                            <a
                              href="https://www.facebook.com/Terminal21Korat/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="https://www.terminal21.co.th/korat/wp-content/uploads/2016/10/Facebook-Logo.png"
                                alt="facebook"
                                width="33"
                                height="33"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>
      </header>
    )}
  </>
);

import React, { useState } from "react";
import { isMobile } from "react-device-detect";

export default () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  return (
    <>
      <header id="customise">
        <nav id="nav" className="navbar navbar-default" role="navigato">
          <div>
            <div className="navbar-header hidden-md hidden-lg">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#nav_menu"
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <div className="languages">
                <select className="language">
                  <option value="/index.php?&amp;lan=th" selected="">
                    TH
                  </option>
                  <option value="/index.php?&amp;lan=en">EN</option>
                </select>
              </div>

              <div style={{ flex: 1 }} />
              <a className="brand" href="https://www.thepromenade.co.th/home">
                <img
                  className="img-responsive logoNav img-center"
                  src="https://www.thepromenade.co.th//images/logo-mobie.png"
                  alt="Small Nav Logo"
                  style={{ height: 30, padding: 0, marginTop: -5 }}
                />
              </a>
            </div>
            <div className="display-mobie" style={{ padding: "5px 10px" }}>
              <form
                action="https://www.thepromenade.co.th/directory.php"
                method="POST"
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control input-sm"
                    name="text"
                    placeholder="Search directory..."
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-default"
                      name="submit_search"
                      type="submit"
                    >
                      Search!
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div
            className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
            id="nav_menu"
          >
            <div className="col-md-5 col-sm-6 col-xs-12">
              <ul id="nav_list" style={{ float: "right" }}>
                <li>
                  <a href="https://www.thepromenade.co.th/home">Home</a>
                </li>
                <li>
                  <a href="https://www.thepromenade.co.th/event">Event</a>
                </li>
                <li>
                  <a href="https://www.thepromenade.co.th/about">about us</a>
                </li>
                <li>
                  <a href="https://www.thepromenade.co.th/directory/13">
                    DIRECTORY
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2 hidden-xs hidden-sm visible-md visible-lg">
              <a href="https://www.thepromenade.co.th/home">
                <img
                  className="img-responsive logoNav img-center"
                  src="https://www.thepromenade.co.th//images/logo.png"
                  alt="Small Nav Logo"
                  style={{ left: 0, position: "absolute", top: -15 }}
                />
              </a>
            </div>

            <div className="col-md-5 col-sm-6 col-xs-12">
              <ul id="nav_list2" style={{ float: "left" }}>
                <li>
                  <a href="https://www.thepromenade.co.th/promotion">
                    PROMOTION
                  </a>
                </li>
                <li>
                  <a href="https://www.thepromenade.co.th/gallery">GALLERY</a>
                </li>
                <li>
                  <a href="https://www.thepromenade.co.th/contact">
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="languages">
                <select className="language2 hidden-sm hidden-xs">
                  <option value="/index.php?&amp;lan=th" selected="">
                    TH
                  </option>
                  <option value="/index.php?&amp;lan=en">EN</option>
                </select>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

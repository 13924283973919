import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '~/hooks';

function LocationSVG ({ IconColor }) {
  const { theme } = useTheme();
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="15.904"
      height="20.733"
      viewBox="0 0 15.904 20.733"
    >
      <path
        style={{ fill: `${IconColor || theme.colorHelper.secondary}` }}
        d="M105.009 101.152a3.856 3.856 0 103.856 3.856 3.861 3.861 0 00-3.856-3.856zm0 6.513a2.657 2.657 0 112.657-2.657 2.66 2.66 0 01-2.657 2.658zm0 0"
        data-name="Path 4"
        transform="translate(-97.056 -97.056)"
      />
      <path
        style={{ fill: `${IconColor || theme.colorHelper.secondary}` }}
        d="M7.952 0a7.952 7.952 0 00-6.1 13.055l6.1 7.678 6.1-7.678A7.952 7.952 0 007.952 0zm5.175 12.291l-.005.006-5.17 6.509L2.787 12.3l-.01-.012a6.753 6.753 0 1110.35 0zm0 0"
        data-name="Path 5"
      />
    </svg>
  );
}

LocationSVG.propTypes = {
  IconColor: PropTypes.string,
};

LocationSVG.defaultProps = {
  IconColor: undefined,
};


export default LocationSVG;

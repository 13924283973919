import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Layout component
 * ----------------------------------------------------------------------------
 *
 * @param  {{}} props
 * @param  {JSX.Element} props.children
 * @param  {String} props.className
 * @param  {Boolean} props.paddingless
 * @return  {JSX.Element}
 */
const Layout = ({ children, className, paddingless }) => (
  <Wrapper
    className={`${className} ${bindingClassName({
      layout: true,
      'is-paddingless': paddingless,
    })}`}
  >
    {children}
  </Wrapper>
);

const Wrapper = styled.div``;

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  paddingless: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
  paddingless: false,
};

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '~/hooks';

import Slider from '../Slider';

function ShopCover ({
  src,
  alt,
  title,
  subTitle,
  gallery,
}) {
  const { theme } = useTheme();

  return (
    <Wrapper
      theme={theme}
    >
      <div className="shop-cover">
        <div className="shop-cover-header">
          <div className="shop-logo">
            <img src={src} alt={alt} />
          </div>
          <div className="shop-name">{title}</div>
          <div className="shop-category">{subTitle}</div>
        </div>
        <div className="shop-img">
          <Slider
            settings={{
              slidesToShow: 1,
            }}
          >
            {
              gallery.map(src => (
                <div>
                  <img src={src} alt="" />
                </div>
              ))
            }
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
}

ShopCover.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  gallery: PropTypes.arrayOf(PropTypes.string),
};

ShopCover.defaultProps = {
  title: '',
  subTitle: '',
  src: '',
  alt: '',
  gallery: [],
  // gallery: [ 'https://via.placeholder.com/200x150', 'https://via.placeholder.com/200x150' ],
};

const Wrapper = styled.div``;

export default ShopCover;

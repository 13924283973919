import { useRef, useState, useEffect } from 'react';

/**
 * ----------------------------------------------------------------------------
 * Selector hooks
 * ----------------------------------------------------------------------------
 *
 * @param {{}} selectors
 * @returns {{}}
 */
function useSelfBBox () {
  const selfRef = useRef(null);
  const [ selfSize, setSize ] = useState({ width: 0, height: 0 });

  const getSelfBBox = () => {
    if (selfRef === null) return {};
    return selfRef.current.getBBox();
  };
  useEffect(() => {
    const selfBBox = getSelfBBox();
    setSize(selfBBox);
  }, [selfRef]);

  return {
    selfRef,
    selfSize,
  };
}

export default useSelfBBox;

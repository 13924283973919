/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '~/hooks';

function WalkingSVG ({ IconColor }) {
  const { theme } = useTheme();
  return (
    <div className="walking-svg" style={{ height: '60%' }}>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="14.889"
        height="24.623"
        viewBox="0 0 14.889 24.623"
      >
        <g id="walk" transform="translate(0)">
          <g id="Group_22" data-name="Group 22" transform="translate(0)">
            <g id="Group_21" data-name="Group 21" transform="translate(0)">
              <path
                id="Path_7"
                data-name="Path 7"
                d="M210.291,4.581A2.291,2.291,0,1,0,208,2.291,2.3,2.3,0,0,0,210.291,4.581Z"
                transform="translate(-201.701)"
                style={{ fill: `${IconColor || theme.colorHelper.secondary}` }}
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M105.556,104.017v-2.291a5.871,5.871,0,0,1-4.982-2.806l-1.088-1.832A2.234,2.234,0,0,0,97.539,96a2.3,2.3,0,0,0-.859.172l-6.013,2.462v5.383h2.291V100.18l2-.859-3.15,16.148h2.405l2-9.162,2.462,2.291v6.872h2.291v-8.647l-2.348-2.348.687-3.436A7.991,7.991,0,0,0,105.556,104.017Z"
                transform="translate(-90.667 -90.846)"
                style={{ fill: `${IconColor || theme.colorHelper.secondary}` }}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

WalkingSVG.propTypes = {
  IconColor: PropTypes.string,
};

WalkingSVG.defaultProps = {
  IconColor: undefined,
};

export default WalkingSVG;

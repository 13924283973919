import { createModule } from 'redux-modux';

const initialState = {
  category: '',
  text: '',
  building: {},
};
const handlers = {
  /**
   * Set category filter
   * @function
   * @returns
   */
  setCategory (state, action) {
    return {
      ...state,
      category: action.payload,
    };
  },
  /**
   * Set text filter
   * @function
   * @returns
   */
  setText (state, action) {
    return {
      ...state,
      text: action.payload,
    };
  },
  /**
   * Set building filter
   * @function
   * @returns
   */
  setBuilding (state, action) {
    return {
      ...state,
      building: action.payload,
    };
  },
};

export default createModule({
  moduleName: 'visibility-filter',
  initialState,
  handlers,
});

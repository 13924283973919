import React from 'react';

export default () => (
  <footer id="customise">
    <div className="main-footer main-footer-3 text-color-default">
      <div className="container">
        <div className="vc_row wpb_row vc_row-fluid bg_footer vc_custom_1475464395314">
          <div className="wpb_column vc_column_container vc_col-sm-7">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid left15 width33">
                  <div className="wpb_column vc_column_container vc_col-sm-3">
                    <div className="vc_column-inner vc_custom_1475464663038">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/asok/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="384"
                                height="105"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo.png 384w, https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo-300x82.png 300w"
                                sizes="(max-width: 384px) 100vw, 384px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wpb_column vc_column_container vc_col-sm-3 vc_col-has-fill">
                    <div className="vc_column-inner vc_custom_1475145263754">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/korat/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="500"
                                height="142"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_korat_logo.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_korat_logo.png 500w, https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_korat_logo-300x85.png 300w"
                                sizes="(max-width: 500px) 100vw, 500px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="young_bander wpb_column vc_column_container vc_col-sm-3">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left width60">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/youngbrander/#1"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="163"
                                height="119"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/YB-Header-elements_04.png"
                                className="vc_single_image-img attachment-medium"
                                alt=""
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grand wpb_column vc_column_container vc_col-sm-3">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.grandecentrepointpattaya.com/"
                              target="_self"
                              className="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="187"
                                height="31"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/12/grand_centre_logo.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vc_row wpb_row vc_inner vc_row-fluid left15 width33 hide">
                  <div className="wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner vc_custom_1475464663038">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/asok/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="384"
                                height="105"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo.png 384w, https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/Terminal_asok_logo-300x82.png 300w"
                                sizes="(max-width: 384px) 100vw, 384px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
                    <div className="vc_column-inner vc_custom_1475145263754">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/pattaya/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper   vc_box_border_grey"
                            >
                              <img
                                width="435"
                                height="121"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/logo_pattaya.png"
                                className="vc_single_image-img attachment-full"
                                alt=""
                                srcSet="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/logo_pattaya.png 435w, https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/logo_pattaya-300x83.png 300w"
                                sizes="(max-width: 435px) 100vw, 435px"
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="young_bander wpb_column vc_column_container vc_col-sm-4">
                    <div className="vc_column-inner ">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left width60">
                          <figure className="wpb_wrapper vc_figure">
                            <a
                              href="https://www.terminal21.co.th/youngbrander/#1"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="vc_single_image-wrapper vc_box_border_grey"
                            >
                              <img
                                width="163"
                                height="119"
                                src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2016/09/YB-Header-elements_04.png"
                                className="vc_single_image-img attachment-medium"
                                alt=""
                              />
                            </a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wpb_column vc_column_container vc_col-sm-5">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="vc_row wpb_row vc_inner vc_row-fluid">
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1536910422566">
                      <div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element vc_custom_1536808683459 copy normalscreen1 hide">
                          <div className="wpb_wrapper">
                            <p style={{ textAlign: "center" }}>
                              Copyright © 2017 Terminal 21 (Terminal 21 Shopping
                              Mall). All rights reserved.
                            </p>
                          </div>
                        </div>

                        <div className="wpb_text_column wpb_content_element open_daily hide">
                          <div className="wpb_wrapper">
                            <p>
                              <i className="fa fa-clock-o" />
                              OPEN DAILY
                              <br />
                              11.00 AM - 11.00 PM
                            </p>
                          </div>
                        </div>

                        <hr className="divider hide" />

                        <div className="wpb_text_column wpb_content_element footermenu">
                          <div className="wpb_wrapper">
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/about-us/">
                                ABOUT US
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/contact-us/">
                                CONTACT US
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/careers/">
                                CAREERS
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/gf-floor/">
                                SHOP
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/how-to-go/">
                                HOW TO GO
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="wpb_text_column wpb_content_element footermenu hide">
                          <div className="wpb_wrapper">
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/about-us/">
                                เกี่ยวกับเรา
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/contact-us/">
                                ติดต่อเรา
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/careers/">
                                สมัครงาน
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/lg-floor/">
                                ร้านค้า
                              </a>
                            </div>
                            <div className="width20">
                              <a href="https://www.terminal21.co.th/pattaya/%e0%b8%82%e0%b9%89%e0%b8%ad%e0%b8%95%e0%b8%81%e0%b8%a5%e0%b8%87%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%84%e0%b8%8b%e0%b8%95%e0%b9%8c/">
                                ความเป็นส่วนตัว
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="wpb_text_column wpb_content_element">
                          <div className="wpb_wrapper">
                            <div className="subscribe">
                              <div className="sub_label">Email Newsletters</div>
                              <div
                                role="form"
                                className="wpcf7"
                                id="wpcf7-f3882-o2"
                                lang="en-US"
                                dir="ltr"
                              >
                                <div className="screen-reader-response" />
                                <form
                                  action="/pattaya/wp-content/themes/royal/custom.css#wpcf7-f3882-o2"
                                  method="post"
                                  className="wpcf7-form"
                                  novalidate="novalidate"
                                >
                                  <div style={{ display: "none" }}>
                                    <input
                                      type="hidden"
                                      name="_wpcf7"
                                      value="3882"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_version"
                                      value="5.0.4"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_locale"
                                      value="en_US"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_unit_tag"
                                      value="wpcf7-f3882-o2"
                                    />
                                    <input
                                      type="hidden"
                                      name="_wpcf7_container_post"
                                      value="0"
                                    />
                                  </div>

                                  <p>
                                    <label>
                                      Email
                                      <strong>Newsletters</strong>
                                    </label>
                                    <br />
                                    <span className="wpcf7-form-control-wrap your-email">
                                      <input
                                        type="email"
                                        name="your-email"
                                        value=""
                                        size="40"
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                        aria-required="true"
                                        aria-invalid="false"
                                      />
                                    </span>
                                    <input
                                      type="submit"
                                      value="Subscribe"
                                      className="wpcf7-form-control wpcf7-submit"
                                    />
                                    <span className="ajax-loader" />
                                  </p>
                                  <input
                                    type="hidden"
                                    id="ct_checkjs_cf7_6602294be910b1e3c4571bd98c4d5484"
                                    name="ct_checkjs_cf7"
                                    value="393142427"
                                  />
                                  <div className="wpcf7-response-output wpcf7-display-none" />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="wpb_raw_code wpb_content_element wpb_raw_html hide">
                          <div className="wpb_wrapper">
                            <div className="social-icon">
                              <a
                                href="https://www.instagram.com/terminal21korat/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://demo.fennecdigital.com/terminal-korat/wp-content/uploads/2016/10/Instagram_App_Large_May2016_200.png"
                                  alt="ig_icon"
                                  width="33"
                                  height="33"
                                />
                              </a>
                              <a
                                href="https://www.facebook.com/Terminal21Korat/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://demo.fennecdigital.com/terminal-korat/wp-content/uploads/2016/10/Facebook-Logo.png"
                                  alt="facebook"
                                  width="33"
                                  height="33"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element  vc_custom_1537181371616 copy smallscreen hide">
                  <div className="wpb_wrapper">
                    <p style={{ textAlign: "center" }}>
                      Copyright © 2017 Terminal 21 (Terminal 21 Shopping Mall).
                      All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper" />
            </div>
          </div>
        </div>

        <div className="vc_row wpb_row vc_row-fluid copyright_pt">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner ">
              <div className="wpb_wrapper">
                <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                  <div className="wpb_wrapper">
                    <div className="social-icon">
                      <a
                        href="https://www.facebook.com/Terminal21Pattaya/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2018/09/logo_facebook.png"
                          alt="ig_icon"
                          width="33"
                          height="33"
                        />
                      </a>
                      <a
                        href="https://twitter.com/T21pattaya"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2018/09/logo_twister.png"
                          alt="facebook"
                          width="33"
                          height="33"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/terminal21pattaya/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2018/09/logo_ig.png"
                          alt="facebook"
                          width="33"
                          height="33"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/c/Terminal21ShoppingMall"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2018/09/logo_youtube.png"
                          alt="facebook"
                          width="33"
                          height="33"
                        />
                      </a>
                      <a
                        href="https://line.me/ti/p/~@terminal21"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.terminal21.co.th/pattaya/wp-content/uploads/2018/09/logo_line.png"
                          alt="facebook"
                          width="33"
                          height="33"
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="wpb_text_column wpb_content_element  vc_custom_1536807670863 copy">
                  <div className="wpb_wrapper">
                    <p style={{ textAlign: "center" }}>
                      Copyright © 2017 Terminal 21 (Terminal 21 Shopping Mall).
                      All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

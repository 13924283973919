import { createSelector } from 'reselect';
import helper from '../helper';

/**
 * Entire floor icon
 * @description entire floor icon from redux store
 */
const entire = helper.getEntireFloorIcon;

/**
 * Get Floor icon by id
 * @description Get a single floor icon by provide id
 * @param {String|Number} id
 */
const getFloorIconById = createSelector(
  [entire],
  entireFloorIcon => id => entireFloorIcon[id],
);

export default {
  entire,
  getFloorIconById,
};

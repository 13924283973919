import React from 'react';
import PropTypes from 'prop-types';

const GroupButton = ({ children, className }) => (
  <div className={`group-button ${className}`} role="group" aria-label="Basic example">
    {children}
  </div>
);

GroupButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GroupButton.defaultProps = {
  className: '',
};

export default GroupButton;

import { fetcher } from '~/utils';


/**
 * Project service
 *
 * @function
 */
function service () {
  return {
    /**
     * Get proejct by project slug
     *
     * @param {string} slug - project slug
     */
    getBySlug (slug) {
      const option = { useMock: true, jsonMock: `/projects/${slug}.json` };

      return fetcher(option);
    },
  };
}

export default {};
export const ProjectService = service();

import { useContext } from 'react';
import { ModalContext } from '~/context/Modal';

/**
 * ----------------------------------------------------------------------------
 * Modal hook
 * ----------------------------------------------------------------------------
 */
function useModal () {
  const { modals, setModals } = useContext(ModalContext);

  /** checking active modal */
  const isActive = name => modals[name];

  /** set active modal follow by provided name */
  const open = (name) => {
    /** close all before open a new one */
    const closeAll = Object.keys(modals).reduce((result, modal) => ({ ...result, [modal]: false }), {});
    setModals({
      ...closeAll,
      [name]: true,
    });
  };

  /** set active modal follow by provided name */
  const close = (name) => {
    setModals({
      ...modals,
      [name]: false,
    });
  };

  const closeAll = () => {
    const nextState = Object.keys(modals).reduce((result, modal) => ({ ...result, [modal]: false }), {});
    setModals(nextState);
  };

  /** init new modal with close status */
  const initialModal = (name) => {
    setModals({
      ...modals,
      [name]: false,
    });
  };

  const destroyModal = (name) => {
    const newModals = { ...modals };
    delete newModals[name];

    setModals(newModals);
  };

  return {
    open,
    close,
    closeAll,
    modals,
    isActive,
    initialModal,
    destroyModal,
  };
}

export default useModal;

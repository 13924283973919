import React from 'react';
import PropTypes from 'prop-types';

import { bindingClassName } from '~/utils';

/**
 * ----------------------------------------------------------------------------
 * Modal footer item component
 * ----------------------------------------------------------------------------
 *
 * @param {{
 *  children: JSX.Element,
 *  variant: (''|'primary')
 *  onClick: Function,
 *  renderAs: (String|Function|{})
 * }} props
 * @returns
 */
function FooterItem ({ children, variant, onClick, renderAs: RenderAs }) {
  return (
    <RenderAs
      className={bindingClassName(
        'app-modal-footer-item',
        { [`is-${variant}`]: variant },
      )}
      onClick={onClick}
    >
      {children}
    </RenderAs>
  );
}

FooterItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  variant: PropTypes.oneOf([
    '',
    'primary',
  ]),
  onClick: PropTypes.func,
  renderAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape(),
  ]),
};

FooterItem.defaultProps = {
  variant: '',
  onClick () {},
  renderAs: 'div',
};

export default FooterItem;

/**
 * Wayfinder context helper
 */
export default {
  /**
   * Calculate distance
   *
   * @param {{}} from
   * @param {{}} to
   */
  calculateDistance (from, to) {
    if (!to || !from) {
      return 0;
    }

    return Math.hypot(to.x - from.x, to.y - from.y);
  },
  /**
   * Construct routing map
   *
   * @param {{}} nodes
   */
  constructRoutingMap (nodes) {
    console.debug('constructRoutingMap', nodes);
    const escalators = {};
    const elevators = {};

    const routingMap = Object.values(nodes).reduce((nodeWithAdjacentElements, node) => {
      // modified keys
      if (node.isEsalator !== undefined) {
        if (node.isEsalator === true) node.isLiftNode = true;
        delete node.isEsalator;
      }
      if (node.isLift !== undefined) {
        if (node.isLift === true) node.isEscalatorNode = true;
        delete node.isLift;
      }

      const adjacentNodes = node.neighbor.reduce((acc, toNodeId) => {
        if (!nodes[toNodeId]) {
          console.error(`adjacentNodes Warning: node[${toNodeId}] is missing, cannot calculate distance from ${node.id} to neighbor ${toNodeId} as adjacent nodes`);
          return acc;
        }
        if (node.id === toNodeId) {
          console.error(`adjacentNodes Warning: node ${node.id} has itself as neighbor`);
          return acc;
        }
        return {
          ...acc,
          [toNodeId]: this.calculateDistance(node, nodes[toNodeId]),
        };
      }, {});

      if (node.links && node.links.length > 0) {
        escalators[node.id] = node.links;
      }
      const adjacentEscalators = node.links && node.links.reduce((acc, toNodeId) => {
        if (!nodes[toNodeId]) {
          console.error(`adjacentEscalators Warning: node[${toNodeId}] is missing, cannot calculate distance from ${node.id} to link ${toNodeId} as escalator`);
          return acc;
        }

        return {
          ...acc,
          [toNodeId]: 450,
        };
      }, {});

      if (node.linksLift && node.linksLift.length > 0) {
        elevators[node.id] = node.linksLift;
      }
      const adjacentElevators = node.linksLift && node.linksLift.reduce((acc, toNodeId) => {
        if (!nodes[toNodeId]) {
          console.error(`adjacentElevators Warning: node[${toNodeId}] is missing, cannot calculate distance from ${node.id} to link ${toNodeId} as elevator`);
          return acc;
        }

        return {
          ...acc,
          [toNodeId]: 2000,
        };
      }, {});

      node.x = typeof node.x === 'string' ? parseFloat(node.x) : node.x;
      node.y = typeof node.y === 'string' ? parseFloat(node.y) : node.y;

      const newNode = {
        ...nodeWithAdjacentElements,
        [node.id]: {
          ...adjacentNodes,
          ...adjacentEscalators,
          ...adjacentElevators,
        },
      };
      return newNode;
    }, {});
    return { nodes, routingMap };
  },
};
